import { fork, takeEvery, all } from "redux-saga/effects";
import {
  watchRequestHomeModuleInfo,
  watchRequestEntrepenourHomeModuleInfo,
} from "./Home";
import { watchRequestFooterModuleInfo } from "./Footer";
import { watchRequestNavbarModuleInfo } from "./Navbar";
import {
  watchRequestTrPrExProducts,
  watchRequestFavorites,
  watchRequestAllProducts,
  watchRequestSetProductOnFavorites,
  watchRequestGetZcareProduct,
  watchRequestGetSetupKitComponents,
  watchRequestGetAdmissionKits,
  watchRequestAllProductsByLang,
  watchRequestAddSetCategoriesReducer,
  watchRequestRemoveSetCategoriesReducer,
  watchRequestAllProductsAsync,
  watchRequestAllCaregoriesAsync,
  watchRequestGetBeautyBoxes,
  watchRequestAllProductFamiliesAsync,
} from "./Products";
import {
  watchRequestFirstChargeReload,
  watchRequestFirstChargeSite,
  watchRequestStatesAndCities,
  watchRequestLandingRegistrationData
} from "./General";
import {
  watchRequestClienLogin,
  watchRequestRegisterNewClient,
  watchRequestClientInformation,
  watchRequestUpdateClientInformation,
  watchRequestRegisterWebPageContact,
  watchRequestEmailForRetargetingProgram,
  watchRequestClientAdministrationMenu,
  watchRequestClientLogOut,
  watchRequestClientLogOutAndChargeNewMember,
  watchRequestPasswordRecovery,
  watchRequestValidationPasswordRecoveryCode,
  watchRequestResetUserPassword,
  watchRequestClientConfirmedOrders,
  watchRequestValidateInitialBanner,
  watchRequestValidateIdentificationType,
  watchRequestUpdateDateFromCheckout,
  watchRequestInsertFiscalAddress,
  watchRequestValidateOwnerEmail,
  watchRequestValidateOwnerPhone,
  watchRequestGetDataSponsorById,
  watchRequestGetDataSponsorByName,
  watchRequestGetDataSponsorByCity,
  watchRequestAssignSponsor,
  watchRequestUpdateShippingAddress,
  watchRequestUploadImage,
  watchRequestRegisterSupportQuestion,
  watchRequestGetDataSponsorAutomatic,
  watchRequestAssignSponsorAutomatic,
  watchRequestImmediateGains,
  watchRequestLoggUserActivity,
  watchRequestClientGuestInformationEmail,
  watchRequestNewsLetterSubscriptionEvaluation,
  watchRequestDataValidationUser,
  watchRequestRegistrationLoginData,
  watchRequestNewRegistrationCheckoutData,
  watchRequestUpdateAddressCheckoutData,
  watchRequestRegistrationLandingData,
  watchRequestNewRegistrationCheckoutDataFC,
  watchRequestDataValidationUserFC,
} from "./Client";
import {
  watchRequestTwilioCellPhoneValidation,
  watchRequestTwilioCellPhoneCodeValidation,
  watchRequestTwilioOnlyCellPhoneValidation,
} from "./Twilio";
import { watchRequestEmailValidation } from "./Email";
import { watchRequestClientAddressValidation } from "./Address";
import {
  watchRequestCartCurrentOrder,
  watchRequestGetCartOrder,
  watchRequestGetCouponList,
  watchRequestCartCancelOrder,
  watchRequestCartOrderCharges,
  watchRequestCartAddMembership,
  watchRequestCartCloseOrder,
  watchRequestGetCartClosedOrder,
  watchRequestCartTaxOrder,
  watchRequestCartAssignInventory,
  watchRequestCloseCartOrderGuest,
  watchRequestCartGetGiftList,
  watchRequestCartAddConfigurableGift,
} from "./Cart";
import {
  watchRequestStripePayment,
  watchRequestStripePayment2023,
} from "./Payment";
import {
  watchRequestCancellationComplete,
  watchRequestGetReasonsReturn,
  watchRequestGetExchangeRate,
  watchRequestFullRefund,
  watchRequestPartialRefund,
} from "./Repayment";
import { watchRequestInstagramPosts } from "./Facebook";
import {
  watchRequestReferrerCellPhoneValidation,
  watchRequestReferrerEmailValidation,
  watchRequestReferrerNewRegister,
  watchRequestReferrerCode,
  watchRequestReferrerCodeSendedLog,
  watchRequestReferrerList,
} from "./Referrer";
import {
  watchRequestCustomLabelsConfiguration,
  watchValidateCustomLabelConfigurationOnOrder,
  watchRequestUpdateCustomLabelConfigurationOnOrder,
  watchRequestBuildImagesOnServer,
  watchRequestCustomLabelsConfigurationByDetail,
  watchRequestUpdateCustomLabelConfigurationOnOrderDetail,
  watchRequestCustomLabelsConfigurationByPromo,
} from "./CustomLabels";
import {
  watchRequestGetRegisteredProspect,
  watchRequestRegisterProspect,
} from "./NewMember";
import { watchRequestSurveyContent,
         watchSubmitSurveyData,
         watchRequestSurveyProfileDetails
 } from "./PersonalityTest"

export default function* rootSaga() {
  yield fork(watchRequestCancellationComplete);
  yield fork(watchRequestHomeModuleInfo);
  yield fork(watchRequestEntrepenourHomeModuleInfo);
  yield fork(watchRequestFooterModuleInfo);
  yield fork(watchRequestNavbarModuleInfo);
  yield fork(watchRequestTrPrExProducts);
  yield fork(watchRequestFavorites);
  yield fork(watchRequestFirstChargeSite);
  yield fork(watchRequestStatesAndCities);
  yield fork(watchRequestLandingRegistrationData);
  yield fork(watchRequestFirstChargeReload);
  yield fork(watchRequestClienLogin);
  yield fork(watchRequestAllProducts);
  yield fork(watchRequestSetProductOnFavorites);
  yield fork(watchRequestRegisterNewClient);
  yield fork(watchRequestClientInformation);
  yield fork(watchRequestUpdateClientInformation);
  yield fork(watchRequestEmailForRetargetingProgram);
  yield fork(watchRequestRegisterWebPageContact);
  yield fork(watchRequestClientAdministrationMenu);
  yield fork(watchRequestClientLogOut);
  yield fork(watchRequestClientLogOutAndChargeNewMember);
  yield fork(watchRequestDataValidationUser);

  yield fork(watchRequestRegistrationLoginData);
  yield fork(watchRequestNewRegistrationCheckoutData);
  yield fork(watchRequestUpdateAddressCheckoutData);
  yield fork(watchRequestRegistrationLandingData);
  yield fork(watchRequestNewRegistrationCheckoutDataFC);
  yield fork(watchRequestDataValidationUserFC);

  yield fork(watchRequestTwilioCellPhoneValidation);
  yield fork(watchRequestTwilioCellPhoneCodeValidation);
  yield fork(watchRequestTwilioOnlyCellPhoneValidation);
  yield fork(watchRequestEmailValidation);
  yield fork(watchRequestClientAddressValidation);
  yield fork(watchRequestPasswordRecovery);
  yield fork(watchRequestValidationPasswordRecoveryCode);
  yield fork(watchRequestResetUserPassword);
  yield fork(watchRequestClientConfirmedOrders);
  yield fork(watchRequestValidateInitialBanner);
  yield fork(watchRequestGetZcareProduct);
  yield fork(watchRequestCartCurrentOrder);
  yield fork(watchRequestGetCartOrder);
  yield fork(watchRequestGetCouponList);
  yield fork(watchRequestGetSetupKitComponents);
  yield fork(watchRequestCartCancelOrder);
  yield fork(watchRequestCartOrderCharges);
  yield fork(watchRequestGetAdmissionKits);
  yield fork(watchRequestCartAddMembership);
  yield fork(watchRequestValidateIdentificationType);
  yield fork(watchRequestUpdateDateFromCheckout);
  yield fork(watchRequestInsertFiscalAddress);
  yield fork(watchRequestStripePayment);
  yield fork(watchRequestStripePayment2023);
  yield fork(watchRequestCartCloseOrder);
  yield fork(watchRequestGetCartClosedOrder);
  yield fork(watchRequestValidateOwnerEmail);
  yield fork(watchRequestValidateOwnerPhone);
  yield fork(watchRequestGetReasonsReturn);
  yield fork(watchRequestGetExchangeRate);
  yield fork(watchRequestCartTaxOrder);
  yield fork(watchRequestCartAssignInventory);
  yield fork(watchRequestFullRefund);
  yield fork(watchRequestPartialRefund);
  yield fork(watchRequestInstagramPosts);
  yield fork(watchRequestGetDataSponsorById);
  yield fork(watchRequestGetDataSponsorByName);
  yield fork(watchRequestGetDataSponsorByCity);
  yield fork(watchRequestAssignSponsor);
  yield fork(watchRequestUpdateShippingAddress);
  yield fork(watchRequestReferrerCellPhoneValidation);
  yield fork(watchRequestReferrerEmailValidation);
  yield fork(watchRequestReferrerNewRegister);
  yield fork(watchRequestReferrerCode);
  yield fork(watchRequestReferrerCodeSendedLog);
  yield fork(watchRequestReferrerList);
  yield fork(watchRequestUploadImage);
  yield fork(watchRequestRegisterSupportQuestion);
  yield fork(watchRequestGetDataSponsorAutomatic);
  yield fork(watchRequestAssignSponsorAutomatic);
  yield fork(watchRequestImmediateGains);
  yield fork(watchRequestLoggUserActivity);
  yield fork(watchRequestNewsLetterSubscriptionEvaluation);
  //Custom Labels
  yield fork(watchRequestCustomLabelsConfiguration);
  yield fork(watchValidateCustomLabelConfigurationOnOrder);
  yield fork(watchRequestUpdateCustomLabelConfigurationOnOrder);
  yield fork(watchRequestBuildImagesOnServer);
  yield fork(watchRequestCustomLabelsConfigurationByDetail);
  yield fork(watchRequestCustomLabelsConfigurationByPromo);
  yield fork(watchRequestUpdateCustomLabelConfigurationOnOrderDetail);

  yield fork(watchRequestAllProductsByLang);

  yield fork(watchRequestAddSetCategoriesReducer);
  yield fork(watchRequestRemoveSetCategoriesReducer);

  yield fork(watchRequestCloseCartOrderGuest);
  yield fork(watchRequestClientGuestInformationEmail);
  yield fork(watchRequestCartGetGiftList);
  yield fork(watchRequestCartAddConfigurableGift);

  yield fork(watchRequestAllProductsAsync);
  yield fork(watchRequestAllCaregoriesAsync);
  yield fork(watchRequestAllProductFamiliesAsync);

  yield fork(watchRequestGetBeautyBoxes);
  //NEW MEMBERS
  yield fork(watchRequestGetRegisteredProspect);
  yield fork(watchRequestRegisterProspect);
  //Personality Test
  yield fork(watchRequestSurveyContent);
  yield fork(watchSubmitSurveyData);
  yield fork(watchRequestSurveyProfileDetails);
}

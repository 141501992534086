import { NumericFormat } from "react-number-format";
//Flags
import flagAG from "./../../assets/media/images/flags/antigua-y-barbuda.png";
import flagBS from "./../../assets/media/images/flags/bahamas.png";
import flagBB from "./../../assets/media/images/flags/barbados.png";
import flagCA from "./../../assets/media/images/flags/canada.png";
import flagDM from "./../../assets/media/images/flags/dominica.png";
import flagUS from "./../../assets/media/images/flags/united-states.png";
import flagGD from "./../../assets/media/images/flags/grenada.png";
import flagGY from "./../../assets/media/images/flags/guyana.png";
import flagKY from "./../../assets/media/images/flags/cayman-islands.png";
import flagJM from "./../../assets/media/images/flags/jamaica.png";
import flagMX from "./../../assets/media/images/flags/mexico.png";
import flagPR from "./../../assets/media/images/flags/puerto-rico.png";
import flagRD from "./../../assets/media/images/flags/dominican-republic.png";
import flagVC from "./../../assets/media/images/flags/st-vincent-and-the-grenadines.png";
import flagLC from "./../../assets/media/images/flags/st-lucia.png";
import flagSX from "./../../assets/media/images/flags/sint-maarten.png";
import flagSR from "./../../assets/media/images/flags/suriname.png";
import flagTT from "./../../assets/media/images/flags/trinidad-and-tobago.png";

export const formatNumberWithCommas = (number) => {
  number = parseFloat(number).toFixed(2);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const langEnum = [
  { langId: 1, code: "es" },
  { langId: 2, code: "en" },
];

export const groupBy = function (list, key) {
  return list.reduce(function (acc, item) {
    //acc, item
    (acc[item[key]] = acc[item[key]] || []).push(item);
    return acc;
  }, {});
};

export const postRequest = (url, data) => {
  let headers = { "Content-Type": "application/json" };

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  };

  return fetch(url, options);
};

export const getRequest = (url, data) => {
  let headers = { "Content-Type": "application/json" };

  const options = {
    method: "GET",
    headers: headers,
    body: JSON.stringify(data),
  };

  return fetch(url, options);
};
export const truncateText = (text, length = 40) => {
  if (text) {
    if (text.length > length) {
      return `${text.slice(0, length)} ...`;
    } else {
      return `${text.slice(0, length)}`;
    }
  }
  return text;
};

export const restrictToOnlyNumbers = (e, length = 10) => {
  const regularExpression = new RegExp(`^[0-9]{10}$`, "gi");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    if (Number.isNaN(parseInt(e.target.value))) {
      return (e.target.value = "");
    }
    return (e.target.value = parseInt(e.target.value.slice(0, length)));
  }
};

export const restrictToOnlyLetters = (e) => {
  const regularExpression = new RegExp(`^[a-zA-Z ]+$`, "g");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    return (e.target.value = e.target.value.substring(
      0,
      e.target.value.length - 1
    ));
  }
};

export const handleScrollToTop = () => {
  const pathname = window.location.pathname;
  const baseContainer = document.getElementById("baseContainer__wrapper");
  //console.log("pathname", pathname);
  if (baseContainer && typeof baseContainer.scrollTo === "function") {
    if (pathname !== "/contactus") {
      //console.log("scroll", baseContainer);
      setTimeout(() => {
        baseContainer.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  }
};

export const convertNameToInitials = (name) => {
  let initials = "";
  const nameArray = name.split(" ");
  nameArray.slice(0, 2).forEach((name) => {
    initials += name.charAt(0).toUpperCase();
  });
  return initials;
};

export const GetPropertyValue = (obj, dataToRetrieve) => {
  return dataToRetrieve
    .split(".") // split string based on `.`
    .reduce(function (o, k) {
      return o && o[k]; // get inner property if `o` is defined else get `o` and return
    }, obj); // set initial value as object
};

export const formatPrice = (price, extraSuffix, extraPrefix) => {
  //debugger;
  if (price !== undefined) {
    let dollars;
    let cents;
    const priceInFloat = parseFloat(price);
    if (priceInFloat) {
      const priceArray = priceInFloat.toFixed(2).toString().split(".");
      if (priceArray && priceArray.length > 1) {
        dollars = priceArray[0];
        cents = priceArray[1];
      }
      return (
        <div
          style={{
            display: "flex",
            width: "fit-content",
            alignItems: "flex-start",
          }}
        >
          {extraPrefix && (
            <div style={{ marginRight: "0.5vw" }}>{extraPrefix}</div>
          )}
          <NumericFormat
            style={{ fontSize: 16 }}
            value={dollars}
            displayType={"text"}
            decimalSeparator=","
            prefix={"$"}
            suffix={""}
          />
          {/* <sup>{cents}</sup> */}
          <span
            style={{
              fontSize: "10px", //"0.70rem",
              // marginTop: "1px", //"-0.20rem",
              // marginLeft: "0.04rem",
              position: "relative",
              top: "0.75px",
              left: "0.25px",
            }}
          >
            {`.${cents}`}
          </span>
          {extraSuffix && (
            <div style={{ marginLeft: "0.5vw" }}>{extraSuffix}</div>
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            width: "fit-content",
            alignItems: "flex-start",
          }}
        >
          <NumericFormat
            style={{ fontSize: 16 }}
            value={"0"}
            displayType={"text"}
            decimalSeparator=","
            prefix={"$"}
            suffix={""}
          />
          {/* <sup>{"00"}</sup> */}
          <span
            style={{
              fontSize: "10px", //"0.70rem",
              // marginTop: "1px", //"-0.20rem",
              // marginLeft: "0.04rem",
              position: "relative",
              top: "1px",
            }}
          >
            {"00"}
          </span>
        </div>
      );
    }
  }
};

export const enumCategories = [
  {
    id: 11,
    name: "News",
    levels: [],
  },
  {
    id: 7,
    name: "Offers",
    levels: [],
  },
  {
    id: 3,
    name: "__products_category_fragrance",
    levels: [
      { level: 1, name: "_products_category_fragrance_level_one" },
      { level: 2, name: "_products_category_fragrance_level_two" },
      { level: 3, name: "_products_category_fragrance_level_three" },
      { level: 4, name: "_products_category_fragrance_level_four" },
    ],
  },
  {
    id: 2,
    name: "__products_category_skin_care",
    levels: [{ level: 1, name: "__products_category_skin_care_level_one" }],
  },
  {
    id: 4,
    name: "__products_category_makeup",
    levels: [
      { level: 1, name: "__products_category_makeup_level_one" },
      { level: 2, name: "__products_category_makeup_level_two" },
    ],
  },
  {
    id: 5,
    name: "__products_category_boys_girls_babies",
    levels: [
      { level: 1, name: "__products_category_boys_girls_babies_level_one" },
      { level: 2, name: "__products_category_boys_girls_babies_level_two" },
    ],
  },
  {
    id: 1,
    name: "__products_category_body_care",
    levels: [{ level: 1, name: "__products_category_body_care_level_one" }],
  },
  {
    id: 10,
    name: "__products_category_wellness",
    levels: [],
  },
  {
    id: 6,
    name: "__products_category_pets",
    levels: [],
  },
];

export const capitalizeText = (text) => {
  //return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  const textArray = text.split(" ");
  const result = textArray.map(
    (item) => item.charAt(0).toUpperCase() + item.toLowerCase().slice(1)
  );
  const resultString = result.join(" ");
  return resultString;
};

export const percentageUtility = (value) => {
  var ceilValue = Math.ceil(value / 5) * 5;
  // console.log("Math value",  Math.ceil(value / 5))
  // console.log("ceil value", ceilValue)
  let percentageString = "";
  if (ceilValue > value) {
    percentageString = `${ceilValue - 5}`;
    return percentageString;
  } else {
    percentageString = value.toString();
  }
  return percentageString;
};

export const restrictToOnlyNumbersString = (e, length = 10) => {
  const regularExpression = new RegExp(`^[0-9]{10}$`, "gi");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    if (Number.isNaN(parseInt(e.target.value))) {
      return (e.target.value = "");
    }
    return (e.target.value = e.target.value.slice(0, length));
  }
};

export const countries = [
  {
    id: 17,
    shortName: "AG",
    longName: "Antigua y Barbuda",
    flagURL: flagAG,
    internationalPrefix: "+1",
  },
  {
    id: 4,
    shortName: "BS",
    longName: "Bahamas",
    flagURL: flagBS,
    internationalPrefix: "+1",
  },
  {
    id: 13,
    shortName: "BB",
    longName: "Barbados",
    flagURL: flagBB,
    internationalPrefix: "+1",
  },
  {
    id: 4,
    shortName: "CA",
    longName: "Canada",
    flagURL: flagCA,
    internationalPrefix: "+1",
  },
  {
    id: 14,
    shortName: "DM",
    longName: "Dominica",
    flagURL: flagDM,
    internationalPrefix: "+1",
  },
  {
    id: 1,
    shortName: "US",
    longName: "Estados Unidos",
    flagURL: flagUS,
    internationalPrefix: "+1",
  },
  {
    id: 16,
    shortName: "GD",
    longName: "Granada",
    flagURL: flagGD,
    internationalPrefix: "+1",
  },
  {
    id: 5,
    shortName: "GY",
    longName: "Guyana",
    flagURL: flagGY,
    internationalPrefix: "+1",
  },
  {
    id: 9,
    shortName: "KY",
    longName: "Islas Caimán",
    flagURL: flagKY,
    internationalPrefix: "+1",
  },
  {
    id: 15,
    shortName: "JM",
    longName: "Jamaica",
    flagURL: flagJM,
    internationalPrefix: "+1",
  },
  {
    id: 2,
    shortName: "MX",
    longName: "México",
    flagURL: flagMX,
    internationalPrefix: "+52",
  },
  {
    id: 10,
    shortName: "PR",
    longName: "Puerto Rico",
    flagURL: flagPR,
    internationalPrefix: "+1",
  },
  {
    id: 3,
    shortName: "RD",
    longName: "República Dominicana",
    flagURL: flagRD,
    internationalPrefix: "+1",
  },
  {
    id: 11,
    shortName: "VC",
    longName: "San Vicente y las Granadinas",
    flagURL: flagVC,
    internationalPrefix: "+1",
  },
  {
    id: 8,
    shortName: "LC",
    longName: "Santa Lucía",
    flagURL: flagLC,
    internationalPrefix: "+1",
  },
  {
    id: 12,
    shortName: "SX",
    longName: "Sint Maarten",
    flagURL: flagSX,
    internationalPrefix: "+1",
  },
  {
    id: 7,
    shortName: "SR",
    longName: "Suriname",
    flagURL: flagSR,
    internationalPrefix: "+1",
  },
  {
    id: 6,
    shortName: "TT",
    longName: "Trinidad y Tobago",
    flagURL: flagTT,
    internationalPrefix: "+1",
  },
];

export const regexIsEmailAddressValid = (email) => {
  if (email.length > 0) {
    const regularExpression =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(email);
  } else {
    return false;
  }
};

export const restrictToOnlyLettersForEmail = (e) => {
  const regularExpression = new RegExp(`^[a-zA-Z0-9_\\.\\@\\-]+$`, "g");
  if (regularExpression.test(e.target.value)) {
    return e.target.value;
  } else {
    return (e.target.value = e.target.value.substring(
      0,
      e.target.value.length - 1
    ));
  }
};

import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
//MUI
import { IconButton, Skeleton } from "@mui/material";
import { Close } from "@mui/icons-material";
//Components
import NewProductCard from "../../base/components/NewProductCard";
//Assets
import { ReactComponent as HeartBlackOutlineIcon } from "./../../../../assets/media/images/icons/heartBlackOutlineIcon.svg";

const FloatingFavorites = ({
  open,
  setIsOpenFloatingFavorites,
  clientFavorites,
  isLanguageLoaded,
  enqueueSnackbar,
}) => {
  //Translation
  const { t } = useTranslation();

  return (
    <div className={`floating__favorites ${open ? "active" : ""}`}>
      <div className="floating__favorites__header">
        <div className="floating__favorites__header__title">
          <HeartBlackOutlineIcon />
          <span>
            {isLanguageLoaded ? (
              t("__floating__my__favourites__title")
            ) : (
              <Skeleton variant="rect" width={100} height={30} />
            )}
          </span>
        </div>
        <div className="floating__favorites__header__close__wrapper">
          <IconButton
            onClick={() => {
              setIsOpenFloatingFavorites(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
      </div>

      <div
        className={`floating__favorites__scrollable__wrapper ${
          isMobile || isTablet ? "mobile" : ""
        }`}
      >
        {clientFavorites.map((item, index) => {
          return (
            <div
              key={index}
              className="floating__favorites__scrollable__wrapper__item"
            >
              <NewProductCard
                t={t}
                product={item}
                fullWidth
                clientFavorites={clientFavorites}
                isLanguageLoaded={isLanguageLoaded}
                enqueueSnackbar={enqueueSnackbar}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FloatingFavorites;

import {
  REQUEST_FIRST_CHARGE_SITE,
  REQUEST_STATES_CITIES,
  REQUEST_LOGG_USER_ACTIVITY,
  REQUEST_FIRST_CHARGE_SITE_RELOAD,
  REQUEST_LANDING_REGISTRATION_DATA
} from "./../../Constants";

export const requestFirstChargeSite = (model) => {
  let userId = "";
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUGuestId")) {
    userId = localStorage.getItem("ZUsaUGuestId");
  }
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  if (model?.urlPage !== "" && model?.urlPage.length > 0) {
    localStorage.setItem("ZUsaUrlPage", model?.urlPage);
  }
  model.userId = userId;
  model.sessionId = sessionId;
  return {
    type: REQUEST_FIRST_CHARGE_SITE,
    payload: { model },
  };
};

export const requestStatesAndCities = () => {
  return {
    type: REQUEST_STATES_CITIES,
    payload: "",
  };
};

export const requestLoggUserActivity = (
  topicItemId,
  idSubCategory,
  idCategory,
  productSku,
  aditionalInfo
) => {
  let sessionId = "0";
  //// ver con tocayo si asi lo dejamos
  const isExits = JSON.parse(localStorage.getItem("ZUsaUInfo"));
  if (isExits) {
    if (localStorage.getItem("ZUsaUInfo")) {
      sessionId = JSON.parse(
        localStorage.getItem("ZUsaUInfo")
      ).sessionId.toString();
    }
    return {
      type: REQUEST_LOGG_USER_ACTIVITY,
      payload: {
        sessionId,
        topicItemId,
        idSubCategory,
        idCategory,
        productSku,
        aditionalInfo,
      },
    };
  } else {
    return {
      type: "",
      payload: {},
    };
  }
};

export const requestFirstChargeSiteReload = (model) => {
  return {
    type: REQUEST_FIRST_CHARGE_SITE_RELOAD,
    payload: { model },
  };
};

export const requestLandingRegistrationData = () => {
  return {
    type: REQUEST_LANDING_REGISTRATION_DATA,
    payload: "",
  };
};
import GraphqlHandler from '../GraphqlHandler';
import GraphqlHandlerNoInterceptor from '../GraphqlHandler/noInterceptor'
import { ENVIROMENT_URL } from '../../React/ReduxSaga/Constants';

const routeClient = ENVIROMENT_URL + 'apiV3/ClientMicroservice/Client'
const routeGeneral = ENVIROMENT_URL + 'apiV3/GeneralMicroservice/General'
const routeLangueages = ENVIROMENT_URL + 'apiV3/GeneralMicroservice/Translation'


class GeneralData {
    static GetFirstChargeSite = model => {
        const result = GraphqlHandler.openRequestGraphQlMutation(
            routeClient,
            //'http://localhost:59622/api/ClientSession',
            `
            (@autodeclare){
                userFirstCharge(user:$user){
                    response
                    {
                      code,
                      message
                    },
                    firstChargeModel
                    {
                      email,
                      idClient,
                      idGuestUser,
                      idLang,
                      idPage,
                      idUser,
                      isGuestUser,
                      isOlder,
                      nameClient,
                      sessionId,
                      sponsorId,
                      typeUser
                    }   
                }
            }
            `,
            true,
            {
                "user!FirstChargeInputModelType" : model
            }
        )
        return result;
    }
    static GetStatesAndCities = () => {
        const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
            routeGeneral,
            `
                {
                    getStatesAndCities
                    {
                        code,
                        message,
                        states
                        {
                            idState,
                            state,
                            name,
                            cities
                        }
                    }
                }
            `,
            false
            );
        return result;
    }
    static GetLandingRegistrationData = () => {
        const result = GraphqlHandlerNoInterceptor.openRequestGraphQlQuery(
            routeGeneral,
            `
                {
                    getLandingRegisterConfig 
                    {
                        code,
                        message,
                        configData 
                        {
                            id,
                            emojiTitleClassStart,
                            titleEn,
                            titleEs,
                            emojiTitleClassEnd,
                            emojiSubtitleClassStart,
                            subtitleEn,
                            subtitleEs,
                            emojiSubtitleClassEnd,
                            emojiFooterClassStart,
                            footerEn,
                            footerEs,
                            emojiFooterClassEnd,
                            imagenEn,
                            imagenEs,
                            status,
                            userAudit,
                            dateAudit,
                            benefits 
                            {
                                id,
                                idLanding,
                                emojiClassStart,
                                benefitEn,
                                benefitEs,
                                emojiClassEnd,
                                userAudit,
                                dateAudit
                            }
                        }
                    }
                }
            `,
            false
            );
        return result;
    }
    static GeyEnTranslation = () => {
        return fetch(`${routeLangueages}/en`).then(response => response.json()).catch(error => error)
    }
    static GeyEsTranslation = () => {
        return fetch(`${routeLangueages}/es`).then(response => response.json()).catch(error => error)
    }
}

export default GeneralData
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Skeleton,
  Checkbox,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  FormHelperText,
} from "@mui/material";
import { isMobile, isTablet } from "react-device-detect";
import { Check, Clear } from "@mui/icons-material";
import Timer from "../base/components/Timer";
/// components
import CenteredModal from "../base/components/CenteredModal";
import moment from "moment";

///// Util
import {
  restrictToOnlyNumbersTwo,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
  restrictToOnlyNumbers,
  regexIsCellphoneValid,
} from "../../../Libraries/Utils/regex";

/// assets
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useTranslation } from "react-i18next";

//Actions
import {
  cleanReducerCellPhoneDataValidation,
  cleanTwilioCellValidationReducer,
  requestTwilioCellPhoneCodeValidation,
  requestTwilioCellPhoneValidation,
} from "./../../ReduxSaga/Actions/Twilio";
import {
  cleanEmailReducerValidation,
  requestEmailValidation,
} from "./../../ReduxSaga/Actions/Email";
import ClientService from "./../../../Libraries/Client";

const NewRecord = ({
  isFromLogin,
  isLanguageLoaded,
  enqueueSnackbar,
  formData,
  setFormData,
  setGender,
  acceptTermsAndConditions,
  setAcceptTermsAndConditions,
  benefitType,
}) => {
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.email = useSelector((state) => state.email);
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.client = useSelector((state) => state.client);
  appReducers.general = useSelector((state) => state.general);
  appReducers.home = useSelector((state) => state.home);

  //Translation
  const { t, i18n } = useTranslation();

  //Variables
  const isVerifyData = true;
  const [snackActive, setSnackActive] = useState(false);
  //// date
  const maxDate = moment().subtract(18, "years").add(1, "days");

  //State
  ///// phone
  const [cellPhone, setCellPhone] = useState("");
  const [showVerficationCode, setShowVerficationCode] = useState(false);
  //const [isCellPhoneVerified, setIsCellPhoneVerified] = useState(false);
  const [cellPhoneCodeOne, setCellPhoneCodeOne] = useState("");
  const [cellPhoneCodeTwo, setCellPhoneCodeTwo] = useState("");
  const [cellPhoneCodeThree, setCellPhoneCodeThree] = useState("");
  const [cellPhoneCodeFour, setCellPhoneCodeFour] = useState("");
  const [cellPhoneCodeFive, setCellPhoneCodeFive] = useState("");
  const [cellPhoneCodeSix, setCellPhoneCodeSix] = useState("");
  //// contador
  const [isReturnActive, setIsReturnActive] = useState(true);
  const [isStartCountDown, setIsStartCountDown] = useState(false);
  const [isCountDown, setIsCountDown] = useState(0);
  ////// modal
  const [termsConditons, setTermsConditons] = useState(false);

  ///// EFFECTS
  useEffect(() => {
    console.log("New Record benefitType", benefitType);
  }, [benefitType]);

  ///EFFECT ON SHOW VERIFICATION CODE TWILIO CODE CELLPHONE
  useEffect(() => {
    if (formData.cellphone.isSendedSMS) {
      setIsStartCountDown(true);
      setIsCountDown(60 * 5);
    } else if (isCountDown === 0 && formData.cellphone.isSendedSMS) {
      setIsReturnActive(true);
    } else {
      setIsStartCountDown(false);
      setIsCountDown(60 * 5);
      setIsReturnActive(false);
    }
  }, [formData.cellphone.isSendedSMS]);

  // useEffect(() => {
  //   if (snackActive) {
  //     enqueueSnackbar(t("__forms_email_validation_error_execution_message"), {
  //       variant: "error",
  //     });
  //   }
  // }, [snackActive]);

  //Effect on email validation
  useEffect(() => {
    if (
      typeof appReducers?.email?.errorEmailValidation !== "undefined" ||
      typeof appReducers?.email?.code !== "undefined"
    ) {
      if (!appReducers?.email?.errorEmailValidation) {
        setFormData({
          ...formData,
          email: {
            ...formData.email,
            isValid: true,
          },
        });
      } else {
        setFormData({
          ...formData,
          email: {
            ...formData.email,
            isValid: false,
          },
        });
        switch (appReducers?.email?.code) {
          case -100: //error de ejecucion
            enqueueSnackbar(
              t("__forms_email_validation_error_execution_message"),
              {
                variant: "error",
              }
            );
            break;
          case -200: //error email en bd
            enqueueSnackbar(
              t("__forms_email_validation_error_db_duplicity_message"),
              {
                variant: "error",
              }
            );
            break;
          case -900: //error zerobounce
            enqueueSnackbar(
              t("__forms_email_validation_error_zerobounce_message"),
              {
                variant: "error",
              }
            );
            break;
          default:
            enqueueSnackbar(
              t("__forms_email_validation_error_execution_message"),
              {
                variant: "error",
              }
            );
            break;
        }
      }
      dispatch(cleanEmailReducerValidation());
    }
  }, [appReducers?.email?.errorEmailValidation, appReducers?.email?.code]);

  //Effect on cellphone code is complete to request code validation
  useEffect(() => {
    const code = `${cellPhoneCodeOne}${cellPhoneCodeTwo}${cellPhoneCodeThree}${cellPhoneCodeFour}${cellPhoneCodeFive}${cellPhoneCodeSix}`;

    if (
      code.length === 6 &&
      formData.cellphone.value.length > 0 &&
      regexIsCellphoneValid(formData.cellphone.value)
    ) {
      console.log("Code", code);
      dispatch(
        requestTwilioCellPhoneCodeValidation(formData.cellphone.value, code)
      );
    }
  }, [
    formData.cellphone.value,
    cellPhoneCodeOne,
    cellPhoneCodeTwo,
    cellPhoneCodeThree,
    cellPhoneCodeFour,
    cellPhoneCodeFive,
    cellPhoneCodeSix,
  ]);

  //Effect on Twilio CellPhone Validation
  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellValidation !== "undefined" || //limpiar este reducer
      typeof appReducers?.twilio?.code !== "undefined"
    ) {
      //debugger;
      if (!appReducers?.twilio?.errorTwilioCellValidation) {
        //setShowVerficationCode(true);
        if (appReducers?.twilio?.code === 100) {
          setFormData({
            ...formData,
            cellphone: {
              ...formData.cellphone,
              isSendedSMS: true,
              isValid: true,
            },
          });
        } else if (appReducers?.twilio?.code === 110) {
          //Solo se validó si es celular y si esta en la BD (no envía el SMS)
          setFormData({
            ...formData,
            cellphone: {
              ...formData.cellphone,
              isSendedSMS: true,
              isValid: true,
              isValidSMSCode: true,
            },
          });
        }
      } else {
        //setShowVerficationCode(false);
        setFormData({
          ...formData,
          cellphone: {
            ...formData.cellphone,
            isSendedSMS: false,
            isValid: false,
          },
        });
        switch (appReducers?.twilio?.code) {
          case -100: //error de ejecucion
            enqueueSnackbar(t("__forms_twilio_cell_validation_gen_error"), {
              variant: "error",
            });
            break;
          case -200: //error celular en bd
            //console.log("trono el wilio");
            enqueueSnackbar(t("__forms_twilio_cell_validation_db_error"), {
              variant: "error",
            });
            break;
          case -300: //error celular no es un numero valido
            enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
              variant: "error",
            });
            break;
          default:
            enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
              variant: "error",
            });
            break;
        }
      }
      //limpiar
      dispatch(cleanTwilioCellValidationReducer());
    }
  }, [
    appReducers?.twilio?.errorTwilioCellValidation,
    appReducers?.twilio?.code,
  ]);

  //*** Effect on twilio code Validation ***//
  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellCodeValidation !== "undefined" //limpiar este reducer
    ) {
      if (appReducers?.twilio?.errorTwilioCellCodeValidation) {
        enqueueSnackbar(t("__forms_twilio_cell_val_code_error_message"), {
          variant: "error",
        });
        setFormData({
          ...formData,
          cellphone: {
            ...formData.cellphone,
            isValidSMSCode: false,
          },
        });
      } else {
        setFormData({
          ...formData,
          cellphone: {
            ...formData.cellphone,
            isValidSMSCode: true,
          },
        });
        //setIsCellPhoneVerified(true);
        //setShowVerficationCode(false);
        setCellPhoneCodeOne("");
        setCellPhoneCodeTwo("");
        setCellPhoneCodeThree("");
        setCellPhoneCodeFour("");
        setCellPhoneCodeFive("");
        setCellPhoneCodeSix("");
        enqueueSnackbar(t("__login_New_Account_Phone_Verify_label"), {
          variant: "success",
        });
      }
      dispatch(cleanReducerCellPhoneDataValidation());
    }
  }, [appReducers?.twilio?.errorTwilioCellCodeValidation]);

  ///// HANDLERS
  const handleResendSmsCode = () => {
    setCellPhoneCodeOne("");
    setCellPhoneCodeTwo("");
    setCellPhoneCodeThree("");
    setCellPhoneCodeFour("");
    setCellPhoneCodeFive("");
    setCellPhoneCodeSix("");
    setIsStartCountDown(true);
    setIsCountDown(60 * 5);
    setIsReturnActive(false);
  };
  const handleEmailValidation = () => {
    console.log("handleEmailValidation");
    dispatch(requestEmailValidation(formData.email.value, false));
  };
  const handlePhoneValidation = () => {
    console.log("handlePhoneValidation");
    if (
      formData.cellphone.value.length > 0 &&
      regexIsCellphoneValid(formData.cellphone.value)
    ) {
      console.log("benefitType", benefitType);

      // if (isFromLogin || benefitType === 5) {
      //   //////
      //   console.log("ByPass Cellphone Validation")
      //   setFormData({
      //     ...formData,
      //     cellphone: {
      //       ...formData.cellphone,
      //       isSendedSMS: true,
      //       isValid: true,
      //       isValidSMSCode: true
      //     },
      //   });

      //   /////
      // } else {
      //   dispatch(
      //     requestTwilioCellPhoneValidation(formData.cellphone.value, true)
      //   );
      // }

      dispatch(
        requestTwilioCellPhoneValidation(
          formData.cellphone.value,
          true,
          isFromLogin ? 5 : benefitType
        )
      );

      //FIXME: DELETE (ONLY FOR TEST)
      // setFormData({
      //   ...formData,
      //   cellphone: {
      //     ...formData.cellphone,
      //     isSendedSMS: true,
      //   },
      // });
    }
  };
  const handleGetGenderByName = async (name) => {
    const result = await ClientService.GetGenderPosibilityByName(name);
    if (typeof result !== "undefined" && typeof result === "object") {
      if (typeof result.gender !== "undefined" && result.gender !== null)
        setGender(result.gender === "male" ? "M" : "F");
      else setGender("F");
    }
  };

  return (
    <div
      className={`new__account__form ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      {/* inicio de cabecero de formulario */}
      <div
        className={`new__account__form__header ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div>
          <div
            className={`new__account__form__header__title ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLanguageLoaded ? (
              !isFromLogin && benefitType === 5
              ? t("__login_Create_Account_Client_Info_Title")
              : t("__login_Create_Account_Title")
            ) : (
              <Skeleton variant="rect" width={150} height={30} />
            )}
          </div>
        </div>
      </div>

      {/* fin de cabecero de formulario */}

      {/* incio del formulario informacion personal */}
      <div
        className={`new__account__form__content ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {/* nombre y apellido */}
        <div
          className={`new__account__form__content__row ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {/* nombre */}
          <FormControl variant="outlined">
            <OutlinedInput
              id="username"
              placeholder={
                isLanguageLoaded
                  ? t("__login_Create_Account_Txt_Name_Label")
                  : ""
              }
              //size="small"
              value={formData.name.value}
              endAdornment={
                <InputAdornment position="end">
                  {formData.name.error || formData.name.isEmpty ? (
                    <Clear className="errorIcon" />
                  ) : (
                    <Check className="successIcon" />
                  )}
                </InputAdornment>
              }
              aria-describedby="username"
              inputProps={{
                "aria-label": "username",
              }}
              onInput={(e) => {
                setFormData({
                  ...formData,
                  name: {
                    ...formData.name,
                    isEmpty: e.target.value.length <= 0,
                    value: e.target.value.toUpperCase(),
                  },
                });
              }}
              onBlur={(e) => {
                handleGetGenderByName(formData.name.value.trim().split(" ")[0]);
              }}
            />
            <FormHelperText id="username">
              {isLanguageLoaded && formData.name.isEmpty
                ? t("__forms_empty_field_error_message")
                : " "}
            </FormHelperText>
          </FormControl>
          {/* apellido */}
          <FormControl variant="outlined">
            <OutlinedInput
              id="lastname"
              placeholder={
                isLanguageLoaded
                  ? t("__login_Create_Account_Txt_LastName_Label")
                  : ""
              }
              //size="small"
              value={formData.lastname.value}
              endAdornment={
                <InputAdornment position="end">
                  {formData.lastname.error || formData.lastname.isEmpty ? (
                    <Clear className="errorIcon" />
                  ) : (
                    <Check className="successIcon" />
                  )}
                </InputAdornment>
              }
              aria-describedby="lastname"
              inputProps={{
                "aria-label": "lastname",
              }}
              onInput={(e) => {
                setFormData({
                  ...formData,
                  lastname: {
                    ...formData.lastname,
                    isEmpty: e.target.value.length <= 0,
                    value: e.target.value.toUpperCase(),
                  },
                });
              }}
            />
            <FormHelperText id="lastname">
              {isLanguageLoaded && formData.lastname.isEmpty
                ? t("__forms_empty_field_error_message")
                : " "}
            </FormHelperText>
          </FormControl>
        </div>
        {/* fecha de nacimiento y email*/}
        <div
          className={`new__account__form__content__row ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {/* fecha de nacimiento */}
          {(isFromLogin || benefitType !== 5) && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                closeOnSelect
                label={
                  isLanguageLoaded
                    ? t("__form__new__members__title__info__personal__birthday")
                    : ""
                }
                openTo="year"
                maxDate={maxDate}
                format="YYYY-MM-DD"
                size="small"
                value={formData.birthdate.value}
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    birthdate: {
                      ...formData.birthdate,
                      isEmpty: false,
                      value: date,
                    },
                  });
                }}
                slotProps={{
                  textField: {
                    //size: "small",
                    helperText:
                      isLanguageLoaded && formData.birthdate.isEmpty
                        ? t("__forms_empty_field_error_message")
                        : " ",
                  },
                }}
              />
            </LocalizationProvider>
          )}
          {/* email */}
          <FormControl variant="outlined">
            <OutlinedInput
              id="email"
              placeholder={
                isLanguageLoaded
                  ? t("__login_Create_Account_Txt_Email_Label")
                  : ""
              }
              //size="small"
              value={formData.email.value}
              endAdornment={
                <InputAdornment position="end">
                  {formData.email.error ||
                  formData.email.isEmpty ||
                  !formData.email.isValid ? (
                    <Clear className="errorIcon" />
                  ) : (
                    <Check className="successIcon" />
                  )}
                </InputAdornment>
              }
              aria-describedby="email"
              inputProps={{
                "aria-label": "email",
              }}
              onInput={(e) => {
                if (e.target.value.length <= 0) {
                  setFormData({
                    ...formData,
                    email: {
                      ...formData.email,
                      isValid: false,
                      error: !regexIsEmailAddressValid(e.target.value),
                      isEmpty: e.target.value.length <= 0,
                      value: restrictToOnlyLettersForEmail(e),
                    },
                  });
                } else {
                  setFormData({
                    ...formData,
                    email: {
                      ...formData.email,
                      isValid: false,
                      error: !regexIsEmailAddressValid(e.target.value),
                      isEmpty: e.target.value.length <= 0,
                      value: restrictToOnlyLettersForEmail(e),
                    },
                  });
                }
              }}
              onBlur={() => {
                if (!regexIsEmailAddressValid(formData.email.value)) {
                  setFormData({
                    ...formData,
                    email: {
                      ...formData.email,
                      error: true,
                    },
                  });
                } else if (
                  formData.email.value.length > 0 &&
                  !formData.email.isValid
                ) {
                  handleEmailValidation();
                }
              }}
            />
            <FormHelperText id="email">
              {/* {isLanguageLoaded && formData.email.isEmpty
                ? t("__forms_empty_field_error_message")
                : " "} */}{" "}
              {isLanguageLoaded && benefitType === 5 &&
              (formData.email.error || formData.email.isEmpty )
                ? t("__message_invalid_email_label")
                : " "}
            </FormHelperText>
          </FormControl>
        </div>

        {/* telefono y codigo telefono */}
        {(isFromLogin || benefitType !== 5) && (
          <div
            className={`new__account__form__content__row ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {/* telefono */}
            <FormControl variant="outlined">
              <OutlinedInput
                id="phone"
                placeholder={
                  isLanguageLoaded ? t("__new_members_form_mobile_label") : ""
                }
                //size="small"
                value={formData.cellphone.value}
                endAdornment={
                  <InputAdornment position="end">
                    {formData.cellphone.error || formData.cellphone.isEmpty ? (
                      <Clear className="errorIcon" />
                    ) : (
                      <Check className="successIcon" />
                    )}
                  </InputAdornment>
                }
                aria-describedby="phone"
                inputProps={{
                  "aria-label": "phone",
                }}
                onInput={(e) => {
                  if (e.target.value.length <= 0) {
                    setFormData({
                      ...formData,
                      cellphone: {
                        ...formData.cellphone,
                        isValidSMSCode: false,
                        isSendedSMS: false,
                        smsCodeTyped: "",
                        isValid: false,
                        error:
                          e.target.value.length > 0 &&
                          e.target.value.length !== 10,
                        isEmpty: e.target.value.length === 0,
                        value: restrictToOnlyNumbers(e, 10).toString(),
                      },
                    });
                  } else {
                    setFormData({
                      ...formData,
                      cellphone: {
                        ...formData.cellphone,
                        isValidSMSCode: false,
                        isSendedSMS: false,
                        smsCodeTyped: "",
                        error:
                          e.target.value.length > 0 &&
                          e.target.value.length !== 10,
                        isEmpty: e.target.value.length === 0,
                        value: restrictToOnlyNumbers(e, 10).toString(),
                      },
                    });
                  }
                }}
              />
              <FormHelperText id="lastname">
                {isLanguageLoaded &&
                (formData.cellphone.error || formData.cellphone.isEmpty)
                  ? t("__login_New_Account_Error_phone_invalid_label")
                  : " "}
              </FormHelperText>
            </FormControl>

            {/* codigo telefono */}
            <div style={{ alignSelf: "center", marginBottom: 5 }}>
              {formData.cellphone.isSendedSMS &&
                !formData.cellphone.isValidSMSCode && (
                  //true && (
                  <div
                    className={`phone_number ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <input
                      id="number1"
                      className={`number_phone ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                      placeholder="0"
                      maxLength={1}
                      aria-describedby="my-helper-text"
                      type={isTablet || isMobile ? "number" : "text"}
                      onInput={(e) => {
                        setCellPhoneCodeOne(restrictToOnlyNumbersTwo(e));
                        document.getElementById("number2").focus();
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          document.getElementById("number1").focus();
                        }
                      }}
                      value={cellPhoneCodeOne}
                    />
                    <input
                      id="number2"
                      className={`number_phone ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                      placeholder="0"
                      aria-describedby="my-helper-text"
                      maxLength={1}
                      type={isTablet || isMobile ? "number" : "text"}
                      onInput={(e) => {
                        setCellPhoneCodeTwo(restrictToOnlyNumbersTwo(e));
                        document.getElementById("number3").focus();
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          document.getElementById("number1").focus();
                        }
                      }}
                      value={cellPhoneCodeTwo}
                    />
                    <input
                      id="number3"
                      className={`number_phone ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                      placeholder="0"
                      aria-describedby="my-helper-text"
                      value={cellPhoneCodeThree}
                      maxLength={1}
                      type={isTablet || isMobile ? "number" : "text"}
                      onInput={(e) => {
                        setCellPhoneCodeThree(restrictToOnlyNumbersTwo(e));
                        document.getElementById("number4").focus();
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          document.getElementById("number2").focus();
                        }
                      }}
                    />
                    <input
                      id="number4"
                      className={`number_phone ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                      placeholder="0"
                      aria-describedby="my-helper-text"
                      value={cellPhoneCodeFour}
                      maxLength={1}
                      type={isTablet || isMobile ? "number" : "text"}
                      onInput={(e) => {
                        setCellPhoneCodeFour(restrictToOnlyNumbersTwo(e));
                        document.getElementById("number5").focus();
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          document.getElementById("number3").focus();
                        }
                      }}
                    />
                    <input
                      id="number5"
                      className={`number_phone ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                      placeholder="0"
                      aria-describedby="my-helper-text"
                      value={cellPhoneCodeFive}
                      maxLength={1}
                      type={isTablet || isMobile ? "number" : "text"}
                      onInput={(e) => {
                        setCellPhoneCodeFive(restrictToOnlyNumbersTwo(e));
                        document.getElementById("number6").focus();
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          document.getElementById("number4").focus();
                        }
                      }}
                    />
                    <input
                      id="number6"
                      className={`number_phone ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                      placeholder="0"
                      aria-describedby="my-helper-text"
                      value={cellPhoneCodeSix}
                      maxLength={1}
                      type={isTablet || isMobile ? "number" : "text"}
                      onInput={(e) => {
                        setCellPhoneCodeSix(restrictToOnlyNumbersTwo(e));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Backspace") {
                          document.getElementById("number5").focus();
                        }
                      }}
                    />

                    <div>
                      <Timer
                        setIsReturnActive={setIsReturnActive}
                        isStartCountDown={isStartCountDown}
                        setIsStartCountDown={setIsStartCountDown}
                        isCountDown={isCountDown}
                        setIsCountDown={setIsCountDown}
                      />
                    </div>
                  </div>
                )}

              {isLanguageLoaded ? (
                <Fragment>
                  {(formData.cellphone.error ||
                    formData.cellphone.isEmpty ||
                    !formData.cellphone.isSendedSMS) && (
                    <Button
                      disabled={formData.cellphone.value.length < 10}
                      variant="contained"
                      className="button__with__icon bg__dark__red"
                      onClick={() => {
                        //setShowVerficationCode(true);
                        if (
                          formData.cellphone.value.length === 10 &&
                          !formData.cellphone.isValidSMSCode
                        ) {
                          handlePhoneValidation();
                        }
                      }}
                    >
                      {t("btn_validate")}
                    </Button>
                  )}
                </Fragment>
              ) : (
                <Skeleton width={200} height={20} variant="wave" />
              )}

              {isReturnActive && (
                <div
                  className={`phone_number_return ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <Link onClick={handleResendSmsCode}>
                    {t("__timer_btn_code_is_forwarded")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}

        {/* terms */}
        {!isFromLogin && (
          <div
            className={`new__account__form__content__row terms_and_conditions ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {isLanguageLoaded ? (
              <>
                <Checkbox
                  checked={acceptTermsAndConditions}
                  onChange={(e) => {
                    setAcceptTermsAndConditions(e.target.checked);
                  }}
                  className="checkbox__new__color"
                />
                <Typography
                  variant="h7"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setTermsConditons(true);
                  }}
                >
                  {isLanguageLoaded ? t("__termsAndConditions_title_Txt") : ""}
                </Typography>
              </>
            ) : (
              <Skeleton width={200} height={30} variant="wave" />
            )}
          </div>
        )}
      </div>
      {/* fin del formulario informacion personal */}

      <CenteredModal
        open={termsConditons}
        title={t("__termsAndConditions_title_Txt")}
        message={
          <div className="polices__privacy__container">
            <div>
              <Typography variant="body1" gutterBottom>
                <div>{t("__termsAndConditions_paragraph1_Txt")} </div>
                <br />
                <div>{t("__termsAndConditions_paragraph2_Txt")} </div>
                <br />
                <div>{t("__termsAndConditions_paragraph3_Txt")} </div>
                <br />
              </Typography>
            </div>
            <div className="text__golden">
              <Typography variant="subtitle1" gutterBottom>
                {t("__termsAndConditions_Rights_title_Txt")}
              </Typography>
            </div>
            <div>
              <Typography variant="body1" gutterBottom>
                <div>{t("__termsAndConditions__Rights_paragraph1_Txt")} </div>
                <br />
                <div>{t("__termsAndConditions_Rights_paragraph2_Txt")} </div>
                <br />
                <div>{t("__termsAndConditions_Rights_paragraph3_Txt")} </div>
                <br />
              </Typography>
            </div>
          </div>
        }
        //  aqui se activa el skeleton
        //isConfirmDisabled={true}
        handleClose={() => {
          setTermsConditons(false);
        }}
        isConfirmDisabled={false}
      />
    </div>
  );
};

export default NewRecord;

import React, { Fragment, useState, useEffect } from "react";
import { FormControlLabel, Checkbox, Tooltip, Skeleton } from "@mui/material";
import CustomerBadge from "./CustomerBadge";
import { isTablet, isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ButtonComponent from "./ButtonComponent";
import BlockProduct from "./BlockProduct";
import {
  capitalizeText,
  formatPrice,
  truncateText,
  percentageUtility,
} from "../../../../Libraries/Utils/utils";
import { requestLoggUserActivity } from "../../../ReduxSaga/Actions/General";
import { requestCarCurrentOrder } from "./../../../ReduxSaga/Actions/Cart";
import { requestSetProductOnFavorite } from "./../../../ReduxSaga/Actions/Products";
import LockProduct from "../../base/components/LockProduct";
//Assets
import { ReactComponent as DiscountIcon } from "./../../../../assets/media/images/icons/discountIcon.svg";
const ultraFakeTags = [
  {
    id: 1,
    tagName: "3 X 2",
    color: "#e56",
  },
  {
    id: 2,
    tagName: "MOM SET",
    color: "#fa5",
  },
  {
    id: 2,
    tagName: "MOM SET",
    color: "#fdd",
  },
  {
    id: 2,
    tagName: "MOM SET",
    color: "#a3e",
  },
  {
    id: 2,
    tagName: "MOM SET",
    color: "#c3d",
  },
];
const fakeColors = [
  { id: 1, color: "#9C223B" },
  { id: 2, color: "#8B493E" },
  { id: 3, color: "#915A59" },
  { id: 4, color: "#FFCDCF" },
  { id: 5, color: "#FF0043" },
  { id: 6, color: "#FF8F00" },
];

const fakeImages = [
  { id: 1, urlPath: "https://www.websizusa.net/imgprod/fullsize/83234.jpg" },
  { id: 2, urlPath: "https://www.websizusa.net/imgprod/fullsize/82556.jpg" },
  { id: 3, urlPath: "https://www.websizusa.net/imgprod/fullsize/83234.jpg" },
  { id: 4, urlPath: "https://www.websizusa.net/imgprod/fullsize/82556.jpg" },
];
const NewProductCard = ({
  product,
  t,
  isLoadingEnable,
  fullWidth,
  clientFavorites = [],
  isLanguageLoaded,
  enqueueSnackbar
}) => {
  //React Router
  const history = useHistory();

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.products = useSelector((state) => state.products);

  //State
  const [isAddFavorite, setIsAddFavorite] = useState(false);
  const [isAddBag, setIsAddBag] = useState(false);
  const [numberProduct, setNumberProduct] = useState(0);
  const [isColorsActive, setIsColorsActive] = useState(true);
  const [showFavoriteIcon, setShowFavoriteIcon] = useState(false);
  const [orderDetailId, setOrderDetailId] = useState(0);
  const [lineTypeOrderDetail, setLineTypeOrderDetail] = useState("");

  const [orderCurrent, setOrderCurrent] = useState({});

  //Effects
  // useEffect(() => {
  //   if (numberProduct <= 0) {
  //     setIsAddBag(false);
  //   } else {
  //     setIsAddBag(true);
  //   }
  // }, [numberProduct]);

  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartOrder !== "undefined") {
      if (
        appReducers?.cart?.cartOrder &&
        !appReducers?.cart?.errorCartOrdererrorCartOrder
      ) {
        const order = appReducers.cart.cartOrder;
        setOrderCurrent(order);
        if (order?.lines.find((d) => d.line.codart === product.itemCode)) {
          //console.log("si se agrego");

          setIsAddBag(true);
          setNumberProduct(
            order?.lines.find((d) => d.line.codart === product.itemCode).line
              .qtyped
          );
          setOrderDetailId(
            order?.lines.find((d) => d.line.codart === product.itemCode).line
              .idpedidodet
          );
          setLineTypeOrderDetail(
            order?.lines.find((d) => d.line.codart === product.itemCode).line
              .tipolinea
          );
        } else {
          setIsAddBag(false);
          setNumberProduct(1);
        }
      }
    }
  }, [
    appReducers?.cart?.cartOrder,
    appReducers?.cart?.errorCartOrder,
    product,
  ]);

  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (!appReducers.general.errorLoadFirstCharge) {
        const { isGuestUser } = appReducers.general.firstChargeModel;
        if (isGuestUser === false) setShowFavoriteIcon(true);
        else setShowFavoriteIcon(false);
      }
    }
  }, [
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
  ]);

  useEffect(() => {
    if (clientFavorites.length > 0 && product.hasOwnProperty("itemCode")) {
      if (
        clientFavorites.find(
          (c) =>
            c.itemCode === product.itemCode && c.typeLine === product.typeLine
        )
      )
        setIsAddFavorite(true);
      else setIsAddFavorite(false);
    } else {
      setIsAddFavorite(false);
    }
  }, [clientFavorites, product]);

  /// HANDLERS
  const handleChangeFavorite = (e) => {
    if (product.hasOwnProperty("itemCode")) {
      dispatch(
        requestSetProductOnFavorite(
          product.itemCode,
          product.typeLine,
          e.target.checked,
          false
        )
      );
      dispatch(
        requestLoggUserActivity(
          39,
          0,
          0,
          "",
          `Click ${
            e.target.checked ? "agrega a favoritos" : "quita de favoritos"
          } (NewProductCard) ${product.itemCode}`
        )
      );
    }
  };
  const handleQtyAdd = () => {
    if (product.hasOwnProperty("itemCode")) {
      const parameters = {
        idPedidoDet: orderDetailId,
        idpromo: 0,
        itemCode: product?.itemCode,
        opc: 5,
        pedidosD: [],
        quantity: 1,
        type: lineTypeOrderDetail,
        withAssign: 0,
      };
      dispatch(requestCarCurrentOrder(parameters, "add"));
      dispatch(
        requestLoggUserActivity(
          9,
          0,
          0,
          product?.itemCode,
          "Se incrementó la cantidad en 1 del producto"
        )
      );
    }
  };
  const handleQtyMinus = () => {
    if (product.hasOwnProperty("itemCode")) {
      const parameters = {
        idPedidoDet: orderDetailId,
        idpromo: 0,
        itemCode: product?.itemCode,
        opc: 5,
        pedidosD: [],
        quantity: -1,
        type: lineTypeOrderDetail,
        withAssign: 0,
      };
      dispatch(requestCarCurrentOrder(parameters, "delete"));
      dispatch(
        requestLoggUserActivity(
          10,
          0,
          0,
          product?.itemCode,
          "Se decremento la cantidad en 1 del producto"
        )
      );
    }
  };
  const handleAddProduct = () => {
    if (product.hasOwnProperty("itemCode")) {
      const parameters = {
        idPedidoDet: 1,
        idpromo: 1,
        itemCode: product?.itemCode,
        opc: 0,
        pedidosD: [],
        quantity: 1,
        type: product.typeLine,
        withAssign: 0,
      };
      dispatch(requestCarCurrentOrder(parameters, "add"));
      dispatch(
        requestLoggUserActivity(
          14,
          0,
          0,
          product?.itemCode,
          "Click en Agregar al Carrito"
        )
      );
    }
  };

  return (
    <Fragment>
      <div
        className={`product__card__main ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        } ${fullWidth ? "full__width" : ""}`}
      >
        {isLoadingEnable ? (
          <Skeleton variant="rect" width={"90%"} height={350} />
        ) : (
          <>
            <div className="product__card__main__inner">
              {product.stock <= 0 && <BlockProduct />}
              {showFavoriteIcon && (
                <div
                  className={`product__favorite ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }  `}
                >
                  <FormControlLabel
                    onChange={handleChangeFavorite}
                    control={
                      <Checkbox
                        checked={isAddFavorite}
                        icon={<div className="heartOutlineIcon" />}
                        checkedIcon={<div className="heartFillIcon" />}
                      />
                    }
                  />
                </div>
              )}
              {
                // (
                // product.minAmount > 0 && (!orderCurrent.order || orderCurrent.lines.reduce((accumulator, object) => object.line.codart !== product.itemCode ? accumulator + object.line.montolinai : accumulator ,0) < product.minAmount )
                // ) ||
                product.maxSets > 0 &&
                  orderCurrent?.lines &&
                  orderCurrent.lines.reduce(
                    (accumulator, object) =>
                      object.line.codart === product.itemCode
                        ? accumulator + object.line.qtyped
                        : accumulator,
                    0
                  ) >= product.maxSets && (
                    <LockProduct
                      t={t}
                      product={product}
                      orderCurrent={orderCurrent}
                    />
                  )
              }
              <Link
                to={
                  localStorage.getItem("ZUsaUrlPage")
                    ? `/product-detail/${localStorage.getItem("ZUsaUrlPage")}/${
                        product.itemCode
                      }/${product.typeLine}`
                    : `/product-detail/${product.itemCode}/${product.typeLine}`
                }
                onClick={() =>
                  dispatch(
                    requestLoggUserActivity(
                      14,
                      product?.subCat3 > 0
                        ? product?.subCat3
                        : product?.subCat2 > 0
                        ? product?.subCat2
                        : product?.subCat1 > 0
                        ? product?.subCat1
                        : product?.idCatProductsCategoriesType,
                      product?.idCatProductsCategoriesType,
                      product?.itemCode,
                      "Click en el Articulo: " + product?.nameArticle
                    )
                  )
                }
                dark
              >
                <div
                  className={`product__imagen ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }  `}
                >
                  {product?.tagsOffer && product.tagsOffer.length > 0 && (
                    <div
                      className={`floating__tags__wrapper ${
                        isMobile || isTablet ? "mobile" : ""
                      }`}
                    >
                      {product.tagsOffer.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="floating__tags__wrapper__item"
                            style={{
                              background: item.hexColor,
                            }}
                          >
                            <span>{item.nameTag}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {product.isLabelConfig && (
                    <div
                      className={`product__badge ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }  `}
                    >
                      <CustomerBadge isDetailShow={false} />
                    </div>
                  )}
                  {product.isNewArticle && (
                    <div
                      className={`product__badge__new ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }  `}
                    >
                      <div className="newIcon" />
                    </div>
                  )}

                  <img
                    title={`${
                      product.nameArticle
                    } - ${product.descArticle.substring(0, 38)}...`}
                    alt={`${
                      product.nameArticle
                    } - ${product.descArticle.substring(0, 38)}...`}
                    src={product.image}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                    }}
                  />
                </div>
                <div className="product__description">
                  {truncateText(capitalizeText(product.nameArticle))}
                </div>

                <div className="product__price">
                  {product.regularPrice > 0 &&
                  product.regularPrice > product.promotionPrice &&
                  (product.typeLine === "PD" ||
                    product.typeLine === "P" ||
                    product.typeLine === "FV" ||
                    product.typeLine === "VV" ||
                    product.typeLine === "VF" ||
                    product.typeLine == "3x2") ? (
                    <Fragment>
                      <em className="product__price__promo__normal">
                        {formatPrice(product.regularPrice)}
                      </em>
                      <div className="product__price__with__discount__wrapper">
                        <em className="product__price__promo">
                          {formatPrice(product.promotionPrice)}
                        </em>
                        <div className="product__price__with__discount__wrapper__inner">
                          {/* {console.log("calc", Math.floor(100 - (product.promotionPrice * 100) / product.regularPrice))} */}
                          <span>{` ${
                            // Math.floor(
                            //   100 -
                            //     (product.promotionPrice * 100) /
                            //       product.regularPrice
                            // )
                            percentageUtility(
                              Math.floor(
                                100 -
                                  (product.promotionPrice * 100) /
                                    product.regularPrice
                              )
                            )
                          }`}</span>
                          <DiscountIcon />
                        </div>
                      </div>
                    </Fragment>
                  ) : product.typeLine === "3x2" ? (
                    product.regularPrice === product.promotionPrice ? (
                      <Fragment>
                        <em className="product__price__normal">
                          {formatPrice(product.regularPrice)}
                        </em>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <em className="product__price__normal">
                          <span>{formatPrice(product.regularPrice)}</span>
                          <span style={{ margin: "0 4px" }}>-</span>
                          <span>{formatPrice(product.promotionPrice)}</span>
                        </em>
                      </Fragment>
                    )
                  ) : (
                    <Fragment>
                      <em className="product__price__normal">
                        {formatPrice(product.regularPrice)}
                      </em>
                    </Fragment>
                  )}
                </div>
              </Link>
            </div>
            <div></div>

            {/* falta el tipo de recurso desde la base */}
            {product.familyProducts && product.familyProducts.length > 0 && (
              <div className="product__variants">
                <span>{t("__new_card_product_variants")}</span>
                {product.valueType && product.valueType === 1 ? (
                  <div className="product__variants__colors">
                    {product.familyProducts.slice(0, 3).map((item, index) => {
                      //console.log("item VARIANT", item)
                      return (
                        <Tooltip
                          key={index}
                          title={item.valueDescription}
                          placement="top-end"
                        >
                          {index === 0 ? (
                            <div
                              style={{
                                border: "2px solid #000",
                                borderRadius: 5,
                                width: 22,
                                height: 25,
                                margin: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                onClick={() => {
                                  dispatch(
                                    requestLoggUserActivity(
                                      14,
                                      product?.subCat3 > 0
                                        ? product?.subCat3
                                        : product?.subCat2 > 0
                                        ? product?.subCat2
                                        : product?.subCat1 > 0
                                        ? product?.subCat1
                                        : product?.idCatProductsCategoriesType,
                                      product?.idCatProductsCategoriesType,
                                      product?.itemCode,
                                      "Click en el Articulo: " +
                                        product?.nameArticle
                                    )
                                  );

                                  const destination = localStorage.getItem(
                                    "ZUsaUrlPage"
                                  )
                                    ? `/product-detail/${localStorage.getItem(
                                        "ZUsaUrlPage"
                                      )}/${item.anchorArticle}`
                                    : `/product-detail/${item.anchorArticle}`;

                                  history.push(destination);
                                }}
                                style={{
                                  backgroundColor: item.value,
                                  borderRadius: 5,
                                  border: 0,
                                  width: 17,
                                  height: 21,
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                dispatch(
                                  requestLoggUserActivity(
                                    14,
                                    product?.subCat3 > 0
                                      ? product?.subCat3
                                      : product?.subCat2 > 0
                                      ? product?.subCat2
                                      : product?.subCat1 > 0
                                      ? product?.subCat1
                                      : product?.idCatProductsCategoriesType,
                                    product?.idCatProductsCategoriesType,
                                    product?.itemCode,
                                    "Click en el Articulo: " +
                                      product?.nameArticle
                                  )
                                );

                                const destination = localStorage.getItem(
                                  "ZUsaUrlPage"
                                )
                                  ? `/product-detail/${localStorage.getItem(
                                      "ZUsaUrlPage"
                                    )}/${item.anchorArticle}`
                                  : `/product-detail/${item.anchorArticle}`;

                                history.push(destination);
                              }}
                              style={{
                                backgroundColor: item.value,
                                borderRadius: 5,
                                border:
                                  index === 0
                                    ? "2px solid #000"
                                    : "solid 1px #707070",
                                width: 22,
                                height: 25,
                                display: "flex",
                                flexDirection: "column",
                                margin: 2,
                              }}
                            />
                          )}
                        </Tooltip>
                      );
                    })}
                    {product.familyProducts.length > 3 && (
                      <span>+ {product.familyProducts.length - 3}</span>
                    )}
                  </div>
                ) : (
                  <div className="product__variants__imagenes">
                    {product.familyProducts.slice(0, 3).map((item, index) => {
                      return (
                        <Link
                          key={index}
                          to={
                            localStorage.getItem("ZUsaUrlPage")
                              ? `/product-detail/${localStorage.getItem(
                                  "ZUsaUrlPage"
                                )}/${item.anchorArticle}`
                              : `/product-detail/${item.anchorArticle}`
                          }
                          onClick={() =>
                            dispatch(
                              requestLoggUserActivity(
                                14,
                                product?.subCat3 > 0
                                  ? product?.subCat3
                                  : product?.subCat2 > 0
                                  ? product?.subCat2
                                  : product?.subCat1 > 0
                                  ? product?.subCat1
                                  : product?.idCatProductsCategoriesType,
                                product?.idCatProductsCategoriesType,
                                product?.itemCode,
                                "Click en el Articulo: " + product?.nameArticle
                              )
                            )
                          }
                        >
                          <Tooltip
                            key={index}
                            title={item.valueDescription}
                            placement="top-end"
                          >
                            {index === 0 ? (
                              <div
                                style={{
                                  borderRadius: 5,
                                  border: "2px solid #000",
                                  width: 28,
                                  height: 31,
                                  display: "flex",
                                  flexDirection: "column",
                                  margin: 2,
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  className="product__variants__image"
                                  src={item.value}
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  borderRadius: 5,
                                  border: "1px solid #707070",
                                  width: 28,
                                  height: 31,
                                  display: "flex",
                                  flexDirection: "column",
                                  margin: 2,
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  className="product__variants__image"
                                  src={item.value}
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                                  }}
                                />
                              </div>
                            )}
                          </Tooltip>
                        </Link>
                      );
                    })}
                    {product.familyProducts.length > 3 && (
                      <span>+ {product.familyProducts.length - 3}</span>
                    )}
                  </div>
                )}
              </div>
            )}

            {/* {product.stock <= 0 && (
              <ButtonComponent
                countButton={false}
                handleActionPrimary={() => {}}
                handleActionSecondary={() => {}}
                valueCount=""
                iconClass="searchIcon"
                textButton={t("btn_cart_search_new")}
                leftAling={true}
              />
            )} */}

            {product.typeLine !== "P" &&
              product.typeLine !== "PD" &&
              product.typeLine !== "N" &&
              !product.isLabelConfig &&
              product.stock > 0 && (
                <ButtonComponent
                  countButton={false}
                  handleActionPrimary={() => {
                    ////regla de cantidad Maxima de Sets
                    if (
                      product.maxSets > 0 &&
                      orderCurrent?.lines &&
                      orderCurrent.lines.reduce(
                        (accumulator, object) =>
                          object.line.codart === product.itemCode
                            ? accumulator + object.line.qtyped
                            : accumulator,
                        0
                      ) >= product.maxSets
                    ) {
                      //notificar
                      dispatch(
                        requestLoggUserActivity(
                          14,
                          product?.subCat3 > 0
                            ? product?.subCat3
                            : product?.subCat2 > 0
                            ? product?.subCat2
                            : product?.subCat1 > 0
                            ? product?.subCat1
                            : product?.idCatProductsCategoriesType,
                          product?.idCatProductsCategoriesType,
                          product?.itemCode,
                          "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                        )
                      );
                      enqueueSnackbar(
                        t("__general_warning_information_cant_max"),
                        {
                          variant: "warning",
                        }
                      );
                    } else {
                      dispatch(
                        requestLoggUserActivity(
                          14,
                          product?.subCat3 > 0
                            ? product?.subCat3
                            : product?.subCat2 > 0
                            ? product?.subCat2
                            : product?.subCat1 > 0
                            ? product?.subCat1
                            : product?.idCatProductsCategoriesType,
                          product?.idCatProductsCategoriesType,
                          product?.itemCode,
                          "Click en Configurar Set: " + product?.nameArticle
                        )
                      );
                      history.push(
                        `/kit-config/${product.itemCode}/${product.typeLine}`
                      );
                    }
                  }}
                  handleActionSecondary={() => {}}
                  valueCount=""
                  iconClass="bagWhiteIcon"
                  textButton={isLanguageLoaded ? t("btn_cart_set_up_new") : ""}
                  leftAling={true}
                />
              )}

            {!isAddBag &&
            (product.typeLine === "N" ||
              product.typeLine === "PD" ||
              product.typeLine === "P") &&
            product.stock > 0 &&
            !product.isLabelConfig ? (
              <>
                <ButtonComponent
                  countButton={false}
                  handleActionPrimary={() => {
                    ////regla de cantidad Maxima de Sets
                    if (
                      product.maxSets > 0 &&
                      orderCurrent?.lines &&
                      orderCurrent.lines.reduce(
                        (accumulator, object) =>
                          object.line.codart === product.itemCode
                            ? accumulator + object.line.qtyped
                            : accumulator,
                        0
                      ) >= product.maxSets
                    ) {
                      //notificar
                      dispatch(
                        requestLoggUserActivity(
                          14,
                          product?.subCat3 > 0
                            ? product?.subCat3
                            : product?.subCat2 > 0
                            ? product?.subCat2
                            : product?.subCat1 > 0
                            ? product?.subCat1
                            : product?.idCatProductsCategoriesType,
                          product?.idCatProductsCategoriesType,
                          product?.itemCode,
                          "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                        )
                      );
                      enqueueSnackbar(
                        t("__general_warning_information_cant_max"),
                        {
                          variant: "warning",
                        }
                      );
                    } else {
                      handleAddProduct();
                    }
                  }}
                  valueCount=""
                  iconClass="bagWhiteIcon"
                  textButton={isLanguageLoaded ? t("btn_cart_add_new") : ""}
                  leftAling={true}
                />
              </>
            ) : (
              !isAddBag &&
              product.isLabelConfig &&
              product.stock > 0 &&
              (product.typeLine === "N" ||
                product.typeLine === "PD" ||
                product.typeLine === "P") && (
                <>
                  <ButtonComponent
                    countButton={false}
                    handleActionPrimary={() => {
                      ////regla de cantidad Maxima de Sets
                      if (
                        product.maxSets > 0 &&
                        orderCurrent?.lines &&
                        orderCurrent.lines.reduce(
                          (accumulator, object) =>
                            object.line.codart === product.itemCode
                              ? accumulator + object.line.qtyped
                              : accumulator,
                          0
                        ) >= product.maxSets
                      ) {
                        //notificar
                        dispatch(
                          requestLoggUserActivity(
                            14,
                            product?.subCat3 > 0
                              ? product?.subCat3
                              : product?.subCat2 > 0
                              ? product?.subCat2
                              : product?.subCat1 > 0
                              ? product?.subCat1
                              : product?.idCatProductsCategoriesType,
                            product?.idCatProductsCategoriesType,
                            product?.itemCode,
                            "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                          )
                        );
                        enqueueSnackbar(
                          t("__general_warning_information_cant_max"),
                          {
                            variant: "warning",
                          }
                        );
                      } else {
                        dispatch(
                          requestLoggUserActivity(
                            14,
                            product?.subCat3 > 0
                              ? product?.subCat3
                              : product?.subCat2 > 0
                              ? product?.subCat2
                              : product?.subCat1 > 0
                              ? product?.subCat1
                              : product?.idCatProductsCategoriesType,
                            product?.idCatProductsCategoriesType,
                            product?.itemCode,
                            "Click en Personalizar Fragancia: " +
                              product?.nameArticle
                          )
                        );
                        history.push(
                          `/personalized-fragance/it/${product.itemCode}`
                        );
                      }
                    }}
                    valueCount=""
                    iconClass="bagWhiteIcon"
                    textButton={isLanguageLoaded ? t("btn_customer_new") : ""}
                    leftAling={true}
                    ///Personalizar
                  />
                </>
              )
            )}

            {isAddBag &&
            (product.typeLine === "N" ||
              product.typeLine === "PD" ||
              product.typeLine === "P") &&
            product.stock > 0 &&
            !product.isLabelConfig ? (
              <>
                <ButtonComponent
                  countButton={true}
                  handleActionPrimary={handleQtyMinus}
                  handleActionSecondary={() => {
                    ////regla de cantidad Maxima de Sets
                    if (
                      product.maxSets > 0 &&
                      orderCurrent?.lines &&
                      orderCurrent.lines.reduce(
                        (accumulator, object) =>
                          object.line.codart === product.itemCode
                            ? accumulator + object.line.qtyped
                            : accumulator,
                        0
                      ) >= product.maxSets
                    ) {
                      //notificar
                      dispatch(
                        requestLoggUserActivity(
                          14,
                          product?.subCat3 > 0
                            ? product?.subCat3
                            : product?.subCat2 > 0
                            ? product?.subCat2
                            : product?.subCat1 > 0
                            ? product?.subCat1
                            : product?.idCatProductsCategoriesType,
                          product?.idCatProductsCategoriesType,
                          product?.itemCode,
                          "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                        )
                      );
                      enqueueSnackbar(
                        t("__general_warning_information_cant_max"),
                        {
                          variant: "warning",
                        }
                      );
                    } else {
                      handleQtyAdd(); //este al final
                    }
                  }}
                  valueCount={numberProduct}
                  iconClass="bagWhiteIcon"
                  textButton=""
                  leftAling={true}
                />
              </>
            ) : (
              isAddBag &&
              product.isLabelConfig &&
              product.stock > 0 &&
              (product.typeLine === "N" ||
                product.typeLine === "PD" ||
                product.typeLine === "P") && (
                <>
                  <ButtonComponent
                    countButton={false}
                    handleActionPrimary={() => {
                      ////regla de cantidad Maxima de Sets
                      if (
                        product.maxSets > 0 &&
                        orderCurrent?.lines &&
                        orderCurrent.lines.reduce(
                          (accumulator, object) =>
                            object.line.codart === product.itemCode
                              ? accumulator + object.line.qtyped
                              : accumulator,
                          0
                        ) >= product.maxSets
                      ) {
                        //notificar
                        dispatch(
                          requestLoggUserActivity(
                            14,
                            product?.subCat3 > 0
                              ? product?.subCat3
                              : product?.subCat2 > 0
                              ? product?.subCat2
                              : product?.subCat1 > 0
                              ? product?.subCat1
                              : product?.idCatProductsCategoriesType,
                            product?.idCatProductsCategoriesType,
                            product?.itemCode,
                            "Click en Agregar al Carrito. Se reedirecciona a categorias por regla de Monto cantidad Maxima de Sets "
                          )
                        );
                        enqueueSnackbar(
                          t("__general_warning_information_cant_max"),
                          {
                            variant: "warning",
                          }
                        );
                      } else {
                        dispatch(
                          requestLoggUserActivity(
                            14,
                            product?.subCat3 > 0
                              ? product?.subCat3
                              : product?.subCat2 > 0
                              ? product?.subCat2
                              : product?.subCat1 > 0
                              ? product?.subCat1
                              : product?.idCatProductsCategoriesType,
                            product?.idCatProductsCategoriesType,
                            product?.itemCode,
                            "Click en Personalizar Fragancia: " +
                              product?.nameArticle
                          )
                        );

                        history.push(
                          `/personalized-fragance/it/${product.itemCode}`
                        );
                      }
                    }}
                    handleActionSecondary={() => {}}
                    valueCount=""
                    iconClass="bagWhiteIcon"
                    textButton={isLanguageLoaded ? t("btn_customer_new") : ""}
                    leftAling={true}
                    ///Personalizar
                  />
                </>
              )
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default NewProductCard;

import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Skeleton, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import { useHistory } from "react-router-dom";
////components
import NewLoginContainer from "../../../login/components/NewRecord/NewLoginContainer";
import NewRecord from "../../../login/NewRecord";
import AddressSection from "../../../base/components/AddressSection";
import CenteredModal from "../../../base/components/CenteredModal";
import moment from "moment";
import {
  requestCleanUserDataValidation,
  requestRegisterFromCheckoutReducer,
  requestRegistrationLoginData,
  requestUserDataValidation,
} from "../../../../ReduxSaga/Actions/Client";
import { requestFirstChargeSiteReload } from "../../../../ReduxSaga/Actions/General";

const NewLoginStep = (props) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  /// TRANSLATION
  const { t, i18n } = useTranslation();
  const [isLoadingEnable, setIsLoadingEnable] = useState(false);

  ///REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.client = useSelector((state) => state.client);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.home = useSelector((state) => state.home);

  //React Router
  const history = useHistory();

  ////State
  const [tab, setTab] = useState("");
  const [withOrder, setWithOrder] = useState(false);
  const [isFromLogin, setIsFromLogin] = useState(true);
  const [isOpenCongraModal, setIsOpenCongraModal] = useState(false);
  //Generales
  const [formData, setFormData] = useState({
    name: {
      error: false,
      isEmpty: true,
      value: "",
    },
    lastname: {
      error: false,
      isEmpty: true,
      value: "",
    },
    birthdate: {
      error: false,
      isEmpty: false,
      value: moment().subtract(18, "years"),
    },
    email: {
      error: false,
      isEmpty: true,
      value: "",
      isValid: false,
    },
    cellphone: {
      error: false,
      isEmpty: true,
      value: "",
      isValid: false,
      isSendedSMS: false,
      isValidSMSCode: false,
      smsCodeTyped: "",
    },
    isShipmentAddressVerified: false,
    isFiscalAddressVerified: false,
    shippStreet: {
      error: false,
      isEmpty: true,
      value: "",
    },
    shippSuite: {
      error: false,
      isEmpty: true,
      value: "",
    },
    shippCity: {
      error: false,
      isEmpty: true,
      value: "",
    },
    shippState: {
      error: false,
      isEmpty: true,
      value: "",
    },
    shippZipCode: {
      error: false,
      isEmpty: true,
      value: "",
    },
  });
  const [addressControl, setAddressControl] = useState({
    isSameAddress: true,
    isShippValidAddress: false,
    isFiscalValidAddress: false,
    addressSugestions: [],
    isOpenModalAddressSuggestion: false,
    selectedAddressInModalAddressSuggestion: {},
  });

  const [gender, setGender] = useState("F");
  //// modal validation userData
  const [isOpenValidationDataModal, setIsOpenValidationDataModal] =
    useState(false);
  const [userDataValidationErrors, setUserDataValidationErrors] = useState([]);

  //Effects
  useEffect(() => {
    if (props.targetRoute === "registration") {
      setTab("registration");
    }
  }, [props.targetRoute]);
  //USER DATA VALIDATION REQUEST EFFECT
  useEffect(() => {
    if (
      typeof appReducers?.client?.userDataValidationResponse !== "undefined"
    ) {
      if (appReducers.client.userDataValidationResponse.code === 100) {
        if (
          appReducers.client.userDataValidationResponse.validationList.length <=
          0
        ) {
          //setIsOpenCongraModal(true);
          setIsOpenValidationDataModal(false);
          setUserDataValidationErrors([]);
          handleRegistrationLoginData();
        } else {
          setIsOpenValidationDataModal(true);
          setUserDataValidationErrors(
            appReducers.client.userDataValidationResponse.validationList
          );
        }
      } else {
        setIsOpenValidationDataModal(false);
        setUserDataValidationErrors([]);
        enqueueSnackbar(
          t("Error al validar los datos proporcionados; intenta de nuevo :)"),
          { variant: "error" }
        );
      }
      dispatch(requestCleanUserDataValidation());
    }
  }, [appReducers?.client?.userDataValidationResponse]);
  useEffect(() => {
    if (typeof appReducers?.client?.newRegistrationLoginData !== "undefined") {
      const response = appReducers?.client?.newRegistrationLoginData?.response;
      if (response.code === 100) {
        console.log(
          "newRegistrationLoginData",
          appReducers?.client?.newRegistrationLoginData
        );
        setIsOpenCongraModal(true);

        ///AQUI
        dispatch(
          requestFirstChargeSiteReload(
            appReducers.client.newRegistrationLoginData.userData
          )
        );

        enqueueSnackbar(t("__login_New_Account_Success_Registration"), {
          variant: "success",
        });
        // setIsOpenCongraModal(false);
        // history.replace("/home");
      } else {
        enqueueSnackbar(response?.message, { variant: "error" });
      }
      dispatch(requestRegisterFromCheckoutReducer());
    } else {
      //TODO:

      if (
        typeof appReducers?.client?.errorRegistrationLoginDataResponse !==
        "undefined"
      ) {
        if (appReducers?.client?.errorRegistrationLoginDataResponse === true) {
          enqueueSnackbar(
            appReducers?.client?.newRegistrationLoginData?.response?.message,
            { variant: "error" }
          );
        }
      }
    }
  }, [appReducers?.client?.newRegistrationLoginData]);

  /// contenido
  function getContentOpc(tab) {
    switch (tab) {
      case "registration":
        return (
          <div
            className={`new__account ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <NewRecord
              isFromLogin={isFromLogin}
              isLanguageLoaded={props.isLanguageLoaded}
              enqueueSnackbar={enqueueSnackbar}
              /////
              formData={formData}
              setFormData={setFormData}
              setGender={setGender}
            />
            <AddressSection
              isLanguageLoaded={props.isLanguageLoaded}
              t={t}
              isFromLogin={true}
              enqueueSnackbar={enqueueSnackbar}
              ////
              formData={formData}
              setFormData={setFormData}
              addressControl={addressControl}
              setAddressControl={setAddressControl}
              //////
            />
            <div className="new__account__form__finish">
              <Button
                style={{ minWidth: 200, maxWidth: 200 }}
                className="button__with__icon bg__dark__pink no__left__margin"
                disabled={
                  formData.name.error ||
                  formData.name.isEmpty ||
                  formData.lastname.error ||
                  formData.lastname.isEmpty ||
                  formData.birthdate.error ||
                  formData.birthdate.isEmpty ||
                  formData.email.error ||
                  !formData.email.isValid ||
                  formData.cellphone.error ||
                  !formData.cellphone.isValid ||
                  formData.cellphone.value.length <= 0 ||
                  !formData.cellphone.isValidSMSCode ||
                  !addressControl.isShippValidAddress
                }
                onClick={() => {
                  handleGetCreateRecruitmentValidation();
                }}
              >
                <div className="button__with__icon__svg__wrapper dark__pink__ball">
                  <div
                    className={
                      formData.name.error ||
                      formData.name.isEmpty ||
                      formData.lastname.error ||
                      formData.lastname.isEmpty ||
                      formData.birthdate.error ||
                      formData.birthdate.isEmpty ||
                      formData.email.error ||
                      !formData.email.isValid ||
                      formData.cellphone.error ||
                      !formData.cellphone.isValid ||
                      formData.cellphone.value.length <= 0 ||
                      !formData.cellphone.isValidSMSCode ||
                      !addressControl.isShippValidAddress
                        ? "userGrayLoginAltSmallIcon"
                        : "userOutlineWhiteIcon"
                    }
                  />
                </div>
                <span>
                  {props.isLanguageLoaded ? t("btn_finish_register") : ""}
                </span>
              </Button>
            </div>
          </div>
        );

      default:
        return (
          <NewLoginContainer
            handleGAEvent={props.handleGAEvent}
            enqueueSnackbar={enqueueSnackbar}
            isLanguageLoaded={props.isLanguageLoaded}
          />
        );
    }
  }

  //HANDLERS
  const handleGetCreateRecruitmentValidation = () => {
    console.log("handleGetCreateRecruitmentValidation");
    const formatedBirthDate = moment(formData.birthdate.value).format(
      "YYYY-MM-DD"
    );
    const model = {
      type: "VPD",
      channel: "ZU",
      cellPhone: formData.cellphone.value,
      email: formData.email.value,
      name: formData.name.value,
      lastName: formData.lastname.value,
      motherLastName: "",
      birthdate: formatedBirthDate,
      street: "",
      suite: "",
      state: "",
      city: "",
      zipCode: "",
      userId: 1,
    };

    dispatch(requestUserDataValidation(model));
  };
  const handleRegistrationLoginData = () => {
    console.log("handleRegistrationLoginData");
    const formatedBirthDate = moment(formData.birthdate.value).format(
      "YYYY-MM-DD"
    );
    const model = {
      name: formData.name.value,
      lastname: formData.lastname.value,
      dateBorn: formatedBirthDate,
      email: formData.email.value,
      cellPhone: formData.cellphone.value,
      gender: gender === "" ? "F" : gender,
      idLang: i18n.language === "es" ? 1 : 2,
      street: formData.shippStreet.value,
      suite: formData.shippSuite.value,
      city: formData.shippCity.value,
      state: formData.shippState.value,
      zip: formData.shippZipCode.value,
    };
    console.log("requestRegistrationLoginData with model:", model);
    dispatch(requestRegistrationLoginData(model));
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className={`firstWrapperInBaseContainer new__login__step ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      {}
      <div
        className={`new__login__step__btns__actions ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {isLoadingEnable ? (
          <Skeleton
            width={isTablet ? "20%" : isMobile ? "60%" : "15%"}
            height={100}
            style={{ margin: "0 10px" }}
          />
        ) : (
          <>
            <div
              className={`new__login__step__btn ${
                tab === "registration" ? "active" : "disabled"
              }`}
              onClick={() => {
                props.handleResetInfoGuestForm();
                setTab("registration");
              }}
            >
              <div
                className={` ${
                  tab === "registration"
                    ? "userOrangeIcon"
                    : "userOutlineGrayIcon"
                }`}
              />
              {props.isLanguageLoaded ? (
                t("btn_new_record")
              ) : (
                <Skeleton variant="rect" width={"90%"} height={30} />
              )}
            </div>
          </>
        )}
        {isLoadingEnable ? (
          <Skeleton
            width={isTablet ? "20%" : isMobile ? "60%" : "15%"}
            height={100}
            style={{ margin: "0 10px" }}
          />
        ) : (
          <>
            <div
              className={`new__login__step__btn ${
                tab === "login" ? "active" : "disabled"
              }`}
              onClick={() => {
                setTab("login");
              }}
            >
              <div
                className={` ${
                  tab === "login" ? "userRedIcon" : "userGrayIcon"
                }`}
              />
              {props.isLanguageLoaded ? (
                t("btn_log_in")
              ) : (
                <Skeleton variant="rect" width={"90%"} height={30} />
              )}
            </div>
          </>
        )}
      </div>
      <div className="new__login__step__form">
        <div
          className={`new__login__step__form__content ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {getContentOpc(tab)}
        </div>
      </div>

      {/* MODALS */}
      <CenteredModal
        open={isOpenCongraModal}
        title={t("__login_Create_Account_Registration_Success_Title")}
        message={t("__login_Create_Account_Registration_Success_Message")}
        //  aqui se activa el skeleton
        //isConfirmDisabled={true}
        handleClose={() => {
          setIsOpenCongraModal(false);
          history.replace("/home");
        }}
        isConfirmDisabled={false}
        handleConfirm={() => {
          setIsOpenCongraModal(false);
          history.replace("/home");
        }}
        alternateConfirmLabel={
          !isFromLogin
            ? t("btn_text_checkout")
            : t("__checkout_order_finish_register_label")
        }
      />
      <CenteredModal
        open={isOpenValidationDataModal}
        title={t("__centered_modal_validation_title")}
        message={
          <ul className="centered__modal__content__list__warning">
            {userDataValidationErrors.length > 0 &&
              userDataValidationErrors.map((error, index) => (
                <li key={index}>{t(error.validationDescription)}</li>
              ))}
          </ul>
        }
        handleClose={() => {
          setIsOpenValidationDataModal(false);
        }}
      />
    </div>
  );
};

export default NewLoginStep;

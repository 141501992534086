import {
  RESPONSE_FIRST_CHARGE_SITE,
  RESPONSE_FIRST_CHARGE_SITE_RELOAD,
  RESPONSE_STATES_CITIES,
  RESPONSE_LANDING_REGISTRATION_DATA
} from "../../Constants";

export default function general(state = {}, action) {
  switch (action.type) {
    case RESPONSE_FIRST_CHARGE_SITE: {
      const responseFirstCharge = action.payload.response;
      if (responseFirstCharge.response.code === 100) {
        const firstChargeModel = responseFirstCharge.firstChargeModel;
        localStorage.setItem("ZUsaUGuestId", firstChargeModel.idGuestUser);
        localStorage.setItem("ZUsaUInfo", JSON.stringify(firstChargeModel));
        localStorage.setItem("ZUsaIdPage", firstChargeModel.idPage);
        return { ...state, errorLoadFirstCharge: false, firstChargeModel };
      } else {
        return { ...state, errorLoadFirstCharge: true };
      }
    }
    case RESPONSE_FIRST_CHARGE_SITE_RELOAD: {
      const { firstChargeModel } = action.payload;
      console.log("RESPONSE_FIRST_CHARGE_SITE_RELOAD", firstChargeModel)
      localStorage.setItem("ZUsaUGuestId", firstChargeModel.idGuestUser);
      localStorage.setItem("ZUsaUInfo", JSON.stringify(firstChargeModel));
      localStorage.setItem("ZUsaIdPage", firstChargeModel.idPage);
      return { ...state, errorLoadFirstCharge: false, firstChargeModel };
    }
    case RESPONSE_STATES_CITIES: {
      const responseStatesCities = action.payload.response;
      if (responseStatesCities.code === 100) {
        // const optionNoState = {
        //     idState: 0,
        //     state: "",
        //     name: "Select",
        //     cities: []
        // }
        const statesAndCities = responseStatesCities.states;
        //.splice(0, 0, optionNoState);
        return { ...state, errorLoadStatesAndCities: false, statesAndCities };
      } else {
        return { ...state, errorLoadStatesAndCities: true };
      }
    }
    case RESPONSE_LANDING_REGISTRATION_DATA: {
      const responseLandingRegistrationData = action.payload.response;
      if (responseLandingRegistrationData.code === 100) {
        const landingRegistrationData = responseLandingRegistrationData.configData;
        return { ...state, errorLoadlandingRegistrationData: false, landingRegistrationData: landingRegistrationData };
      } else {
        return { ...state, errorLoadlandingRegistrationData: true };
      }
    }
    default:
      return { ...state };
  }
}

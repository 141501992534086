import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  OutlinedInput,
} from "@mui/material";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import ValidationCodeCountdown from "./ValidationCodeCountdown";
import {
  areOnlyLetters,
  restrictToOnlyNumbersTwo,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
} from "../../../../Libraries/Utils/regex";
import {
  cleanEmailReducerValidation,
  requestEmailValidation,
} from "../../../ReduxSaga/Actions/Email";
import {
  cleanReducerCellPhoneDataValidation,
  cleanTwilioCellValidationReducer,
  requestTwilioCellPhoneCodeValidation,
  requestTwilioCellPhoneValidation,
} from "../../../ReduxSaga/Actions/Twilio";
import { requestAddressValidation } from "../../../ReduxSaga/Actions/Address";
import {
  requestCleanUserDataValidation,
  requestRegisterFromLandingReducer,
  requestNewRegistrationLandingData,
  requestUserDataValidation,
  requestCleanAddressReducer,
} from "../../../ReduxSaga/Actions/Client";
import ClientService from "../../../../Libraries/Client";
import { useSnackbar } from "notistack";

const RegisterForm = ({
  t,
  i18n,
  setOpenTermsConditons,
  setOpenValidationDataModal,
  setUserDataValidationErrors,
  setOpenSuccessRegister,
  isLanguageLoaded,
  statesResult,
  formData,
  setFormData,
}) => {
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.email = useSelector((state) => state.email);
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.client = useSelector((state) => state.client);
  appReducers.general = useSelector((state) => state.general);
  appReducers.home = useSelector((state) => state.home);
  appReducers.address = useSelector((state) => state.address);

  const { enqueueSnackbar } = useSnackbar();

  //// date
  const maxDate = moment().subtract(18, "years").add(1, "days");
  //// contador
  const [isStartCountDown, setIsStartCountDown] = useState(false);
  const [countDownSeconds, setCountDownSeconds] = useState(0);
  ///// listado de direcciones
  const [isOpenAddressValidationModal, setIsOpenAddressValidationModal] =
    useState(false);
  const [addressListSugestion, setAddressListSugestion] = useState([]);
  const [gender, setGender] = useState("F");

  //// hooks
  const handleChangeTerms = () => {
    setFormData({
      ...formData,
      isTermsAndConditionsAccepted: !formData.isTermsAndConditionsAccepted,
    });
  };

  const handleOpenTerms = () => {
    setOpenTermsConditons(true);
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      birthdate: {
        value: date,
        isValid: true,
      },
    });
  };

  const handleEmailChange = (e) => {
    setFormData({
      ...formData,
      email: {
        value: restrictToOnlyLettersForEmail(e).toLowerCase(),
        isValid: regexIsEmailAddressValid(e.target.value),
        isVerified: e.target.value.length === 0,
      },
    });
  };

  const handleEmailBlur = () => {
    setFormData({
      ...formData,
      email: {
        ...formData.email,
        isValid: regexIsEmailAddressValid(formData.email.value),
        isVerified: formData.email.value.length === 0,
      },
    });
    let isValid = regexIsEmailAddressValid(formData.email.value);
    if (isValid && formData.email.value.length !== 0) {
      dispatch(requestEmailValidation(formData.email.value, false));
    }
  };

  const handleCellPhoneChange = (e) => {
    let isValid = e.target.value.length === 10;
    let phone = restrictToOnlyNumbersTwo(e);
    setFormData({
      ...formData,
      cellPhone: {
        value: phone,
        isValid: isValid,
        isVerified: false,
        code: "",
      },
    });
    if (isValid) {
      dispatch(requestTwilioCellPhoneValidation(phone, true, 1));
    } else {
      setIsStartCountDown(false);
    }
  };

  const handleCellPhoneCodeChange = (e) => {
    setFormData({
      ...formData,
      cellPhone: {
        ...formData.cellPhone,
        code: restrictToOnlyNumbersTwo(e, 6),
      },
    });
  };

  const handleCellPhoneCodeBlur = () => {
    let isValid = formData.cellPhone.code.length === 6;
    if (isValid) {
      dispatch(
        requestTwilioCellPhoneCodeValidation(
          formData.cellPhone.value,
          formData.cellPhone.code
        )
      );
    }
  };

  const handleListAddressActived = () => {
    dispatch(
      requestAddressValidation(
        formData.streetShipping.value,
        formData.suiteShipping.value,
        formData.stateShipping.value,
        formData.cityShipping.value,
        formData.zipShipping.value,
        "e",
        false
      )
    );
  };

  const handlerSelectAddressFromSugestion = (
    address,
    suite,
    state,
    city,
    zip
  ) => {
    setIsOpenAddressValidationModal(false);
    setFormData({
      ...formData,
      isShipmentAddressVerified: true,
      streetShipping: {
        value: address,
        isValid: true,
      },
      suiteShipping: {
        value: suite,
        isValid: true,
      },
      stateShipping: {
        value: state,
        isValid: true,
      },
      cityShipping: {
        value: city,
        isValid: true,
      },
      zipShipping: {
        value: zip,
        isValid: true,
      },
    });
    dispatch(requestCleanAddressReducer());
  };

  const handleGetGenderByName = async (name) => {
    const result = await ClientService.GetGenderPosibilityByName(name);
    if (typeof result !== "undefined" && typeof result === "object") {
      if (typeof result.gender !== "undefined" && result.gender !== null)
        setGender(result.gender === "male" ? "M" : "F");
      else setGender("F");
    }
  };

  const handleValidateRegistration = () => {
    const formatedBirthDate = moment(formData.birthdate.value).format(
      "YYYY-MM-DD"
    );
    const model = {
      type: "VPD",
      channel: "ZU",
      cellPhone: formData.cellPhone.value,
      email: formData.email.value,
      name: formData.name.value,
      lastName: formData.lastname.value,
      motherLastName: "",
      birthdate: formatedBirthDate,
      street: "",
      suite: "",
      state: "",
      city: "",
      zipCode: "",
      userId: 1,
    };
    dispatch(requestUserDataValidation(model));
  };

  const handleSaveRegistration = () => {
    const formatedBirthDate = moment(formData.birthdate.value).format(
      "YYYY-MM-DD"
    );
    const model = {
      name: formData.name.value,
      lastname: formData.lastname.value,
      dateBorn: formatedBirthDate,
      email: formData.email.value,
      cellPhone: formData.cellPhone.value,
      gender: gender === "" ? "F" : gender,
      idLang: i18n.language === "es" ? 1 : 2,
      street: formData.streetShipping.value,
      suite: formData.suiteShipping.value,
      city: formData.cityShipping.value,
      state: formData.stateShipping.value,
      zip: formData.zipShipping.value,
      sponsor: formData.sponsor !== 0 ? formData.sponsor : 73780,
    };
    dispatch(requestNewRegistrationLandingData(model));
  };

  ///// useEffects
  useEffect(() => {
    if (
      typeof appReducers?.email?.errorEmailValidation !== "undefined" ||
      typeof appReducers?.email?.code !== "undefined"
    ) {
      if (!appReducers?.email?.errorEmailValidation) {
        setFormData({
          ...formData,
          email: {
            ...formData.email,
            isValid: true,
            isVerified: true,
          },
        });
      } else {
        setFormData({
          ...formData,
          email: {
            ...formData.email,
            isValid: false,
            isVerified: false,
          },
        });
        switch (appReducers?.email?.code) {
          case -100: //error de ejecucion
            enqueueSnackbar(
              t("__forms_email_validation_error_execution_message"),
              {
                variant: "error",
              }
            );
            break;
          case -200: //error email en bd
            enqueueSnackbar(
              t("__forms_email_validation_error_db_duplicity_message"),
              {
                variant: "error",
              }
            );
            break;
          case -900: //error zerobounce
            enqueueSnackbar(
              t("__forms_email_validation_error_zerobounce_message"),
              {
                variant: "error",
              }
            );
            break;
          default:
            enqueueSnackbar(
              t("__forms_email_validation_error_execution_message"),
              {
                variant: "error",
              }
            );
            break;
        }
      }
      dispatch(cleanEmailReducerValidation());
    }
  }, [appReducers?.email?.errorEmailValidation, appReducers?.email?.code]);

  //Effect on Twilio CellPhone Validation
  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellValidation !== "undefined" ||
      typeof appReducers?.twilio?.code !== "undefined"
    ) {
      if (!appReducers?.twilio?.errorTwilioCellValidation) {
        if (appReducers?.twilio?.code === 100) {
          setFormData({
            ...formData,
            cellPhone: {
              ...formData.cellPhone,
              isValid: true,
              isVerified: false,
            },
          });
          setCountDownSeconds(60 * 5);
          setIsStartCountDown(true);
        }
      } else {
        setFormData({
          ...formData,
          cellPhone: {
            ...formData.cellPhone,
            isValid: false,
            isVerified: false,
          },
        });
        setIsStartCountDown(false);
        switch (appReducers?.twilio?.code) {
          case -100: //error de ejecucion
            enqueueSnackbar(t("__forms_twilio_cell_validation_gen_error"), {
              variant: "error",
            });
            break;
          case -200: //error celular en bd
            enqueueSnackbar(t("__forms_twilio_cell_validation_db_error"), {
              variant: "error",
            });
            break;
          case -300: //error celular no es un numero valido
            enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
              variant: "error",
            });
            break;
          default:
            enqueueSnackbar(t("__forms_twilio_cell_validation_type_error"), {
              variant: "error",
            });
            break;
        }
      }
      dispatch(cleanTwilioCellValidationReducer());
    }
  }, [
    appReducers?.twilio?.errorTwilioCellValidation,
    appReducers?.twilio?.code,
  ]);

  useEffect(() => {
    if (countDownSeconds <= 0 && isStartCountDown) {
      enqueueSnackbar(
        t("__general__cellPhone__verification__code__timer__end"),
        {
          variant: "error",
        }
      );
    }
  }, [countDownSeconds]);

  useEffect(() => {
    if (
      typeof appReducers?.twilio?.errorTwilioCellCodeValidation !== "undefined"
    ) {
      if (appReducers?.twilio?.errorTwilioCellCodeValidation) {
        enqueueSnackbar(t("__forms_twilio_cell_val_code_error_message"), {
          variant: "error",
        });
        setFormData({
          ...formData,
          cellPhone: {
            ...formData.cellPhone,
            isValid: false,
            isVerified: false,
            code: "",
          },
        });
      } else {
        setFormData({
          ...formData,
          cellPhone: {
            ...formData.cellPhone,
            isValid: true,
            isVerified: true,
            code: "",
          },
        });
        enqueueSnackbar(t("__login_New_Account_Phone_Verify_label"), {
          variant: "success",
        });
        setIsStartCountDown(false);
      }
      dispatch(cleanReducerCellPhoneDataValidation());
    }
  }, [appReducers?.twilio?.errorTwilioCellCodeValidation]);

  useEffect(() => {
    if (
      typeof appReducers?.address?.errorClientAddressValidation !== "undefined"
    ) {
      if (appReducers?.address?.errorClientAddressValidation) {
        if (
          appReducers?.address?.addressSugestion &&
          appReducers?.address?.addressSugestion.length > 0
        ) {
          setAddressListSugestion(appReducers?.address?.addressSugestion);
          setIsOpenAddressValidationModal(true);
          setFormData({
            ...formData,
            isShipmentAddressVerified: false,
          });
        } else {
          enqueueSnackbar(
            t("__forms_address_verification_error_noData_message"),
            {
              variant: "error",
            }
          );
          setAddressListSugestion([]);
          setIsOpenAddressValidationModal(false);
          setFormData({
            ...formData,
            isShipmentAddressVerified: false,
          });
          dispatch(requestCleanAddressReducer());
        }
      } else {
        enqueueSnackbar(t("__forms_address_verification_success_message"), {
          variant: "success",
        });
        setAddressListSugestion([]);
        setIsOpenAddressValidationModal(false);
        setFormData({
          ...formData,
          isShipmentAddressVerified: true,
        });
        dispatch(requestCleanAddressReducer());
      }
    }
  }, [
    appReducers?.address.errorClientAddressValidation,
    appReducers?.address.addressSugestion,
  ]);

  useEffect(() => {
    if (
      typeof appReducers?.client?.userDataValidationResponse !== "undefined"
    ) {
      if (appReducers.client.userDataValidationResponse.code === 100) {
        if (
          appReducers.client.userDataValidationResponse.validationList.length <=
          0
        ) {
          setOpenValidationDataModal(false);
          setUserDataValidationErrors([]);
          handleSaveRegistration();
        } else {
          setOpenValidationDataModal(true);
          setUserDataValidationErrors(
            appReducers.client.userDataValidationResponse.validationList
          );
        }
      } else {
        setOpenValidationDataModal(false);
        setUserDataValidationErrors([]);
        enqueueSnackbar(
          t("Error al validar los datos proporcionados, intenta de nuevo."),
          { variant: "error" }
        );
      }
      dispatch(requestCleanUserDataValidation());
    }
  }, [appReducers?.client?.userDataValidationResponse]);

  useEffect(() => {
    if (
      typeof appReducers?.client?.newRegistrationLandingData !== "undefined"
    ) {
      const response =
        appReducers?.client?.newRegistrationLandingData?.response;
      if (response.code === 100) {
        setOpenSuccessRegister(true);
      } else {
        enqueueSnackbar(response?.message, { variant: "error" });
      }
      dispatch(requestRegisterFromLandingReducer());
    } else {
      if (
        typeof appReducers?.client?.errorRegistrationLandingDataResponse !==
        "undefined"
      ) {
        if (
          appReducers?.client?.errorRegistrationLandingDataResponse === true
        ) {
          enqueueSnackbar(
            appReducers?.client?.newRegistrationLandingData?.response?.message,
            { variant: "error" }
          );
        }
      }
    }
  }, [appReducers?.client?.newRegistrationLandingData]);

  return (
    <div className="register__form">
      <div
        className={`register__form__container ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div className="register__form__container__card">
          {isLanguageLoaded ? (
            <span className="title">{t("__login_Create_Account_Title")}</span>
          ) : (
            <Skeleton
              variant="rectangular"
              width={350}
              height={30}
              style={{ marginBottom: 5, marginTop: 5 }}
            />
          )}

          {/* comienza el registro del usurio */}
          {isLanguageLoaded ? (
            <div className="register__user">
              {/*  nombre y apellido */}
              <div
                className={`register__user__row ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <FormControl variant="outlined" className="column__item">
                  <InputLabel htmlFor="outlined-username">
                    {t("__login_Create_Account_Txt_Name_Label")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-username"
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                          className={
                            formData.name.isValid ? "checkGreenIcon" : ""
                          }
                        />
                      </InputAdornment>
                    }
                    label={t("__login_Create_Account_Txt_Name_Label")}
                    value={formData.name.value}
                    onChange={(e) => {
                      if (e.target.value === " ") {
                        setFormData({
                          ...formData,
                          name: {
                            value: "",
                            isValid: false,
                          },
                        });
                      } else if (
                        areOnlyLetters(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setFormData({
                          ...formData,
                          name: {
                            value: e.target.value
                              .replace(/\s\s+/g, " ")
                              .toUpperCase(),
                            isValid: e.target.value.length > 0,
                          },
                        });
                      }
                    }}
                    onBlur={() => {
                      handleGetGenderByName(
                        formData.name.value.trim().split(" ")[0]
                      );
                    }}
                  />
                  {formData.name.value.length === 0 && (
                    <FormHelperText id="name">
                      {t("__forms_empty_field_error_message")}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl variant="outlined" className="column__item">
                  <InputLabel htmlFor="outlined-lastname">
                    {t("__login_Create_Account_Txt_LastName_Label")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-lastname"
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                          className={
                            formData.lastname.isValid ? "checkGreenIcon" : ""
                          }
                        />
                      </InputAdornment>
                    }
                    label={t("__login_Create_Account_Txt_LastName_Label")}
                    value={formData.lastname.value}
                    onChange={(e) => {
                      if (e.target.value === " ") {
                        setFormData({
                          ...formData,
                          lastname: {
                            value: "",
                            isValid: false,
                          },
                        });
                      } else if (
                        areOnlyLetters(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setFormData({
                          ...formData,
                          lastname: {
                            value: e.target.value
                              .replace(/\s\s+/g, " ")
                              .toUpperCase(),
                            isValid: e.target.value.length > 0,
                          },
                        });
                      }
                    }}
                  />
                  {formData.lastname.value.length === 0 && (
                    <FormHelperText id="lastname">
                      {t("__forms_empty_field_error_message")}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              {/*  cumple y email */}
              <div
                className={`register__user__row ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    closeOnSelect
                    className="column__item"
                    label={t(
                      "__form__new__members__title__info__personal__birthday"
                    )}
                    openTo="year"
                    maxDate={maxDate}
                    value={formData.birthdate.value}
                    onChange={(date) => handleDateChange(date)}
                    format="YYYY-MM-DD"
                    size="small"
                    slotProps={{
                      textField: {
                        //size: "small",
                        helperText: !formData.birthdate.isValid
                          ? t("__forms_empty_field_error_message")
                          : "",
                      },
                    }}
                  />
                </LocalizationProvider>

                <FormControl variant="outlined" className="column__item">
                  <InputLabel htmlFor="outlined-email">
                    {t("__login_Create_Account_Txt_Email_Label")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-email"
                    aria-describedby="email"
                    inputProps={{
                      "aria-label": "email",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                          className={
                            formData.email.value.length !== 0 &&
                            formData.email.isValid &&
                            formData.email.isVerified
                              ? "checkGreenIcon"
                              : ""
                          }
                        />
                      </InputAdornment>
                    }
                    label={t("__login_Create_Account_Txt_Email_Label")}
                    value={formData.email.value}
                    onChange={(e) => handleEmailChange(e)}
                    onBlur={handleEmailBlur}
                  />
                </FormControl>
              </div>
              {/*  telefono y verificacion */}
              <div
                className={`register__user__row ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <FormControl variant="outlined" className="column__item">
                  <div className="code__phone">
                    <div className="code__phone__item">
                      <InputLabel htmlFor="outlined-cellphone">
                        {t("__new_members_form_mobile_label")}
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-cellphone"
                        endAdornment={
                          <InputAdornment position="end">
                            <div
                              className={
                                formData.cellPhone.value.length !== 0 &&
                                formData.cellPhone.isValid &&
                                formData.cellPhone.isVerified
                                  ? "checkGreenIcon"
                                  : ""
                              }
                            />
                          </InputAdornment>
                        }
                        label={t("__new_members_form_mobile_label")}
                        maxLength={10}
                        onChange={(e) => handleCellPhoneChange(e)}
                        value={formData.cellPhone.value}
                      />
                      {formData.cellPhone.value.length === 0 && (
                        <FormHelperText id="phone">
                          {t("__forms_empty_field_error_message")}
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                </FormControl>

                <FormControl variant="outlined" className="column__item">
                  {isStartCountDown && (
                    <div className="code__phone">
                      <div className="code__phone__item">
                        <InputLabel htmlFor="outlined-cellphone-code">
                          {t("__steps_form_enterprising_code_text")}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-cellphone-code"
                          endAdornment={
                            <InputAdornment position="end">
                              <div
                                className={
                                  formData.cellPhone.code.length !== 0
                                    ? "checkGreenIcon"
                                    : ""
                                }
                              />
                            </InputAdornment>
                          }
                          label={t("__new_members_form_mobile_label")}
                          maxLength={6}
                          onChange={(e) => handleCellPhoneCodeChange(e)}
                          onBlur={handleCellPhoneCodeBlur}
                          value={formData.cellPhone.code}
                        />
                        {formData.cellPhone.code.length === 0 && (
                          <FormHelperText id="phone">
                            {t("__forms_empty_field_error_message")}
                          </FormHelperText>
                        )}
                      </div>
                      <div className="timer">
                        {" "}
                        <ValidationCodeCountdown
                          t={t}
                          isStartCountDown={isStartCountDown}
                          setIsStartCountDown={setIsStartCountDown}
                          countDownSeconds={countDownSeconds}
                          setCountDownSeconds={setCountDownSeconds}
                        />
                      </div>
                    </div>
                  )}
                </FormControl>
              </div>
            </div>
          ) : (
            <div className="register__user">
              {[0, 1, 2].map((item) => {
                return (
                  <div
                    key={item}
                    className={`register__user__row ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    {[0, 1].map((rowItem) => {
                      return (
                        <Skeleton
                          key={rowItem}
                          variant="rectangular"
                          width={isDesktop ? 300 : isTablet ? 600 : 320}
                          height={50}
                          style={{ margin: 10 }}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}

          {/* termina el registro del usuario */}

          {/* comienza direcciones */}
          <div className="register__address">
            {isLanguageLoaded ? (
              <span className="title">
                {t("__checkout_order_steps_pay_register_shipping_address")}
              </span>
            ) : (
              <Skeleton
                variant="rectangular"
                width={350}
                height={30}
                style={{ marginBottom: 5, marginTop: 5 }}
              />
            )}

            {isLanguageLoaded ? (
              <div className="register__address__container">
                {isOpenAddressValidationModal && (
                  <div
                    className={`suggestion__list ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div className="suggestion__list__header">
                      <span>{t("__modal_validation_address_title")}</span>
                      <IconButton
                        onClick={() => setIsOpenAddressValidationModal(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className="list__address__wrapper">
                      <List
                        sx={{
                          bgcolor: "background.paper",
                        }}
                      >
                        {addressListSugestion &&
                          addressListSugestion.map((item, index) => (
                            <ListItem
                              key={item}
                              onClick={() =>
                                handlerSelectAddressFromSugestion(
                                  item.address1,
                                  item.suite,
                                  item.state,
                                  item.city,
                                  item.zip
                                )
                              }
                            >
                              <ListItemIcon>
                                <ListItemIcon>
                                  <div className="locationRedIcon" />
                                </ListItemIcon>
                              </ListItemIcon>
                              <ListItemText
                                primary={`${item.address1} ${item.suite}`}
                                secondary={`${item.city}, ${item.state} ${item.zip}`}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </div>
                  </div>
                )}

                <div className="register__address__container__form">
                  <div className="address__data">
                    <div
                      className={`address__column ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="column__item"
                      >
                        <InputLabel htmlFor="outlined-street">
                          {t(
                            "__checkout_order_steps_pay_register_shipping_street"
                          )}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-street"
                          endAdornment={
                            <InputAdornment position="end">
                              <div
                                className={
                                  formData.streetShipping.isValid &&
                                  formData.isShipmentAddressVerified
                                    ? "checkGreenIcon"
                                    : ""
                                }
                              />
                            </InputAdornment>
                          }
                          label={t(
                            "__checkout_order_steps_pay_register_shipping_street"
                          )}
                          value={formData.streetShipping.value}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              streetShipping: {
                                value: e.target.value.toUpperCase(),
                                isValid: e.target.value.length > 0,
                              },
                              isShipmentAddressVerified: false,
                            });
                          }}
                        />
                        {formData.streetShipping.value.length === 0 && (
                          <FormHelperText id="street">
                            {t("__forms_empty_field_error_message")}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div
                      className={`address__column ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <FormControl variant="outlined" className="column__item">
                        <InputLabel htmlFor="outlined-suite">
                          {t(
                            "__checkout_order_steps_pay_register_shipping_suite"
                          )}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-suite"
                          label={t(
                            "__checkout_order_steps_pay_register_shipping_suite"
                          )}
                          value={formData.suiteShipping.value}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              suiteShipping: {
                                value: e.target.value.toUpperCase(),
                                isValid: true,
                              },
                              isShipmentAddressVerified: false,
                            });
                          }}
                        />
                      </FormControl>

                      <FormControl variant="outlined" className="column__item">
                        <InputLabel id="demo-simple-select-label">
                          {" "}
                          {t(
                            "__checkout_order_steps_pay_register_shipping_state"
                          )}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          required
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          value={formData.stateShipping.value}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              stateShipping: {
                                value: e.target.value,
                                isValid: e.target.value.length > 0,
                              },
                              isShipmentAddressVerified: false,
                            });
                          }}
                        >
                          <MenuItem selected disabled value="">
                            <em>
                              {t(
                                "__checkout_order_steps_pay_register_shipping_state"
                              )}
                            </em>
                          </MenuItem>
                          {statesResult.map((state, index) => (
                            <MenuItem key={index} value={state.state}>
                              {state.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formData.stateShipping.value.length === 0 && (
                          <FormHelperText id="state">
                            {t("__forms_empty_field_error_message")}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div
                      className={`address__column ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <FormControl variant="outlined" className="column__item">
                        <InputLabel htmlFor="outlined-city">
                          {t(
                            "__checkout_order_steps_pay_register_shipping_city"
                          )}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-city"
                          endAdornment={
                            <InputAdornment position="end">
                              <div
                                className={
                                  formData.cityShipping.isValid &&
                                  formData.isShipmentAddressVerified
                                    ? "checkGreenIcon"
                                    : ""
                                }
                              />
                            </InputAdornment>
                          }
                          label={t(
                            "__checkout_order_steps_pay_register_shipping_city"
                          )}
                          value={formData.cityShipping.value}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              cityShipping: {
                                value: e.target.value.toUpperCase(),
                                isValid: e.target.value.length > 0,
                              },
                              isShipmentAddressVerified: false,
                            });
                          }}
                        />
                        {formData.cityShipping.value.length === 0 && (
                          <FormHelperText id="city">
                            {t("__forms_empty_field_error_message")}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <FormControl variant="outlined" className="column__item">
                        <InputLabel htmlFor="outlined-cp">
                          {t("__checkout_order_steps_pay_register_shipping_CP")}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-cp"
                          endAdornment={
                            <InputAdornment position="end">
                              <div
                                className={
                                  formData.zipShipping.isValid &&
                                  formData.isShipmentAddressVerified
                                    ? "checkGreenIcon"
                                    : ""
                                }
                              />
                            </InputAdornment>
                          }
                          label={t(
                            "__checkout_order_steps_pay_register_shipping_CP"
                          )}
                          value={formData.zipShipping.value}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              zipShipping: {
                                value: restrictToOnlyNumbersTwo(e, 5),
                                isValid: e.target.value.length == 5,
                              },
                              isShipmentAddressVerified: false,
                            });
                          }}
                        />
                        {!formData.zipShipping.isValid && (
                          <FormHelperText id="cp">
                            {t("__forms_empty_field_error_message")}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>

                {!formData.isShipmentAddressVerified && (
                  <div>
                    <div
                      className={`verify__address ${
                        !formData.streetShipping.isValid ||
                        !formData.suiteShipping.isValid ||
                        !formData.stateShipping.isValid ||
                        !formData.cityShipping.isValid ||
                        !formData.zipShipping.isValid
                          ? "disabled"
                          : "active"
                      } `}
                    >
                      <span
                        onClick={() => {
                          !formData.streetShipping.isValid ||
                          !formData.suiteShipping.isValid ||
                          !formData.stateShipping.isValid ||
                          !formData.cityShipping.isValid ||
                          !formData.zipShipping.isValid
                            ? console.log("")
                            : handleListAddressActived();
                        }}
                      >
                        {t(
                          "__checkout_order_steps_pay_register_shipping_Address"
                        )}{" "}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="register__user">
                {[0, 1, 2].map((item) => {
                  return (
                    <div
                      key={item}
                      className={`register__user__row ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      {[0, 1].map((rowItem) => {
                        return (
                          <Skeleton
                            key={rowItem}
                            variant="rectangular"
                            width={isDesktop ? 300 : isTablet ? 600 : 320}
                            height={50}
                            style={{ margin: 10 }}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="terms__conditions">
            {isLanguageLoaded ? (
              <div>
                <Checkbox
                  onClick={handleChangeTerms}
                  value={formData.isTermsAndConditionsAccepted}
                />
                {t("btn_text_accept")}
                <span className="terms__underline" onClick={handleOpenTerms}>
                  {t("__termsAndConditions_title_Txt")}
                </span>
              </div>
            ) : (
              <Skeleton
                variant="rectangular"
                width={isDesktop ? 300 : isTablet ? 250 : 320}
                height={50}
                style={{ margin: 10 }}
              />
            )}
          </div>
          <div className="finish__registration">
            <Button
              style={{ minWidth: 200, maxWidth: 200 }}
              className="button__with__icon bg__dark__pink no__left__margin"
              disabled={
                !formData.name.isValid ||
                !formData.lastname.isValid ||
                !formData.birthdate.isValid ||
                !formData.email.isValid ||
                !formData.email.isVerified ||
                !formData.cellPhone.isValid ||
                !formData.cellPhone.isVerified ||
                !formData.isShipmentAddressVerified ||
                !formData.isTermsAndConditionsAccepted
              }
              onClick={handleValidateRegistration}
            >
              <div className="button__with__icon__svg__wrapper dark__pink__ball">
                <div
                  className={
                    !formData.name.isValid ||
                    !formData.lastname.isValid ||
                    !formData.birthdate.isValid ||
                    !formData.email.isValid ||
                    !formData.email.isVerified ||
                    !formData.cellPhone.isValid ||
                    !formData.cellPhone.isVerified ||
                    !formData.isShipmentAddressVerified ||
                    !formData.isTermsAndConditionsAccepted
                      ? "userGrayLoginAltSmallIcon"
                      : "userOutlineWhiteIcon"
                  }
                />
              </div>
              <span>
                {isLanguageLoaded ? (
                  t("btn_finish_register")
                ) : (
                  <Skeleton
                    variant="rectangular"
                    width={isDesktop ? 70 : isTablet ? 50 : 30}
                    height={15}
                    style={{ margin: 10 }}
                  />
                )}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;

import { call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_FIRST_CHARGE_SITE,
  RESPONSE_FIRST_CHARGE_SITE,
  REQUEST_STATES_CITIES,
  RESPONSE_STATES_CITIES,
  DATA_FETCH_FAILED,
  RESPONSE_FIRST_CHARGE_SITE_RELOAD,
  REQUEST_FIRST_CHARGE_SITE_RELOAD,
  REQUEST_LANDING_REGISTRATION_DATA,
  RESPONSE_LANDING_REGISTRATION_DATA
} from "../../Constants";
import General from "../../../../Libraries/General";

function* requestFirstChargeSite(data) {
  try {
    const result = yield call(General.GetFirstChargeSite, data.payload.model);
    yield put({
      type: RESPONSE_FIRST_CHARGE_SITE,
      payload: { response: result.userFirstCharge },
    });
  } catch (error) {
    console.error("load first Charge site error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}
function* requestStatesAndCities() {
  try {
    const result = yield call(General.GetStatesAndCities);
    yield put({
      type: RESPONSE_STATES_CITIES,
      payload: { response: result.getStatesAndCities },
    });
  } catch (error) {
    console.error("GET STATES AND CITIES FETCH ERROR", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestFirstChargeReload(data) {
  try {
    const result = data.payload.model;
    yield put({
      type: RESPONSE_FIRST_CHARGE_SITE_RELOAD,
      payload: {
        firstChargeModel: result,
      },
    });
  } catch (error) {
    console.error("requestFirstChargeReload error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

function* requestLandingRegistrationData() {
  try {
    const result = yield call(General.GetLandingRegistrationData);
    yield put({
      type: RESPONSE_LANDING_REGISTRATION_DATA,
      payload: { response: result.getLandingRegisterConfig },
    });
  } catch (error) {
    console.error("requestLandingRegistrationData error", error);
    yield put({ type: DATA_FETCH_FAILED, message: error.statusText });
  }
}

//Watchers
export function* watchRequestFirstChargeSite() {
  yield takeLatest(REQUEST_FIRST_CHARGE_SITE, requestFirstChargeSite);
}
export function* watchRequestStatesAndCities() {
  yield takeLatest(REQUEST_STATES_CITIES, requestStatesAndCities);
}
export function* watchRequestFirstChargeReload() {
  yield takeLatest(REQUEST_FIRST_CHARGE_SITE_RELOAD, requestFirstChargeReload);
}
export function* watchRequestLandingRegistrationData() {
  yield takeLatest(REQUEST_LANDING_REGISTRATION_DATA, requestLandingRegistrationData);
}

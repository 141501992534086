import React, { Fragment } from "react";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Skeleton } from "@mui/material";
//// assets

const InfomationEnterprising = ({ isLanguageLoaded, t, i18n, landingData, sponsorData }) => {
  return (
    <div
      className={`information__enterprising__container ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      <div
        className={`information__wrapper ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`information__column__one ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          } ${isLanguageLoaded ? "" : "isSkeletonActive"}`}
        >
          <div className="title__section">
            <div
              className={`title ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {
                isLanguageLoaded && i18n?.language == 'es' ? (
                  <Fragment>
                    <div className={`${landingData?.emojiTitleClassStart}`} />
                    {" "}{landingData?.titleEs}{" "}
                    <div className={`${landingData?.emojiTitleClassEnd}`} />
                  </Fragment>
                ) : 
                isLanguageLoaded ? (
                  <Fragment>
                    <div className={`${landingData?.emojiTitleClassStart}`} />
                    {" "}{landingData?.titleEn}{" "}
                    <div className={`${landingData?.emojiTitleClassEnd}`} />
                  </Fragment>
                ) :
                (
                  <Skeleton
                    variant="rectangular"
                    width={isTablet ? 400 : isMobile ? 300 : 568}
                    height={70}
                    style={{ marginBottom: 10 }}
                  />
                )
              }
            </div>
            <div
              className={`subtitle ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              {
                isLanguageLoaded && i18n?.language == 'es' ? (
                <Fragment>
                  <div className={`${landingData?.emojiSubtitleClassStart}`} />
                  {" "}{landingData?.subtitleEs}{" "}
                  <div className={`${landingData?.emojiSubtitleClassEnd}`} />
                </Fragment>
                ) : 
                isLanguageLoaded ? (
                  <Fragment>
                    <div className={`${landingData?.emojiSubtitleClassStart}`} />
                    {" "}{landingData?.subtitleEn}{" "}
                    <div className={`${landingData?.emojiSubtitleClassEnd}`} />
                  </Fragment>
                ) :
                (
                <Skeleton
                  variant="rectangular"
                  width={isTablet ? 400 : isMobile ? 300 : 568}
                  height={50}
                  style={{ marginBottom: 10 }}
                />
                )
              }
            </div>
          </div>
          <div className="list__benefits">
            {isLanguageLoaded
              ? landingData?.benefits?.map((item) => {
                  return (
                    <div className="benefit" key={item.id}>
                      {item.emojiClassStart !== "" ? (
                        <div className={`${item.emojiClassStart}`} />
                      ) : (
                        ""
                      )}
                      {i18n?.language === "es"
                        ? item.benefitEs
                        : item.benefitEn}
                      {item.emojiClassEnd !== "" ? (
                        <div className={`${item.emojiClassEnd}`} />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : [0, 1, 2, 3, 4].map((item) => {
                  return (
                    <Skeleton
                      key={item}
                      variant="rectangular"
                      width={isTablet ? 200 : isMobile ? 250 : 500}
                      height={30}
                      style={{ marginBottom: 5 }}
                    />
                  );
                })}
          </div>
          <div
            className={`message ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {
                isLanguageLoaded && i18n?.language == 'es' ? (
                <Fragment>
                  <div className={`${landingData?.emojiFooterClassStart}`} />
                  {" "}{landingData?.footerEs}{" "}
                  <div className={`${landingData?.emojiFooterClassEnd}`} />
                </Fragment>
                ) : 
                isLanguageLoaded ? (
                  <Fragment>
                    <div className={`${landingData?.emojiFooterClassStart}`} />
                  {" "}{landingData?.footerEn}{" "}
                  <div className={`${landingData?.emojiFooterClassEnd}`} />
                  </Fragment>
                ) :
                (
                <Skeleton
                  variant="rectangular"
                  width={isTablet ? 400 : isMobile ? 250 : 568}
                  height={30}
                  style={{ marginBottom: 10 }}
                />
                )
              }
          </div>
        </div>
        <div className="information__column__two">
          <div
            className={`landing__image__registration ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {
              isLanguageLoaded && i18n?.language == 'es' ? (
                <Fragment>
                  <img alt="imgRegistration" src={landingData?.imagenEs} /> 
                </Fragment>
              ) :
              isLanguageLoaded ? (
                <Fragment>
                  <img alt="imgRegistration" src={landingData?.imagenEn} />
                </Fragment>
              ) : (
                <Fragment>
                  {
                    <Skeleton
                      variant="rectangular"
                      width={isTablet ? 300 : isMobile ? 250 : 580}
                      height={isTablet ? 300 : isMobile ? 250 : 520}
                      style={
                        isTablet
                          ? {
                              margin: 10,
                              marginLeft: 20,
                              marginRight: 20,
                            }
                          : isMobile
                          ? {
                              margin: 10,
                              marginLeft: 20,
                              marginRight: 20,
                            }
                          : ""
                      }
                    />
                  }
                </Fragment>
              )
            }
          </div>
        </div>
      </div>

      {sponsorData?.idClient ? (
      <div className="info__sponsor">
        <div className="info__sponsor__wrapper">
          <div className="sponsor__info">
            <div>
              {isLanguageLoaded ? (
                <Fragment>
                  <span className="info__title">
                    {t("__form__new__members__name__sponsor")}
                  </span>
                  <span className="info__subtitle">{sponsorData?.nameClient}</span>
                </Fragment>
              ) : (
                [0, 1].map((item) => {
                  return (
                    <Skeleton
                      key={item}
                      variant="rectangular"
                      width={isDesktop ? 150 : isTablet ? 100 : 70}
                      height={15}
                      style={{ marginBottom: 5 }}
                    />
                  );
                })
              )}
            </div>
            <div>
              {isLanguageLoaded ? (
                <Fragment>
                  <span className="info__title">
                    {t("__steps_form_enterprising_phone_text")}:
                  </span>
                  <span className="info__subtitle">{sponsorData?.cellPhone}</span>
                </Fragment>
              ) : (
                [0, 1].map((item) => {
                  return (
                    <Skeleton
                      key={item}
                      variant="rectangular"
                      width={isDesktop ? 100 : isTablet ? 70 : 50}
                      height={15}
                      style={{ marginBottom: 5 }}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      ) : (<span></span>)}

    </div>
  );
};

export default InfomationEnterprising;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link, Skeleton } from "@mui/material";
import TextFieldComponent from "../../../base/components/TextFieldComponent";
import ButtonComponent from "../../../base/components/ButtonComponent";
//UTILS
import {
  requestClientLogin,
  requestCleanClientLoginReducer,
  requestClientInformation,
} from "./../../../../ReduxSaga/Actions/Client";
import { requestLoggUserActivity } from "./../../../../ReduxSaga/Actions/General";
import { useTranslation } from "react-i18next";

const NewLoginContainer = ({
  // t,
  // i18n,
  //history,
  handleGAEvent,
  // withOrder,
  // benefitType,
  // setBenefitType,
  // clientInfoToCloseOrder,
  // setClientInfoToCloseOrder,
  enqueueSnackbar,
  isLanguageLoaded,
  isFromCheckout,
  setIsOpenLoginModal,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  //Translation
  const { t, i18n } = useTranslation();

  const history = useHistory();

  /// REDUX
  const appReducers = {};
  appReducers.client = useSelector((state) => state.client);
  appReducers.general = useSelector((state) => state.general);

  ///HOOKS
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  ///EFFECST
  useEffect(() => {
    document.title = "Zermat USA :: Login";
  }, []);

  useEffect(() => {
    if (
      appReducers?.client?.hasOwnProperty("errorClientLogin") ||
      appReducers?.client?.hasOwnProperty("errorMultinivelLogin")
    )
      if (
        !appReducers?.client?.errorClientLogin &&
        !appReducers?.client?.errorMultinivelLogin
      ) {
        const language =
          appReducers?.client?.clientLogin?.idLang === 1 ? "es" : "en";
        i18n.changeLanguage(language);
        //debugger;
        dispatch(requestClientInformation());

        dispatch(
          requestLoggUserActivity(2, 0, 0, "", "Inicio de sesion: " + email)
        );
        handleGAEvent("User", "Usuario Inicia Session");
        // if (withOrder) {
        //   if (benefitType === 1) history.replace("/precheckout");
        //   else history.replace("/checkout");
        // } else history.replace("/home");

        console.log("CONDICIONAR EL REDIRECT", isFromCheckout);

        if (isFromCheckout) {
          //Close login modal
          setIsOpenLoginModal(false);
        } else {
          history.replace("/home");
        }
      } else if (appReducers?.client?.errorMultinivelLogin) {
        enqueueSnackbar(t("__login_sorry_invalid_sign_in_Login_Message"), {
          variant: "warning",
        });
        dispatch(requestCleanClientLoginReducer());
      } else {
        enqueueSnackbar(t("__login_Error_Login_Message"), {
          variant: "error",
        });
        dispatch(requestCleanClientLoginReducer());
      }
  }, [
    appReducers?.client?.errorClientLogin,
    appReducers?.client?.clientLogin,
    appReducers?.client?.errorMultinivelLogin,
  ]);

  // ///Effect login user
  // useEffect(() => {
  //   if (
  //     typeof appReducers?.client?.errorLoadClientLogedInformation !==
  //       "undefined" &&
  //     typeof appReducers?.general?.errorLoadFirstCharge !== "undefined"
  //   ) {
  //     debugger
  //     if (!appReducers.general.errorLoadFirstCharge) {
  //       if (!appReducers.client.errorLoadClientLogedInformation) {
  //         debugger

  //         // if (withOrder) {
  //         //   if (benefitType === 1) history.replace("/precheckout");
  //         //   else history.replace("/checkout");
  //         // } else history.replace("/home");
  //       }
  //     }
  //   }
  // }, [
  //   appReducers?.general.errorLoadFirstCharge,
  //   appReducers?.general.firstChargeModel,
  //   appReducers?.client.errorLoadClientLogedInformation,
  //   appReducers?.client.clientLogedinInformation,
  //   i18n?.language,
  // ]);

  const handleGoRecovery = () => {
    history.replace("/recovery");
  };

  return (
    <div
      className={`form__new__login ${isFromCheckout ? "login_in_checkou" : ""}`}
    >
      {/* {console.log("lang", location)} */}
      <div className="form__new__login__header">
        <div>
          <div className="userRedIcon" />
        </div>

        <div
          className={`${
            isLanguageLoaded ? "skeletonActive" : "skeletonActive"
          }`}
        >
          <div className="form__new__login__header__title">
            {isLanguageLoaded ? (
              t("__ZUsa_NavBar_OptionCart_LogIn")
            ) : (
              <Skeleton variant="rect" width={"90%"} height={30} />
            )}
            {}
          </div>
          {!isFromCheckout && (
            <div className="form__new__login__header__subtitle">
              {isLanguageLoaded ? (
                t("_log_in_subtitle")
              ) : (
                <Skeleton variant="rect" width={"70%"} height={30} />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="form__new__login__content">
        <div style={{ marginBottom: 16 }}>
          {" "}
          <TextFieldComponent
            labelName={isLanguageLoaded ? t("__login_Txt_Email_Label") : ""} //__login_Create_Account_Txt_Email_Label
            typeInput="email"
            valueInput={email}
            erroHelp={
              // email.length === 0
              //   ? t("__forms_empty_field_error_message")
              //   : !regexIsEmailAddressValid(email)
              //   ? t("__forms_field_email_validation_js_error_message")
              //   : ""
              email.length === 0 && isLanguageLoaded
                ? t("__forms_empty_field_error_message")
                : ""
            }
            placeholderInput={""} //t("__home_subscribe_placeholder_footer")
            isActiveHelp={
              email.length === 0 //|| !regexIsEmailAddressValid(email)
            }
            textareaEnabled={false}
            setValue={(e) => setEmail(e.target.value)} //restrictToOnlyLettersForEmail(e)
          />
        </div>
        <div style={{ marginBottom: 16 }}>
          <TextFieldComponent
            labelName={
              isLanguageLoaded ? t("__ZUsa_NavBar_OptionCart_Password") : ""
            }
            typeInput="password"
            valueInput={password}
            erroHelp={
              isLanguageLoaded ? t("__forms_empty_field_error_message") : ""
            }
            isActiveHelp={password.length > 0 ? false : true}
            textareaEnabled={false}
            setValue={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        {!isFromCheckout && (
          <div className="form__new__login__forgot__pass">
            {isLanguageLoaded ? (
              <Link onClick={handleGoRecovery}>
                {t("__login_Btn_Fogot_Pass_Label")}
              </Link>
            ) : (
              <Skeleton variant="rect" width={"100%"} height={30} />
            )}
          </div>
        )}
      </div>
      <div className="form__new__login__actions">
        <div>
          <ButtonComponent
            countButton={false}
            disabledPrimary={password.length <= 0 || email.length <= 0}
            handleActionPrimary={() => {
              dispatch(requestClientLogin(email, password));
            }}
            handleActionSecondary={() => {}}
            valueCount=""
            iconClass={
              password.length <= 0 || email.length <= 0
                ? "userGrayLoginAltSmallIcon"
                : "userOutlineWhiteIcon"
            }
            textButton={isLanguageLoaded ? t("btn_log_in") : ""}
            leftAling={true}
          />
        </div>
      </div>
    </div>
  );
};

export default NewLoginContainer;

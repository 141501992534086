import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Button, Link, Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import TextFieldComponent from "../../base/components/TextFieldComponent";
import Timer from "../../base/components/Timer";
///ACTIONS
import {
  requestPasswordRecovery,
  requestValidationPasswordRecoveryCode,
  requestCleanPasswordRecoveryReducer,
} from "./../../../ReduxSaga/Actions/Client";
import { requestLoggUserActivity } from "./../../../ReduxSaga/Actions/General";
//UTILS
import {
  restrictToOnlyNumbers,
  restrictToOnlyLettersAndNumbers,
  regexIsEmailAddressValid,
  restrictToOnlyLettersForEmail,
  restrictToOnlyNumbersTwo,
  regexIsCellphoneValid,
} from "../../../../Libraries/Utils/regex";
import { Code } from "@mui/icons-material";

const RecoveryPassword = ({ handleGAEvent, isLanguageLoaded }) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  ///REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.client = useSelector((state) => state.client);
  //// history
  const history = useHistory();
  /// traduccion
  const { t, i18n } = useTranslation();
  /// HOOKS

  const [stepRecoveryActive, setStepRecoveryActive] = useState(0);
  const [isLoadingEnable, setIsLoadingEnable] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isReturnActive, setIsReturnActive] = useState(false);
  const [sendPhone, setSendPhone] = useState(false);
  const [isStartCountDown, setIsStartCountDown] = useState(false);
  const [isCountDown, setIsCountDown] = useState(0);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [verificationCode, setVerificationCode] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
  });
  const [emailCodeRequestSend, setEmailCodeRequestSend] = useState(false);
  const [cellPhoneCodeRequestSend, setCellPhoneCodeRequestSend] =
    useState(false);

  ///EFFECTS
  useEffect(() => {
    document.title = "Zermat USA :: Recover Password";
  }, []);
  //EFFECT ON REQUEST EMAIL OR CELLPHONE EVALUATION TO RECOVER PASSWORD

  //EFFECT ON RECOVERY PASSWORD REQUEST
  useEffect(() => {
    if (typeof appReducers?.client?.responsePasswordRecovery !== "undefined") {
      const recoveryResponse = appReducers.client.responsePasswordRecovery;
      if (recoveryResponse.code === 100) {
        if (emailCodeRequestSend) {
          setEmailCodeRequestSend(false);
          setIsEmailSend(true);
          setIsReturnActive(true);
          setIsStartCountDown(true);
        }
        if (cellPhoneCodeRequestSend) {
          setCellPhoneCodeRequestSend(false);
          setSendPhone(true);
          setIsReturnActive(true);
          setIsStartCountDown(true);
        }
        dispatch(
          requestLoggUserActivity(
            33,
            0,
            0,
            "",
            "Envio de Codigo Correcto. Medio: " + emailCodeRequestSend
              ? "Email"
              : "Celular" +
                  " .Contacto: " +
                  (emailCodeRequestSend ? email : phone)
          )
        );
        handleGAEvent("User", "Se Envio Codigo De Recuperacion Al Usuario");
      } else {
        const type = stepRecoveryActive === 0 ? "Email" : "Numero Celular";

        if (type === "Email") {
          if (recoveryResponse.code === -200) {
            enqueueSnackbar(t("__message_email_already_exists_label"), {
              variant: "warning",
            });
          } else {
            enqueueSnackbar(t("__message_email_any_account_label"), {
              variant: "error",
            });
          }
        }
        // else
        //   enqueueSnackbar(t("__message_phone_any_account_label"), {
        //     variant: "error",
        //   });

        if (type === "Numero Celular") {
          if (recoveryResponse.code === -200) {
            enqueueSnackbar(t("__message_cellphone_already_exists_label"), {
              variant: "warning",
            });
          } else {
            enqueueSnackbar(t("__message_phone_any_account_label"), {
              variant: "error",
            });
          }
        }
        // else
        //   enqueueSnackbar(t("__message_email_any_account_label"), {
        //     variant: "error",
        //   });
      }
      //// \\\\
      dispatch(requestCleanPasswordRecoveryReducer());
    }
  }, [
    appReducers?.client?.responsePasswordRecovery,
    i18n?.language,
    cellPhoneCodeRequestSend,
    emailCodeRequestSend,
  ]);

  ///EFFECT ON CODE VALIDATION REQUEST PASSWORD RECOVERY
  useEffect(() => {
    if (appReducers?.client?.userRecoveryCodeValidation) {
      const recoveryCodeResponse =
        appReducers.client.userRecoveryCodeValidation;
      if (recoveryCodeResponse.code == 100) {
        dispatch(
          requestLoggUserActivity(
            33,
            0,
            0,
            "",
            "Codigo de Recuperacion Correcto"
          )
        );
        handleGAEvent(
          "User",
          "Usuario Ingresa Codigo de Recuperacion Correcto"
        );
        history.push("/reset");
      } else {
        if (i18n.language === "es") {
          enqueueSnackbar(t("__message_code_invalid_label"), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("__message_code_invalid_label"), {
            variant: "error",
          });
        }
      }
    }
  }, [appReducers?.client?.userRecoveryCodeValidation, i18n?.language]);

  function getContentOpc(stepRecoveryActive) {
    switch (stepRecoveryActive) {
      case 0:
        return (
          <div
            className={`form__guest recovery__container ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div className="form__guest__header">
              <div>
                <div className="arrobaIcon" />
              </div>
              <div>
                <div className="form__guest__header__title">
                  {t("__recovery_password_form_email")}
                </div>
                <div className="form__guest__header__subtitle">
                  {t("__recovery_password_subtitle_form_email")}
                </div>
              </div>
            </div>
            <div className="form__guest__content">
              <div>
                {!isEmailSend ? (
                  <TextFieldComponent
                    labelName={t("__login_Create_Account_Txt_Email_Label")}
                    typeInput="email"
                    valueInput={email}
                    erroHelp={
                      email.length === 0
                        ? t("__forms_empty_field_error_message")
                        : !regexIsEmailAddressValid(email)
                        ? t("__forms_field_email_validation_js_error_message")
                        : ""
                    }
                    placeholderInput={t("__home_subscribe_placeholder_footer")}
                    isActiveHelp={
                      email.length === 0 || !regexIsEmailAddressValid(email)
                    }
                    textareaEnabled={false}
                    setValue={(e) => setEmail(restrictToOnlyLettersForEmail(e))}
                  />
                ) : (
                  <>
                    {" "}
                    <div
                      className={`recovery__phone ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <input
                        id="number1"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            one: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number2").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number1").focus();
                          }
                        }}
                      />
                      <input
                        id="number2"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            two: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number3").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number1").focus();
                          }
                        }}
                      />
                      <input
                        id="number3"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            three: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number4").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number2").focus();
                          }
                        }}
                      />
                      <input
                        id="number4"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            four: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number5").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number3").focus();
                          }
                        }}
                      />
                      <input
                        id="number5"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            five: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number6").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number4").focus();
                          }
                        }}
                      />
                      <input
                        id="number6"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            six: restrictToOnlyLettersAndNumbers(e),
                          });
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number5").focus();
                          }
                        }}
                      />

                      <div>
                        <Timer
                          setIsReturnActive={setIsReturnActive}
                          isStartCountDown={isStartCountDown}
                          setIsStartCountDown={setIsStartCountDown}
                          isCountDown={isCountDown}
                          setIsCountDown={setIsCountDown}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="recovery__link">
                {isReturnActive && !isStartCountDown && (
                  <Link
                    href="#"
                    onClick={() => {
                      setEmailCodeRequestSend(true);
                      dispatch(requestPasswordRecovery(1, phone, email));
                    }}
                  >
                    {t("__timer_btn_code_is_forwarded")}
                  </Link>
                )}
              </div>
            </div>
            <div className="form__guest__actions">
              <div>
                <Button
                  disabled={
                    email.length === 0 || !regexIsEmailAddressValid(email)
                  }
                  onClick={() => {
                    if (isEmailSend) {
                      const { one, two, three, four, five, six } =
                        verificationCode;
                      const code = `${one}${two}${three}${four}${five}${six}`;
                      if (code.length === 6)
                        dispatch(
                          requestValidationPasswordRecoveryCode(
                            code.toUpperCase()
                          )
                        );
                      else {
                        enqueueSnackbar(
                          t("__message_please_write_code_complete_label"),
                          {
                            variant: "warning",
                          }
                        );
                      }
                    } else {
                      //value.recoveryType === "email" ? 1 : 2,
                      setEmailCodeRequestSend(true);
                      dispatch(requestPasswordRecovery(1, phone, email));
                    }
                  }}
                  className="button__with__icon bg__dark__pink no__left__margin"
                >
                  <div className="button__with__icon__svg__wrapper dark__pink__ball">
                    <div
                      className={
                        email.length === 0 || !regexIsEmailAddressValid(email)
                          ? "userGrayLoginAltSmallIcon"
                          : "userOutlineWhiteIcon"
                      }
                    />
                  </div>
                  <span>
                    {!isEmailSend
                      ? t("btn_send_link")
                      : t("__message_valid_code_label")}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div
            className={`form__guest recovery__container ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <div className="form__guest__header">
              <div>
                <div className="cellphoneGrayIcon" />
              </div>
              <div>
                <div className="form__guest__header__title">
                  {t("__recovery_password_form_sms")}
                </div>
                <div className="form__guest__header__subtitle">
                  {t("__recovery_password_subtitle_form_sms")}
                </div>
              </div>
            </div>
            <div className="form__guest__content">
              <div>
                {!sendPhone ? (
                  <TextFieldComponent
                    labelName={t("__recovery_password_form_sms")}
                    typeInput="text"
                    valueInput={phone}
                    erroHelp={
                      phone.length <= 0
                        ? t("__forms_empty_field_error_message")
                        : phone.length > 0 && !regexIsCellphoneValid(phone)
                        ? t("__message_phone_invalid_code_label")
                        : ""
                    }
                    isActiveHelp={
                      phone.length <= 0 || !regexIsCellphoneValid(phone)
                    }
                    placeholderInput=""
                    textareaEnabled={false}
                    setValue={(e) => setPhone(restrictToOnlyNumbersTwo(e))}
                  />
                ) : (
                  <>
                    {" "}
                    <div
                      className={`recovery__phone ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <input
                        id="number1"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            one: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number2").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number1").focus();
                          }
                        }}
                      />
                      <input
                        id="number2"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            two: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number3").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number1").focus();
                          }
                        }}
                      />
                      <input
                        id="number3"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            three: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number4").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number2").focus();
                          }
                        }}
                      />
                      <input
                        id="number4"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            four: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number5").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number3").focus();
                          }
                        }}
                      />
                      <input
                        id="number5"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            five: restrictToOnlyLettersAndNumbers(e),
                          });
                          document.getElementById("number6").focus();
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number4").focus();
                          }
                        }}
                      />
                      <input
                        id="number6"
                        className={`number_phone ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                        placeholder="0"
                        aria-describedby="my-helper-text"
                        maxLength={1}
                        type={"text"}
                        onInput={(e) => {
                          setVerificationCode({
                            ...verificationCode,
                            six: restrictToOnlyLettersAndNumbers(e),
                          });
                        }}
                        onKeyUp={(e) => {
                          if (e.key === "Backspace") {
                            document.getElementById("number5").focus();
                          }
                        }}
                      />

                      <div>
                        <Timer
                          setIsReturnActive={setIsReturnActive}
                          isStartCountDown={isStartCountDown}
                          setIsStartCountDown={setIsStartCountDown}
                          isCountDown={isCountDown}
                          setIsCountDown={setIsCountDown}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="recovery__link">
                {isReturnActive && !isStartCountDown && (
                  <Link
                    href="#"
                    onClick={() => {
                      setCellPhoneCodeRequestSend(true);
                      dispatch(requestPasswordRecovery(2, phone, email));
                    }}
                  >
                    {t("__timer_btn_code_is_forwarded")}
                  </Link>
                )}
              </div>
            </div>
            <div className="form__guest__actions">
              <div>
                <Button
                  onClick={() => {
                    if (sendPhone) {
                      const { one, two, three, four, five, six } =
                        verificationCode;
                      const code = `${one}${two}${three}${four}${five}${six}`;
                      if (code.length === 6)
                        dispatch(
                          requestValidationPasswordRecoveryCode(
                            code.toUpperCase()
                          )
                        );
                      else {
                        enqueueSnackbar(
                          t("__message_please_write_code_complete_label"),
                          {
                            variant: "warning",
                          }
                        );
                      }
                    } else {
                      setCellPhoneCodeRequestSend(true);
                      dispatch(requestPasswordRecovery(2, phone, email));
                    }
                  }}
                  className="button__with__icon bg__dark__pink no__left__margin"
                >
                  <div className="button__with__icon__svg__wrapper dark__pink__ball">
                    <div className={"userOutlineWhiteIcon"} />
                  </div>
                  <span>{t("btn_send_link")}</span>
                </Button>
              </div>
            </div>
          </div>
        );
    }
  }

  return (
    <div
      id="firstWrapperInBaseContainer"
      className={`firstWrapperInBaseContainer new__login__step ${
        isTablet ? "tablet" : isMobile ? "mobile" : ""
      }`}
    >
      {/* {console.log("idioma", isLanguageLoaded)} */}
      {isLoadingEnable || !isLanguageLoaded ? (
        <Skeleton width={"40%"} height={100} className="skeletoCenter" />
      ) : (
        <div
          className={`new__login__step__title ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {t("__recovery_password_pick_option")}
        </div>
      )}

      <div
        className={`new__login__step__btns__actions ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        {isLoadingEnable || !isLanguageLoaded ? (
          <Skeleton width={"15%"} height={100} style={{ margin: "0 10px" }} />
        ) : (
          <div
            className={`new__login__step__btn ${
              stepRecoveryActive === 0 ? "active" : ""
            }`}
            onClick={() => {
              setIsEmailSend(false);
              setIsReturnActive(false);
              setIsStartCountDown(false);
              setStepRecoveryActive(0);
            }}
          >
            <div className="arrobaSmallIcon" />
            <span>{t("__recovery_password_form_email")}</span>
          </div>
        )}
        {isLoadingEnable || !isLanguageLoaded ? (
          <Skeleton width={"15%"} height={100} style={{ margin: "0 10px" }} />
        ) : (
          <div
            className={`new__login__step__btn ${
              stepRecoveryActive === 1 ? "active" : ""
            }`}
            onClick={() => {
              setSendPhone(false);
              setIsReturnActive(false);
              setIsStartCountDown(false);
              setStepRecoveryActive(1);
            }}
          >
            <div className="cellphoneGraySmallIcon" />
            <span>{t("__recovery_password_form_sms")}</span>
          </div>
        )}
      </div>
      <div className="new__login__step__form">
        <div
          className={`new__login__step__form__content ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {isLanguageLoaded ? (
            getContentOpc(stepRecoveryActive)
          ) : (
            <div
              className={`form__guest recovery__container ${
                isTablet ? "tablet" : isMobile ? "mobile" : ""
              }`}
            >
              <div className={`form__guest__header`}>
                <div style={{ width: 140 }}>
                  <Skeleton variant="circle" width={100} height={100} />
                </div>
                <div style={{ width: "100%" }}>
                  <div className="form__guest__header__title">
                    <Skeleton variant="rect" width={"50%"} height={30} />
                  </div>
                  <div className="form__guest__header__subtitle">
                    <Skeleton variant="rect" width={"40%"} height={30} />
                  </div>
                </div>
              </div>
              <div className="form__guest__content">
                <div style={{ width: "80%" }}>
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={30}
                    style={{ margin: 5 }}
                  />
                </div>
              </div>
              <div className="form__guest__actions">
                <div style={{ width: "50%" }}>
                  <Skeleton variant="rect" width={"50%"} height={30} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecoveryPassword;

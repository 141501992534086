import React, { useEffect, Fragment } from "react";
import { Typography } from "@mui/material";

const ValidationCodeCountdown = ({
    t,
    isStartCountDown,
    setIsStartCountDown,
    countDownSeconds,
    setCountDownSeconds
}) => {
    const seconds = String(countDownSeconds % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDownSeconds / 60)).padStart(2, 0);

    useEffect(() => {
        let timerId;
        if (isStartCountDown) {
            timerId = setInterval(() => {
                setCountDownSeconds((countDownSeconds) => countDownSeconds - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        }
        return () => clearInterval(timerId);
    }, [isStartCountDown]);

    useEffect(() => {
        if (countDownSeconds <= 0) {
            setIsStartCountDown(false);
        }
    }, [countDownSeconds]);

    return (
        <Fragment>
            <Typography variant="subtitle1" gutterBottom>
                {minutes}:{seconds}
            </Typography>
        </Fragment>
    );
};

export default ValidationCodeCountdown;

import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
//MUI
import { Button, ClickAwayListener, IconButton } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
//Components
import Counter from "../../base/components/Counter";
import CenteredModal from "../../base/components/CenteredModal";
import { useSnackbar } from "notistack";
///ACTIONS
import {
  requestCarCurrentOrder,
  requestGetCartOrder,
  cleanCartOrderOperations,
  requestGetCartCouponList,
  requestCartCancelOrder,
  requestCleanCartReducerCancelOrder,
} from "./../../../ReduxSaga/Actions/Cart";
import { requestLoggUserActivity } from "./../../../ReduxSaga/Actions/General";
//Assets
import { ReactComponent as BagIcon } from "./../../../../assets/media/images/icons/bagIcon.svg";
import { ReactComponent as BagWhiteIcon } from "./../../../../assets/media/images/icons/bagIconWhiteAlt.svg";
import { ReactComponent as BagActiveIcon } from "./../../../../assets/media/images/icons/bagActiveWhiteIcon.svg";
import { ReactComponent as ExtrasIcon } from "./../../../../assets/media/images/icons/extrasIcon.svg";
import { ReactComponent as TrashIcon } from "./../../../../assets/media/images/icons/trashIcon.svg";
import { ReactComponent as DiscountIcon } from "./../../../../assets/media/images/icons/discountIcon.svg";
import { ReactComponent as ExclamationIcon } from "./../../../../assets/media/images/icons/exclamationOrangeIcon.svg";
import { ReactComponent as CheckSucessIcon } from "./../../../../assets/media/images/icons/checkGreenIcon.svg";
import { capitalizeText, formatPrice } from "../../../../Libraries/Utils/utils";

import Tooltip from "@mui/material/Tooltip";

const fakeProducts = [
  {
    id: 1,
    name: "XPREXION MAN COLLECTION",
    imageUrl: "https://www.websizusa.net/imgprod/fullsize/82437.jpg",
    oldPrice: 56,
    newPrice: 43.1,
    isPreferredClientPrice: true,
    type: "",
    configurationNumber: 0,
  },
  {
    id: 2,
    name: "PLAYER INTENSE COLLECTION",
    imageUrl: "https://www.websizusa.net/imgprod/fullsize/220908.jpg",
    oldPrice: 35,
    newPrice: 32,
    isPreferredClientPrice: true,
    type: "kit",
    configurationNumber: 1,
  },
  {
    id: 3,
    name: "PLAYER INTENSE COLLECTION",
    imageUrl: "https://www.websizusa.net/imgprod/fullsize/220908.jpg",
    oldPrice: 35,
    newPrice: 32,
    isPreferredClientPrice: true,
    type: "kit",
    configurationNumber: 2,
  },
  {
    id: 4,
    name: "PERSONALIZED FRAGRANCE FOR GIRL 1.69 FL. ",
    imageUrl: "https://www.websizusa.net/imgprod/fullsize/42913.jpg",
    oldPrice: 0,
    newPrice: 34,
    isPreferredClientPrice: true,
    type: "personalizedFragrance",
    configurationNumber: 0,
  },
  {
    id: 5,
    name: "VIZAZE VIVALDI EDT 2 FL. OZ",
    imageUrl: "https://www.websizusa.net/imgprod/fullsize/82168.jpg",
    oldPrice: 33,
    newPrice: 25.9,
    isPreferredClientPrice: true,
    type: "",
    configurationNumber: 0,
  },
  {
    id: 6,
    name: "Membresía Cliente preferido + Experience Box",
    imageUrl: "https://www.websizusa.net/imgprod/fullsize/02110.jpg",
    oldPrice: 0,
    newPrice: 10,
    isPreferredClientPrice: false,
    type: "membership",
    configurationNumber: 0,
  },
];

const FloatingCheckout = ({
  open,
  setIsOpenFloatingCheckout,
  infoCartOrder,
  admissionKits,
  cpMemberships,
  benefitType,
  setBenefitType,
  handleGAEvent,
  isOpenGiftSelectModal,
  countGiftSelect,
  floatingCheckoutIconIsClicked,
  setFloatingCheckoutIconIsClicked,
  clientInfoToCloseOrder,
  handleResetInfoClient,
  handleResetInfoGuestForm,
  isLanguageLoaded,
}) => {
  ///INITIAL STATES
  const initialNotifyData = {
    notify: false,
    message: "",
    type: "",
  };
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  //React Router
  const history = useHistory();
  //Translation
  const { t, i18n } = useTranslation();
  ///REDUX
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.general = useSelector((state) => state.general);
  //Hooks
  const [selectedMembership, setSelectedMembership] = useState("");
  const [
    isOpenRemoveMembershipCPModalConfirmation,
    setIsOpenRemoveMembershipCPModalConfirmation,
  ] = useState(false);
  const [
    isOpenRemoveMembershipEntrepreneurModalConfirmation,
    setIsOpenRemoveMembershipEntrepreneurModalConfirmation,
  ] = useState(false);
  const [isOpenGiftsAvailableModal, setIsOpenGiftsAvailableModal] =
    useState(false);
  const [linesOrder, setLinesOrder] = useState([]);
  const [order, setOrder] = useState({});
  const [notifyData, setNotifyData] = useState(initialNotifyData);
  const [isGuest, setIsGuest] = useState(true);
  const [
    isOpenPersonalizedFragrancesIncrementWarningModal,
    setIsOpenPersonalizedFragrancesIncrementWarningModal,
  ] = useState(false);
  const [
    personalizedFragranceToIncrementCode,
    setPersonalizedFragranceToIncrement,
  ] = useState();
  const [personalizedFragranceTypeLine, setPersonalizedFragranceTypeLine] =
    useState("");
  const [sets, setSets] = useState([]);
  const [blockPayButton, setblockPayButton] = useState(false);
  //Effects
  useEffect(() => {
    if (linesOrder.length === 0) {
      setIsOpenFloatingCheckout(false);
      setblockPayButton(true);
    } else {
      //console.log("orden lineas", linesOrder);
      evaluateBlockPayButtonByMinAmount();
      const sets = linesOrder.filter(
        (item) =>
          item.line.tipolinea === "K" ||
          item.line.tipolinea === "P" ||
          item.line.tipolinea === "FV" ||
          item.line.tipolinea === "VF" ||
          item.line.tipolinea === "VV"
      );
      //console.log("sets", sets);
      setSets(sets);
    }
  }, [linesOrder]);
  useEffect(() => {
    if (infoCartOrder?.hasOwnProperty("code") && infoCartOrder?.code === 100) {
      if (infoCartOrder?.order && infoCartOrder?.order !== null) {
        setOrder(infoCartOrder?.order);
        setLinesOrder(infoCartOrder?.lines);
      }
    }
  }, [infoCartOrder]);

  useEffect(() => {
    if (notifyData?.notify) {
      enqueueSnackbar(notifyData.message, {
        variant: notifyData.type,
      });
      setNotifyData(initialNotifyData);
    }
  }, [notifyData.notify]);

  useEffect(() => {
    if (open && typeof appReducers?.cart?.cartCurrentOrder !== "undefined") {
      switch (appReducers?.cart?.cartCurrentOrder?.code) {
        case 100:
          const typeOp = appReducers?.cart?.cartOpType;
          switch (typeOp) {
            case "add":
              setNotifyData({
                message: t("__message_success_product_add_cart_label"),
                notify: true,
                type: "success",
              });
              break;
            case "addClientCoupon":
              enqueueSnackbar(
                t("__message_success_client_coupon_add_cart_label"),
                {
                  variant: "success",
                }
              );
              dispatch(requestGetCartCouponList());
              break;
            case "delClientCoupon":
              enqueueSnackbar(
                t("__message_success_client_coupon_deleted_cart_label"),
                {
                  variant: "success",
                }
              );
              dispatch(requestGetCartCouponList());
              break;
            case "modify":
              setNotifyData({
                message: t("__message_success_product_modified_cart_label"),
                notify: true,
                type: "success",
              });
              break;
            case "delete":
              setNotifyData({
                message: t("__message_success_product_deleted_cart_label"),
                notify: true,
                type: "warning",
              });
              break;
          }
          // if (notifyData.message.length > 0)
          //   setNotifyData({ ...notifyData, notify: true, type: "success" });
          // else
          //   setNotifyData({
          //     ...notifyData,
          //     notify: true,
          //     type: "success",
          //     message: t("__message_success_product_add_cart_label"),
          //   });
          dispatch(requestGetCartOrder());
          dispatch(cleanCartOrderOperations());
          break;
        case 110:
          setNotifyData({
            notify: true,
            type: "warning",
            message: t("__message_beauty_boxes_exeded_product_label"),
          });
          break;
        case -100:
          setNotifyData({
            notify: true,
            type: "error",
            message: appReducers?.cart?.cartCurrentOrder?.message,
          });
          break;
        default:
      }
    }
  }, [
    appReducers.cart.cartCurrentOrder,
    appReducers.cart.cartOrder,
    appReducers.cart.errorCartOrder,
    open,
  ]);

  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (
        !appReducers.general.errorLoadFirstCharge &&
        typeof appReducers?.general?.firstChargeModel !== "undefined"
      ) {
        const { isGuestUser } = appReducers.general.firstChargeModel;
        setIsGuest(isGuestUser);
      }
    }
  }, [
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
  ]);

  ///EFFECT ON ORDER CANCELATION
  useEffect(() => {
    if (
      typeof appReducers?.cart?.errorCartCancelOrder !== "undefined" &&
      open
    ) {
      enqueueSnackbar(t("shopping__payment__error_cancel_order_message"), {
        variant: "error",
      });
      dispatch(requestCleanCartReducerCancelOrder());
    } else {
      if (appReducers?.cart?.cartCancelOrder) {
        const response = appReducers?.cart?.cartCancelOrder;
        if (response.code === 100) {
          enqueueSnackbar(t("__message_order_cancel_label"), {
            variant: "success",
          });
          dispatch(requestGetCartOrder());
          dispatch(requestLoggUserActivity(7, 0, 0, "", "Se cancelo la orden"));
          handleGAEvent("CartOrder", "Usuario Cancela Orden");
          history.replace("/categories");
          if (!clientInfoToCloseOrder.allReadyRegister) {
            handleResetInfoClient();
          } else {
            handleResetInfoGuestForm();
          }
        }
      }
      dispatch(requestCleanCartReducerCancelOrder());
    }
    setIsOpenFloatingCheckout(false);
  }, [
    appReducers?.cart.cartCancelOrder,
    appReducers?.cart.errorCartCancelOrder,
  ]);
  //HANDLER
  const handleOpsOnOrder = (type, product, qty) => {
    if (
      (product.tipolinea === "P" ||
        product.tipolinea === "N" ||
        product.tipolinea === "PD" ||
        product.tipolinea === "FV" ||
        product.tipolinea === "VV" ||
        product.tipolinea === "FV") &&
      product.isCustomLabel &&
      type === "add"
    ) {
      // alert(
      //   "para agregar otro productos debes ir al configurador de gragancias personalizadas; deseas hacerlo ahora?"
      // );
      // history.push(`/personalized-fragance/it/${product.codart}`);
      setPersonalizedFragranceToIncrement(product.codart);
      setPersonalizedFragranceTypeLine(product.tipolinea);
      setIsOpenPersonalizedFragrancesIncrementWarningModal(true);
    } else {
      const parameters = {
        idPedidoDet: product.idpedidodet,
        idpromo: 0,
        itemCode: product.codart,
        opc: -1,
        pedidosD: [],
        quantity: qty,
        type: product.tipolinea,
        withAssign: 0,
      };
      switch (type) {
        case "delete":
          parameters.opc = 4;
          dispatch(requestCarCurrentOrder(parameters, type));
          dispatch(
            requestLoggUserActivity(
              8,
              0,
              0,
              product.codart,
              "Se elimino producto de la orden"
            )
          );
          handleGAEvent(
            "CartOrder",
            "Usuario Edito La Orden, Elimino El Articulo: " + product.codart
          );
          break;
        case "delClientCoupon":
          parameters.opc = 4;
          dispatch(requestCarCurrentOrder(parameters, type));
          dispatch(
            requestLoggUserActivity(
              8,
              0,
              0,
              product.codart,
              "Se elimino producto de la orden"
            )
          );
          handleGAEvent(
            "CartOrder",
            "Usuario Edito La Orden, Elimino El Articulo: " + product.codart
          );
          break;
        case "add":
          parameters.opc = 5;
          dispatch(requestCarCurrentOrder(parameters, type));
          dispatch(
            requestLoggUserActivity(
              9,
              0,
              0,
              product.codart,
              `Se incremento la cantidad en 1 del producto: ${product.codart}`
            )
          );
          handleGAEvent(
            "CartOrder",
            `Usuario Edito La Orden, Incrementó La Cantidad De: ${product.codart}`
          );
          break;
        case "minus":
          parameters.opc = 5;
          dispatch(requestCarCurrentOrder(parameters, "delete"));
          dispatch(
            requestLoggUserActivity(
              10,
              0,
              0,
              product.codart,
              `Se decremento la cantidad en 1 del producto: ${product.codart}`
            )
          );
          handleGAEvent(
            "CartOrder",
            `Usuario Edito La Orden, Decrementó La Cantidad De: ${product.codart}`
          );
          break;
        default:
          break;
      }
    }
  };
  //RENDER
  const evaluateBlockPayButtonByMinAmount = () => {
    if (linesOrder.length > 0) {
      let blockButton = false;
      linesOrder.forEach((item) => {
        if (item.line.amountMin > 0 && blockButton === false) {
          const sumAic = linesOrder
            .filter(
              (lo) =>
                lo.line.codart !== item.line.codart &&
                lo.line.comisionable === 1
            )
            .reduce((acum, current) => acum + current.line.montolinai2, 0);
          if (item.line.amountMin > sumAic) blockButton = true;
        }
      });
      setblockPayButton(blockButton);
    } else setblockPayButton(false);
  };
  return (
    <Fragment>
      {order.hasOwnProperty("orderId") && (
        <ClickAwayListener
          onClickAway={() => {
            setFloatingCheckoutIconIsClicked(false);
            if (!floatingCheckoutIconIsClicked) {
              setIsOpenFloatingCheckout(false);
            }
          }}
        >
          <div
            className={`floating__checkout ${open ? "active" : ""} ${
              isIOS ? "is__iOS" : ""
            }`}
          >
            <div className="floating__checkout__header">
              <div className="floating__checkout__header__title">
                <BagIcon />
                <span>{t("__floating__checkout__header__title")}</span>
              </div>
              <div className="floating__checkout__header__close__wrapper">
                <IconButton
                  onClick={() => {
                    setIsOpenFloatingCheckout(false);
                  }}
                >
                  <Close />
                </IconButton>
              </div>
            </div>

            <div className="floating__checkout__scrollable__wrapper">
              {/* PRODUCTS */}
              <div className="floating__checkout__scrollable__wrapper__products__section">
                <div className="floating__checkout__scrollable__wrapper__products__section__title__wrapper">
                  <div className="floating__checkout__scrollable__wrapper__products__section__title__wrapper__texts__wrapper">
                    <span className="floating__checkout__scrollable__wrapper__products__section__title__wrapper__title">
                      {t("__floating__checkout__products__section__title")}
                    </span>
                    <span className="floating__checkout__scrollable__wrapper__products__section__title__wrapper__count">
                      {`(${linesOrder.length} ${t(
                        "__floating__checkout__products__section__product"
                      )}${linesOrder.length > 1 ? "s" : ""})`}
                    </span>
                  </div>

                  <div className="floating__checkout__scrollable__wrapper__products__section__title__wrapper__drop__order">
                    <IconButton
                      onClick={() => {
                        dispatch(requestCartCancelOrder());
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                </div>
                <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper">
                  {linesOrder.map((item, index) => {
                    //console.log("item FC", item)
                    return (
                      <div
                        key={index}
                        className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item"
                      >
                        <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__content">
                          <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__image__wrapper">
                            <img
                              src={item.line.imageUrl}
                              alt=""
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://www.websizusa.net/imgprod/fullsize/sinproducto.jpg";
                              }}
                            />
                          </div>

                          <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper">
                            <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__header">
                              {benefitType === 2 && (
                                <span className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__header__title">
                                  {t(
                                    "__floating__checkout__products__section__special__shopper__price"
                                  )}
                                </span>
                              )}
                              {benefitType === 1 && (
                                <span className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__header__title">
                                  {t(
                                    "__floating__checkout__price__entrepreneur"
                                  )}
                                </span>
                              )}
                              {(item.line.tipolinea === "K" ||
                                item.line.tipolinea === "P" ||
                                item.line.tipolinea === "FV" ||
                                item.line.tipolinea === "VF" ||
                                item.line.tipolinea === "VV") && (
                                <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__header__configurable__wrapper">
                                  {(item.line.tipolinea === "K" ||
                                    item.line.tipolinea === "P" ||
                                    item.line.tipolinea === "FV" ||
                                    item.line.tipolinea === "VF" ||
                                    item.line.tipolinea === "VV") && (
                                    // <span>{`Set ${index + 1}`}</span>
                                    <span>{`Set ${
                                      sets.findIndex(
                                        (set) =>
                                          set.line.codart === item.line.codart
                                      ) + 1
                                    }`}</span>
                                  )}
                                  {/* {1 === 0 && ( /// aqui la evaluación. ESE EDIT LLEVA UN PUSH A LA CONFIGURACION DE FRAGANCIA PERSONALIZADA
                                <IconButton
                                  onClick={() => {
                                    //
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              )} */}
                                </div>
                              )}
                            </div>
                            <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__title__and__old__price">
                              <span className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__title__and__old__price__title">
                                {i18n.language === "es"
                                  ? capitalizeText(item.line.descripcionEs)
                                  : capitalizeText(item.line.descripcionEn)}
                              </span>

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {item.line.montolinai !==
                                  item.line.montolinai2 && (
                                  <span className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__title__and__old__price__old__price">
                                    {formatPrice(item.line.montolinai2)}
                                  </span>
                                )}
                                {item.line.montolinai !==
                                  item.line.montolinai2 && (
                                  <div className="product__price__with__discount__wrapper__inner">
                                    <span>{`- ${Math.floor(
                                      100 -
                                        (item.line.montolinai * 100) /
                                          item.line.montolinai2
                                    )}`}</span>
                                    <DiscountIcon />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__new__price__and__counter">
                              <span className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__new__price__and__counter__new__price">
                                {item.line.tipolinea === "R"
                                  ? t("__general__gift")
                                  : formatPrice(item.line.montolinai)}
                              </span>

                              <span></span>
                              <Counter
                                showOnlyQty={
                                  item.line.tipolinea === "R" ||
                                  item.line.tipolinea === "K" ||
                                  (item.line.tipolinea === "P" &&
                                    item.line.montolinai < 0)
                                }
                                withTrash={item.line.tipolinea !== "R"}
                                disableMinus={
                                  //item.line.tipolinea === "P" ||
                                  item.line.tipolinea === "R" ||
                                  item.line.tipolinea === "K" ||
                                  (item.line.tipolinea === "P" &&
                                    item.line.montolinai < 0)
                                }
                                disableAdd={
                                  //item.line.tipolinea === "P" ||
                                  item.line.tipolinea === "R" ||
                                  item.line.tipolinea === "K" ||
                                  (item.line.tipolinea === "P" &&
                                    item.line.montolinai < 0)
                                }
                                disabledRemove={item.line.tipolinea === "R"}
                                qtyOnOrder={item.line.qtyped}
                                handleQtyAdd={() => {
                                  ////regla de cantidad Maxima de Sets
                                  // let a = item?.line;
                                  // let b = infoCartOrder?.lines.reduce((accumulator, object) => object.line.codart === item.line.codart ? accumulator + object.line.qtyped : accumulator ,0) + 1;

                                  if (
                                    item.line.cantMax > 0 &&
                                    infoCartOrder.lines &&
                                    infoCartOrder.lines.reduce(
                                      (accumulator, object) =>
                                        object.line.codart === item.line.codart
                                          ? accumulator + object.line.qtyped
                                          : accumulator,
                                      0
                                    ) +
                                      1 >
                                      item.line.cantMax
                                  ) {
                                    //notificar
                                    enqueueSnackbar(
                                      t(
                                        "__general_warning_information_cant_max"
                                      ),
                                      {
                                        variant: "warning",
                                      }
                                    );
                                  } else {
                                    const message =
                                      t("_Zusa_Item_Detail_was") +
                                      t("_Zusa_Item_Detail_modified") +
                                      t("_Zusa_Item_Detail_correclty");
                                    setNotifyData({ ...notifyData, message });
                                    handleOpsOnOrder("add", item.line, 1);
                                  }
                                }}
                                handleQtyMinus={() => {
                                  const message =
                                    t("_Zusa_Item_Detail_was") +
                                    t("_Zusa_Item_Detail_modified") +
                                    t("_Zusa_Item_Detail_correclty");
                                  setNotifyData({ ...notifyData, message });
                                  handleOpsOnOrder("minus", item.line, -1);
                                }}
                                handleRemove={() => {
                                  const message =
                                    t("_Zusa_Item_Detail_was") +
                                    t("_Zusa_Item_Detail_canceled") +
                                    t("_Zusa_Item_Detail_correclty");
                                  setNotifyData({ ...notifyData, message });
                                  handleOpsOnOrder(
                                    item.line.montolinai > 0
                                      ? "delete"
                                      : "delClientCoupon",
                                    item.line,
                                    0
                                  );
                                }}
                                // item.line.isCustomLabel
                                isPersonalizedFragrance={false}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Aqui comienza lo nuevo requerimiento  */}
                        {item.line.amountMin > 0 && (
                          <Fragment>
                            <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__divider" />

                            <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__warning">
                              <span
                                className={`floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__warning__message ${
                                  item.line.amountMin <
                                  linesOrder
                                    .filter(
                                      (lo) =>
                                        lo.line.codart !== item.line.codart &&
                                        lo.line.comisionable === 1
                                    )
                                    .reduce(
                                      (acum, current) =>
                                        acum + current.line.montolinai2,
                                      0
                                    )
                                    ? "text__success"
                                    : "text__warning"
                                }`}
                              >
                                {t(
                                  "__floating__checkout__set__minAmount__claimed_msg"
                                )}
                              </span>
                              <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__warning__quantities">
                                <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__warning__quantities__success">
                                  <span>{`${t(
                                    "__floating__checkout__set__minAmount__required"
                                  )}$${item.line.amountMin.toFixed(2)}`}</span>
                                  {item.line.amountMin >
                                    linesOrder
                                      .filter(
                                        (lo) =>
                                          lo.line.codart !== item.line.codart &&
                                          lo.line.comisionable === 1
                                      )
                                      .reduce(
                                        (acum, current) =>
                                          acum + current.line.montolinai2,
                                        0
                                      ) && (
                                    <span>
                                      {t(
                                        "__floating__checkout__set__minAmount__left"
                                      )}
                                      <span
                                        className={`floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__data__wrapper__warning__quantities__success ${
                                          item.line.amountMin <
                                          linesOrder
                                            .filter(
                                              (lo) =>
                                                lo.line.codart !==
                                                  item.line.codart &&
                                                lo.line.comisionable === 1
                                            )
                                            .reduce(
                                              (acum, current) =>
                                                acum + current.line.montolinai2,
                                              0
                                            )
                                            ? "text__success"
                                            : "text__warning"
                                        }`}
                                      >
                                        $
                                        {(
                                          item.line.amountMin -
                                          linesOrder
                                            .filter(
                                              (lo) =>
                                                lo.line.codart !==
                                                  item.line.codart &&
                                                lo.line.comisionable === 1
                                            )
                                            .reduce(
                                              (acum, current) =>
                                                acum + current.line.montolinai2,
                                              0
                                            )
                                        ).toFixed(2)}
                                      </span>
                                    </span>
                                  )}
                                </div>

                                {item.line.amountMin <
                                linesOrder
                                  .filter(
                                    (lo) =>
                                      lo.line.codart !== item.line.codart &&
                                      lo.line.comisionable === 1
                                  )
                                  .reduce(
                                    (acum, current) =>
                                      acum + current.line.montolinai2,
                                    0
                                  ) ? (
                                  <Tooltip
                                    placement="top-end"
                                    title={t(
                                      "__floating__checkout__set__minAmount__tooltip__congrats__title"
                                    )}
                                    arrow
                                  >
                                    <IconButton>
                                      <CheckSucessIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    placement="top-end"
                                    title={`${t(
                                      "__floating__checkout__set__minAmount__tooltip__left__title"
                                    )}$${item.line.amountMin.toFixed(2)}`}
                                    arrow
                                  >
                                    <IconButton>
                                      <ExclamationIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </Fragment>
                        )}
                        {/* Aqui termina lo nuevo requerimiento  */}
                      </div>
                    );
                  })}
                </div>

                {benefitType !== 5 ? (
                  order?.importeii !== order?.importeii2 ? (
                    <Fragment>
                      <div
                        className="floating__checkout__scrollable__wrapper__products__section__footer"
                        style={{ marginBottom: 0, fontSize: 13 }}
                      >
                        <span className="floating__checkout__scrollable__wrapper__products__section__footer__title">
                          SUBTOTAL:
                        </span>
                        {console.log("order FC", order)}
                        <span className="floating__checkout__scrollable__wrapper__products__section__footer__amount">
                          {formatPrice(
                            order?.importeaic2 + order?.importeainc2
                          )}
                        </span>
                      </div>
                      <div className="floating__checkout__scrollable__wrapper__products__section__footer">
                        <span className="floating__checkout__scrollable__wrapper__products__section__footer__title">
                          {benefitType === 2
                            ? `${t("__general__subtotal__with__membership")}:`
                            : benefitType === 1
                            ? `${t("__general__subtotal__with__kit")}:`
                            : ""}
                        </span>

                        <span className="floating__checkout__scrollable__wrapper__products__section__footer__amount">
                          {formatPrice(order?.importeaic + order?.importeainc)}
                        </span>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="floating__checkout__scrollable__wrapper__products__section__footer">
                      <span className="floating__checkout__scrollable__wrapper__products__section__footer__title">
                        SUBTOTAL:
                      </span>

                      <span className="floating__checkout__scrollable__wrapper__products__section__footer__amount">
                        {formatPrice(order?.subtotal)}
                      </span>
                    </div>
                  )
                ) : (
                  <div className="floating__checkout__scrollable__wrapper__products__section__footer">
                    <span className="floating__checkout__scrollable__wrapper__products__section__footer__title">
                      SUBTOTAL:
                    </span>

                    <span className="floating__checkout__scrollable__wrapper__products__section__footer__amount">
                      {formatPrice(order?.subtotal)}
                    </span>
                  </div>
                )}
              </div>
              {/* END PRODUCTS */}

              {/* EXTRAS */}

              {benefitType !== 1 && (
                <div className="floating__checkout__scrollable__wrapper__products__section">
                  <div className="floating__checkout__scrollable__wrapper__products__section__title__wrapper">
                    <div className="floating__checkout__scrollable__wrapper__products__section__title__wrapper__texts__wrapper">
                      <ExtrasIcon />
                      <span className="floating__checkout__scrollable__wrapper__products__section__title__wrapper__title">
                        {t("__floating__checkout__section__add")}
                      </span>
                    </div>
                  </div>
                  <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper">
                    {benefitType === 5 && (
                      <div
                        className={`floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership ${
                          selectedMembership === "CP" ? "active" : ""
                        }`}
                      >
                        <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__title">
                          <span>
                            {t(
                              "__floating__checkout__scpecial__shopper__membership"
                            )}
                          </span>
                        </div>
                        <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content">
                          <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__image__wrapper">
                            {i18n.language === "en" ? (
                              <img
                                src="https://www.websizusa.net/imgprod/zu/modules/1/68/3/Home_ad5_lg_en.jpg"
                                alt=""
                              />
                            ) : (
                              <img
                                src="https://www.websizusa.net/imgprod/zu/modules/1/67/3/Home_ad5_lg_es.jpg"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__data__wrapper">
                            <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__data__wrapper__legend">
                              <span>
                                {t(
                                  "__floating__checkout__special__shopper__legend"
                                )}
                              </span>
                            </div>
                            <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__data__wrapper__price__and__button__wrapper">
                              <span className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__data__wrapper__price__and__button__wrapper__price">
                                {formatPrice(10)}
                              </span>
                              <Button
                                className={`button__with__icon bg__dark__pink no__left__margin small ${
                                  isMobile || isTablet ? "only__icon" : ""
                                }`}
                                onClick={() => {
                                  setIsOpenFloatingCheckout(false);
                                  const url = localStorage.getItem(
                                    "ZUsaUrlPage"
                                  )
                                    ? `/favoriteCustomer/${localStorage.getItem(
                                        "ZUsaUrlPage"
                                      )}`
                                    : "/favoriteCustomer";
                                  history.push(url);
                                  // if (selectedMembership === "CP") {
                                  //   //setSelectedMembership("");
                                  //   setIsOpenRemoveMembershipCPModalConfirmation(
                                  //     true
                                  //   );
                                  // } else {
                                  //   setSelectedMembership("CP");
                                  // }
                                }}
                              >
                                <div className="button__with__icon__svg__wrapper dark__pink__ball">
                                  {selectedMembership === "CP" ? (
                                    <BagActiveIcon />
                                  ) : (
                                    <BagWhiteIcon />
                                  )}
                                </div>

                                {selectedMembership === "CP" ? (
                                  isMobile || isTablet ? (
                                    <Close />
                                  ) : (
                                    <span className="button__with__icon__text">
                                      {t("__general__remove__label")}
                                    </span>
                                  )
                                ) : isMobile || isTablet ? (
                                  <span></span>
                                ) : (
                                  <span className="button__with__icon__text">
                                    {t("__general__add__label")}
                                  </span>
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className={`floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership ${
                        selectedMembership === "Entrepreneur" ? "active" : ""
                      }`}
                    >
                      <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__title">
                        <span>
                          {t("__floating__checkout__entrepreneur__membership")}
                        </span>
                      </div>
                      <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content">
                        <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__image__wrapper">
                          {i18n.language === "en" ? (
                            <img
                              src="https://www.websizusa.net/imgprod/zu/modules/1/70/3/Home_ad6_lg_en.jpg"
                              alt=""
                            />
                          ) : (
                            <img
                              src="https://www.websizusa.net/imgprod/zu/modules/1/69/3/Home_ad6_lg_es.jpg"
                              alt=""
                            />
                          )}
                        </div>
                        <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__data__wrapper">
                          <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__data__wrapper__legend">
                            <span>
                              {t("__floating__checkout__entrepreneur__legend")}
                            </span>
                          </div>
                          <div className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__data__wrapper__price__and__button__wrapper">
                            <span className="floating__checkout__scrollable__wrapper__products__section__list__wrapper__item__membership__content__data__wrapper__price__and__button__wrapper__price">
                              {/* {formatPrice(19.95)}-{formatPrice(49.95)} */}
                              {formatPrice(0.01)}
                            </span>
                            <Button
                              className={`button__with__icon bg__dark__pink no__left__margin small ${
                                isMobile || isTablet ? "only__icon" : ""
                              }`}
                              onClick={() => {
                                // if (selectedMembership === "Entrepreneur") {
                                //   //setSelectedMembership("");
                                //   setIsOpenRemoveMembershipEntrepreneurModalConfirmation(
                                //     true
                                //   );
                                // } else {
                                //   setSelectedMembership("Entrepreneur");
                                // }
                                setIsOpenFloatingCheckout(false);
                                const url = localStorage.getItem("ZUsaUrlPage")
                                  ? `/enterprising/${localStorage.getItem(
                                      "ZUsaUrlPage"
                                    )}`
                                  : "/enterprising";
                                history.push(url);
                              }}
                            >
                              <div className="button__with__icon__svg__wrapper dark__pink__ball">
                                {selectedMembership === "Entrepreneur" ? (
                                  <BagActiveIcon />
                                ) : (
                                  <BagWhiteIcon />
                                )}
                              </div>

                              {selectedMembership === "Entrepreneur" ? (
                                isMobile || isTablet ? (
                                  <Close />
                                ) : (
                                  <span className="button__with__icon__text">
                                    {t("__general__remove__label")}
                                  </span>
                                )
                              ) : isMobile || isTablet ? (
                                <span></span>
                              ) : (
                                <span className="button__with__icon__text">
                                  {t("__general__add__label")}
                                </span>
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* END EXTRAS */}
            </div>

            <div className="floating__checkout__footer__wrapper">
              <Button
                className="button__with__icon bg__dark__pink no__left__margin"
                disabled={
                  infoCartOrder?.order?.importeii <= 0 || blockPayButton
                }
                onClick={() => {
                  if (!isOpenGiftSelectModal) {
                    setIsOpenFloatingCheckout(false);
                    history.push("/checkout");
                    // if (!isGuest && benefitType > 1) {
                    //   setIsOpenFloatingCheckout(false);
                    //   history.push("/checkout");
                    // } else {
                    //   setIsOpenFloatingCheckout(false);
                    //   history.push("/checkout");
                    // }
                  } else {
                    setIsOpenGiftsAvailableModal(true);

                    dispatch(
                      requestLoggUserActivity(
                        83,
                        0,
                        0,
                        "",
                        "Se Abre modal para la seleccion de regalos."
                      )
                    );
                  }
                }}
              >
                <div className="button__with__icon__svg__wrapper dark__pink__ball">
                  <BagWhiteIcon />
                </div>
                <span className="button__with__icon__text">
                  {t("btn_cart_text_pay2")}
                </span>
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      )}

      <CenteredModal
        open={isOpenRemoveMembershipCPModalConfirmation}
        title={t(
          "__floating__checkout__modal__remove__special__shopper__membership__title"
        )}
        message={t(
          "__floating__checkout__modal__remove__special__shopper__membership__body"
        )}
        handleClose={() => {
          setIsOpenRemoveMembershipCPModalConfirmation(false);
        }}
        handleConfirm={() => {
          history.push("/login");
          setIsOpenRemoveMembershipCPModalConfirmation(false);
        }}
        handleCancel={() => {
          setSelectedMembership("");
          setIsOpenRemoveMembershipCPModalConfirmation(false);
        }}
        alternateCancelLabel={t("__floating__checkout__remove__membership")}
        alternateConfirmLabel={t("btn_log_in")}
      />
      <CenteredModal
        open={isOpenRemoveMembershipEntrepreneurModalConfirmation}
        title={t(
          "__floating__checkout__modal__remove__entrepreneur__membership__title"
        )}
        message={t(
          "__floating__checkout__modal__remove__special__shopper__membership__body"
        )}
        handleClose={() => {
          setIsOpenRemoveMembershipEntrepreneurModalConfirmation(false);
        }}
        handleConfirm={() => {
          history.push("/login");
          setIsOpenRemoveMembershipEntrepreneurModalConfirmation(false);
        }}
        handleCancel={() => {
          setSelectedMembership("");
          setIsOpenRemoveMembershipEntrepreneurModalConfirmation(false);
        }}
        alternateCancelLabel={t("__floating__checkout__remove__membership")}
        alternateConfirmLabel={t("btn_log_in")}
      />

      <CenteredModal
        open={isOpenGiftsAvailableModal}
        title={t("__general__congratulations")}
        //message={"Tienes 6 regalos por los productos que estas comprando"}
        message={`${t("__gifts__subtitle__part1")} ${countGiftSelect} ${t(
          "__general__gift"
        )}${6 > 1 ? "s" : ""} ${t("__gifts__modal__message")}`}
        handleClose={() => {
          setIsOpenGiftsAvailableModal(false);
        }}
        handleConfirm={() => {
          history.push("/gifts");
          setIsOpenGiftsAvailableModal(false);
          setIsOpenFloatingCheckout(false);
        }}
        alternateConfirmLabel={t("__gifts__modal__button__text")}
      />

      <CenteredModal
        open={isOpenPersonalizedFragrancesIncrementWarningModal}
        title={t(
          "__floating__checkout__modal__add__other__personalized__fragrance__title"
        )}
        message={t(
          "__floating__checkout__modal__add__other__personalized__fragrance__subtitle"
        )}
        handleClose={() => {
          setIsOpenPersonalizedFragrancesIncrementWarningModal(false);
        }}
        handleCancel={() => {
          setIsOpenPersonalizedFragrancesIncrementWarningModal(false);
        }}
        handleConfirm={() => {
          if (
            personalizedFragranceTypeLine === "N" ||
            personalizedFragranceTypeLine === "PD" ||
            personalizedFragranceTypeLine === "P"
          )
            history.push(
              `/personalized-fragance/it/${personalizedFragranceToIncrementCode}`
            );
          else
            history.push(
              `/kit-config/${personalizedFragranceToIncrementCode}/personalizedFragranceTypeLine`
            );

          setIsOpenPersonalizedFragrancesIncrementWarningModal(false);
        }}
      />
    </Fragment>
  );
};

export default FloatingCheckout;

import React, { useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
//MUI
import { Skeleton } from "@mui/material";
//Assets
import { ReactComponent as ZermatLogoBlack } from "../../../../assets/media/images/zermatLogoBlack.svg";
import { ReactComponent as PrevArrowLanguageSwitcher } from "../../../../assets/media/images/icons/arrow_red_left.svg";
import { ReactComponent as NextArrowLanguageSwitcher } from "../../../../assets/media/images/icons/arrow_red_right.svg";

const NavbarLanding = () => {
  //React Router
  const history = useHistory();

  //Translation
  const { t, i18n } = useTranslation();

  return (
    <div className="landing__header">
      <div className="logo__zermat">
        <ZermatLogoBlack
          onClick={() => {
            let target = "";
            if (localStorage.getItem("ZUsaUrlPage")) {
              target = `/mypage/${localStorage.getItem("ZUsaUrlPage")}`;
            } else {
              target = "/home";
            }
            if (target.length > 0) {
              history.push(target);
            }
          }}
        />
      </div>
      <div className="border__black" />
      <div className="landing__language">
        <div className="language__switcher">
          <div className="prev">
            <PrevArrowLanguageSwitcher />
          </div>
          <Swiper
            id="languageSwiper"
            slidesPerView={1}
            updateOnWindowResize
            centeredSlides
            loop
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            onSlideChange={(swiper) => {
              if (swiper.realIndex === 0) {
                i18n.changeLanguage("en");
              } else if (swiper.realIndex === 1) {
                i18n.changeLanguage("es");
              }
            }}
            modules={[Navigation]}
          >
            <SwiperSlide>
              <span>
                {!i18next.isInitialized ? (
                  <Skeleton
                    variant="rect"
                    width={50}
                    height={20}
                    style={{ margin: "0 5px" }}
                  />
                ) : (
                  t("__general__navbar_language_english")
                )}
              </span>
            </SwiperSlide>
            <SwiperSlide>
              <span>
                {" "}
                {!i18next.isInitialized ? (
                  <Skeleton
                    variant="rect"
                    width={50}
                    height={20}
                    style={{ margin: "0 5px" }}
                  />
                ) : (
                  t("__general__navbar_language_spanish")
                )}
              </span>
            </SwiperSlide>
          </Swiper>

          <div className="next">
            <NextArrowLanguageSwitcher />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarLanding;

import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isMobile, isTablet } from "react-device-detect";
import { useHistory } from "react-router-dom";

//Moment
import moment from "moment";

///componets
import NavbarLanding from "./components/NavbarLanding";
import InfomationEnterprising from "./components/InfomationEnterprising";

import RegisterForm from "./components/RegisterForm";
import CenteredModal from "../base/components/CenteredModal";

// Actions
import {
  requestStatesAndCities,
  requestLandingRegistrationData,
} from "./../../ReduxSaga/Actions/General";
import { requestEntrepenourHomeModuleInfo } from "./../../ReduxSaga/Actions/Home";
import { requestClientLogoutAndChargeNewMember } from "./../../ReduxSaga/Actions/Client";

const LandingEnterprisingContainer = (props) => {
  const maxDate = moment().subtract(18, "years").add(1, "days");

  //Translation
  const { t, i18n } = useTranslation();

  //React Router
  const history = useHistory();

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.home = useSelector((state) => state.home);
  appReducers.email = useSelector((state) => state.email);
  appReducers.client = useSelector((state) => state.client);
  appReducers.twilio = useSelector((state) => state.twilio);
  appReducers.address = useSelector((state) => state.address);
  appReducers.newMember = useSelector((state) => state.newMember);

  // Hooks
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const [landingData, setLandingData] = useState({});
  const [openTermsConditons, setOpenTermsConditons] = useState(false);
  const [openValidationDataModal, setOpenValidationDataModal] = useState(false);
  const [userDataValidationErrors, setUserDataValidationErrors] = useState([]);
  const [openSuccessRegister, setOpenSuccessRegister] = useState(false);
  const [statesResult, setStatesResult] = useState([]);
  const [sponsorData, setSponsorData] = useState({});
  const [formData, setFormData] = useState({
    name: {
      value: "",
      isValid: false,
    },
    lastname: {
      value: "",
      isValid: false,
    },
    birthdate: {
      value: moment(maxDate.format("YYYY-MM-DD"), "YYYY-MM-DD"),
      isValid: true,
    },
    email: {
      value: "",
      isValid: true,
      isVerified: true,
    },
    cellPhone: {
      value: "",
      isValid: false,
      isVerified: false,
      code: "",
    },
    isShipmentAddressVerified: false,
    streetShipping: {
      value: "",
      isValid: false,
    },
    suiteShipping: {
      value: "",
      isValid: true,
    },
    stateShipping: {
      value: "",
      isValid: false,
    },
    cityShipping: {
      value: "",
      isValid: false,
    },
    zipShipping: {
      value: "",
      isValid: false,
    },
    isTermsAndConditionsAccepted: false,
    sponsor: 0,
  });

  // Efecto obtener configuracion landing
  useEffect(() => {
    document.title = "Zermat USA :: New Members";
    handleGetLandingConfig();
  }, []);

  // Efecto evaluar configuracion landing
  useEffect(() => {
    if (
      typeof appReducers?.general?.errorLoadlandingRegistrationData !==
      undefined
    ) {
      if (!appReducers?.general?.errorLoadlandingRegistrationData) {
        if (appReducers?.general?.landingRegistrationData) {
          setLandingData(appReducers?.general?.landingRegistrationData);
          dispatch(requestStatesAndCities());
        } else {
          setLandingData({});
        }
      }
    }
    return () => {
      if (
        appReducers?.general?.landingRegistrationData == null ||
        appReducers?.general?.landingRegistrationData == undefined
      ) {
        setLandingData({});
      }
    };
  }, [
    appReducers?.general?.errorLoadlandingRegistrationData,
    appReducers?.general?.landingRegistrationData,
  ]);

  // Cargamos idioma
  useEffect(() => {
    if (i18n.resolvedLanguage) {
      setIsLanguageLoaded(true);
      handleFirstCharge();
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (appReducers?.general?.firstChargeModel?.hasOwnProperty("idLang")) {
      if (appReducers?.general?.firstChargeModel?.idPage > 0) {
        dispatch(requestEntrepenourHomeModuleInfo());
      } else {
        console.error("No se encontró la página web del lider");
        localStorage.clear();
        history.replace(`/newRegistration`);
      }
    }
    return;
  }, [
    appReducers?.general?.errorLoadFirstCharge,
    appReducers?.general?.firstChargeModel,
    i18n,
  ]);

  //*** SET INFORMACION PAGINA ***//
  useEffect(() => {
    if (appReducers?.home?.entrepenourPageInfo) {
      setSponsorData(appReducers?.home?.entrepenourPageInfo);
    }

    return () => {
      if (sponsorData.hasOwnProperty("nameClient")) {
        setSponsorData({});
      }
    };
  }, [appReducers?.home?.entrepenourPageInfo]);

  //SponsorData Effect
  useEffect(() => {
    if (sponsorData.hasOwnProperty("idClient")) {
      setFormData({
        ...formData,
        sponsor: sponsorData.idClient,
      });
    }
  }, [sponsorData]);

  // Efecto para ciudades y estados
  useEffect(() => {
    if (typeof appReducers?.general?.errorLoadStatesAndCities !== "undefined") {
      if (!appReducers?.general?.errorLoadStatesAndCities) {
        if (
          appReducers?.general?.statesAndCities &&
          appReducers?.general?.statesAndCities.length > 0
        ) {
          setStatesResult(appReducers?.general?.statesAndCities);
        }
      }
    }
    return () => {
      if (statesResult?.length > 0) setStatesResult([]);
    };
  }, [
    appReducers?.general?.errorLoadStatesAndCities,
    appReducers?.general?.statesAndCities,
  ]);

  // Handlers
  const handleGetLandingConfig = () => {
    dispatch(requestLandingRegistrationData());
  };

  const handleFirstCharge = () => {
    let urlPage = "";
    if (props?.match?.params?.hasOwnProperty("userWebPage")) {
      urlPage = props.match.params.userWebPage;
    }
    if (urlPage.length <= 0 && localStorage.getItem("ZUsaUrlPage")) {
      urlPage = localStorage.getItem("ZUsaUrlPage");
      history.replace(
        `/newRegistration/${localStorage.getItem("ZUsaUrlPage")}`
      );
    }
    dispatch(requestClientLogoutAndChargeNewMember(urlPage));
  };

  return (
    <div className="landing__enterprising__container">
      <div className="landing__header">
        <NavbarLanding style={{ zIndex: 999 }} />
      </div>
      <div className="landing__content">
        <InfomationEnterprising
          isLanguageLoaded={isLanguageLoaded}
          landingData={landingData}
          sponsorData={sponsorData}
          t={t}
          i18n={i18n}
        />
        <RegisterForm
          t={t}
          i18n={i18n}
          openTermsConditons={openTermsConditons}
          setOpenTermsConditons={setOpenTermsConditons}
          setOpenValidationDataModal={setOpenValidationDataModal}
          setUserDataValidationErrors={setUserDataValidationErrors}
          setOpenSuccessRegister={setOpenSuccessRegister}
          isLanguageLoaded={isLanguageLoaded}
          statesResult={statesResult}
          formData={formData}
          setFormData={setFormData}
        />
      </div>
      <div className="landing__footer">
        <div className="landing__footer__container">
          <div className="icons">
            <span>{t("__new__members__footer__follow__zermat")}</span>

            <a target="_black" href="https://www.instagram.com/zermatus/?hl=en">
              <div className="instagramBlack" />
            </a>
            <a
              target="_black"
              href="https://www.tiktok.com/@zermatusa_official?lang=en"
            >
              {" "}
              <div className="tiktokBlack" />
            </a>
            <a target="_black" href="https://www.facebook.com/zermatusa/">
              <div className="facebookBlack" />
            </a>
            <a
              target="_black"
              href="https://www.youtube.com/channel/UCNC80gWIoSGS8tO4oU_TkCg"
            >
              {" "}
              <div className="youtubeBlack" />
            </a>
            <a target="_black" href="https://mx.pinterest.com/zermatusa/">
              <div className="pinterestBlack" />
            </a>
          </div>
        </div>
      </div>

      {/* modals terminos */}

      <CenteredModal
        open={openTermsConditons}
        title={t("__termsAndConditions_title_Txt")}
        sizeModal={"lg"}
        message={
          <div
            className={`floating__terms__and__conditions__content__main__message ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            {i18n.language === "es" ? (
              <Fragment>
                <h4>
                  Como Emprendedor(a) Independiente de
                  <span className="marcazermat">Zermat USA, Inc.</span>
                  reconozco y estoy de acuerdo en cumplir con las siguientes
                  políticas y procedimientos:
                </h4>
                <ol>
                  <li>
                    QUE SOY UN(A) EMPRENDEDOR(A) INDEPENDIENTE y no un agente,
                    socio, representante legal o empleado de
                    <span className="marcazermat">Zermat USA, Inc.</span>, o de
                    cualquiera de sus subsidiarias o filiales (en este acuerdo
                    se le llama a
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    También <strong>La Compañía</strong>). Entiendo que no
                    recibiré trato de empleado para efectos de impuestos
                    federales o estatales.
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    no se hace responsable por la retención fiscal, ni deberá
                    retener o deducir ningún impuesto sobre la nómina de las
                    comisiones o bonificaciones recibidas producto de mi
                    desempeño; a menos que dicha retención fiscal sea requerida
                    u ordenada por parte de las autoridades federales o
                    estatales.
                  </li>
                  <li>
                    No haré declaraciones ni me comprometeré o participaré en
                    contratos legales o vinculantes a nombre de
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    No estoy autorizado(a) para contraer deudas, gastos u
                    obligaciones; ni abrir ninguna cuenta bancaria con el nombre
                    de <strong>La Compañía</strong>. Además, como Empresario(a)
                    Independiente de
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    ,me comprometo a no patrocinar, apoyar, administrar o a usar
                    ningún sitio Web a menos que
                    <strong>La Compañía</strong> lo autorice previamente.
                  </li>
                  <li>
                    Estoy de acuerdo en que soy la única persona responsable por
                    el pago de todos los gastos efectuados por mi persona; que
                    incluyen pero no se limitan a gastos de viaje, comida,
                    alojamiento, gastos administrativos, de oficina, de larga
                    distancia, telefónicos y cualquier otro gasto.
                  </li>
                  <li>
                    Me comprometo a representar de manera profesional los
                    productos, servicios y planes de compensación de
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    Entiendo que debo controlar la manera y medios de proceder
                    como Empresario(a) Independiente de
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    No expresaré los posibles ingresos que se podrían obtener
                    más allá de lo establecido en los documentos oficiales de{" "}
                    <strong>La Compañía</strong>. No puedo usar, producir,
                    crear, publicar, distribuir ni obtener folletos, productos
                    de audio, nombres registrados, marcas de servicio o
                    logotipos de ninguna fuente que no sea la de
                    <span className="marcazermat">Zermat USA, Inc.</span>
                  </li>
                  <li>
                    Me comprometo a cumplir con las políticas de compra y pago
                    que
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    establece. En el caso remoto de que llegara a incurrir en
                    cualquier obligación financiera con
                    <strong>La Compañía</strong>, autorizo por la presente a
                    <span className="marcazermat">Zermat USA, Inc.</span>, a
                    aplicar cualquier comisión o bonificación que yo pudiera
                    llegar a recibir a través de mi desempeño como Empresario(a)
                    Independiente, como pago de dichas obligaciones. Además,
                    estoy de acuerdo en que
                    <span className="marcazermat">Zermat USA, Inc.</span>, no
                    enviará ni procesará ninguna orden de venta hasta que se
                    resuelva cualquier y todos los saldos pendientes.
                  </li>
                  <li>
                    Reconozco que el sistema y modelo de ventas de
                    <span className="marcazermat">Zermat USA, Inc.</span>, en
                    los Estados Unidos se encuentra limitado de forma exclusiva
                    para actividades de negocio Multinivel; y por la presente me
                    comprometo, como Emprendedor(a) Independiente, a vender los
                    productos de
                    <strong>La Compañía</strong> directamente al consumidor
                    final , y no venderé, exhibiré, ni promocionaré sus
                    productos en establecimientos comerciales de ningún tipo, a
                    menos que
                    <span className="marcazermat">Zermat USA, Inc.</span>, lo
                    autorice previamente.
                  </li>
                  <li>
                    Estoy de acuerdo en que todos los materiales promocionales,
                    como materiales impresos o paquetes de prueba, son para
                    propósitos promocionales exclusivamente, para las
                    actividades del (de la) Emprendedor(a) Independiente de
                    <span className="marcazermat">Zermat USA, Inc.</span>, y no
                    se pueden revender al público en general.
                  </li>
                  <li>
                    El plazo de este acuerdo es de un (1) año a partir de la
                    fecha de su firma, se renovará cada año en forma automática
                    en la fecha de aniversario y permanecerá en vigor hasta que:
                    <ol>
                      <li>
                        Yo cancele este Acuerdo con o si causa cualquier
                        momento, al enviar un aviso por escrito a
                        <span className="marcazermat">Zermat USA, Inc.</span>,
                        con mi firma original, nombre en letra de molde,
                        dirección y número de identificación de Emprendedor(a).
                        <span className="marcazermat">Zermat USA, Inc.</span>,
                        se reserva el derecho de aceptar y procesar mi
                        re-ingreso a <strong>La Compañía</strong> (favor de
                        consultar la cláusula descrita del Manual de Políticas y
                        Procedimientos).
                      </li>
                      <li>
                        <span className="marcazermat">Zermat USA, Inc.</span>,
                        cancele este acuerdo con o sin causa en cualquier
                        momento, tras el envío de un aviso por escrito a la
                        ultima dirección proporcionada por mi a{" "}
                        <strong>La Compañía</strong>. Entiendo y estoy de
                        acuerdo que
                        <span className="marcazermat">Zermat USA, Inc.</span>,
                        lleve a cabo este trámite en cualquier momento y a su
                        entera discreción.
                      </li>
                      <li>
                        En caso de cancelación por parte de cualquiera de las
                        partes, estoy de acuerdo en renunciar a cualquier y a
                        todos los derechos derivados de este Acuerdo; que
                        incluyen pero no se limitan a derechos de propiedad, a
                        mi antigua organización de línea descendente y a
                        cualquier bonificación, comisión o cualquier otro tipo
                        de remuneración derivado de las ventas y otras
                        actividades de mi antigua organización de línea
                        descendente.
                      </li>
                    </ol>
                  </li>
                  <li>
                    He leído cuidadosamente este documento y al firmarlo, me
                    comprometo a cumplir con el Manual de Políticas y
                    Procedimientos de
                    <span className="marcazermat">Zermat USA, Inc.</span>; el
                    cual, en combinación con el Plan de Compensación, está
                    incorporado implícitamente y forma parte de estos Términos y
                    Condiciones. Además, entiendo que este Acuerdo, el Plan de
                    Compensación y el Manual de Políticas y Procedimientos
                    pueden ser modificados ocasionalmente, y estoy de acuerdo en
                    que dichas modificaciones tendrán validez una vez que
                    <span className="marcazermat">Zermat USA, Inc.</span>, las
                    haga públicas, y a su vez, sustituirán cualquier Acuerdo
                    existente con
                    <span className="marcazermat">Zermat USA, Inc.</span>
                  </li>
                  <li>
                    No puedo asignar ningún derecho ni delegar mis funciones
                    bajo este Acuerdo sin el consentimiento previo y por escrito
                    de
                    <span className="marcazermat">Zermat USA, Inc.</span>, dicha
                    acción le otorgará el derecho a<strong>La Compañía</strong>{" "}
                    de terminar este convenio.
                  </li>
                  <li>
                    Reconozco que
                    <span className="marcazermat">Zermat USA, Inc.</span>,
                    respeta mi privacidad y que no venderá, compartirá,
                    distribuirá, o facilitará ninguna información proporcionada
                    por mi persona en esta solicitud.
                  </li>
                  <li>
                    Con mi firma en el presente Acuerdo, autorizo a
                    <span className="marcazermat">Zermat USA, Inc.</span>, a
                    usar y publicar mi nombre en materiales promocionales,
                    ocasionalmente y como lo considere apropiado.
                  </li>
                  <li>
                    Entiendo que si dejo de cumplir con cualquiera de los
                    términos y condiciones de este Acuerdo,
                    <span className="marcazermat">Zermat USA, Inc.</span>,
                    podría, a su entera discreción, terminar el convenio o
                    imponer acciones disciplinarias correctivas; que incluyen
                    pero no se limitan a, la confiscación de bonificaciones o
                    comisiones, pérdida de toda o parte de mi organización de
                    línea descendente.
                  </li>
                  <li>
                    Hasta donde lo permite la ley, y de no ser resueltos por
                    mutuo acuerdo, todos los reclamos o disputas de cualquier
                    naturaleza entre los (las) actuales o antiguos(as)
                    Emprendedores(as) Independientes y
                    <span className="marcazermat">Zermat USA, Inc.</span>, sus
                    agentes, socios o empleados, deberán resolverse por
                    arbitraje vinculante a través de un árbitro o árbitros
                    mutuamente acordados por las partes. De no haber acuerdo de
                    ninguna de las parte después del arbitraje los reclamos o
                    disputas podrán ser expuestas bajo las leyes y
                    jurisdicciones del condado de Harris en el Estado de Texas.
                    Hasta donde permite la ley, la parte favorecida en cualquier
                    procedimiento (ya sea con arbitraje vinculante o en corte o
                    de cualquier otra forma entre el Emprendedor(a)
                    Independiente y
                    <span className="marcazermat">Zermat USA, Inc.</span>, sus
                    agentes, socios o empleados; Deberá ser excluida de todos
                    los gastos deabogados y cortes de cualquiera de las partes.
                  </li>
                  <li>
                    Restricciones para envíos Aéreos y terrestres por el
                    Departamento de transporte (DOT). Ciertos productos son
                    considerados como peligrosos por el DOT esto incluye:
                    aerosoles, productos que contienen alcohol, como pueden ser
                    de manera enunciativa más no limitativa los siguientes:
                    Desodorantes o aromatizantes en Aerosol, Perfumes, Esmaltes
                    de uñas, todas las muestras de fragancias y/o perfumes,
                    etc., Todos estos productos tienen restricciones y
                    regulaciones de envío por lo cual sólo serán enviados de
                    manera terrestre. En cumplimiento con las regulaciones del
                    DOT para él envió de productos restringidos por vía
                    terrestre, “Zermat” exclusivamente realizará envíos dentro
                    de 48 Estados contiguos más Washington DC, cumpliendo con
                    las regulaciones de etiquetado, empaque y marcas requeridas.
                  </li>
                </ol>
              </Fragment>
            ) : (
              <Fragment>
                <h4>
                  As an Independent Beauty Consultant of{" "}
                  <span className="marcazermat">Zermat USA, Inc.</span> I
                  acknowledge and agree to abide the following polices and
                  procedures:
                </h4>
                <ol type="A">
                  <li>
                    {" "}
                    THAT I AMAN INDEPENDENT BEAUTY CONSULTANT and not an agent,
                    partner, legal representative, or employee of{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>, or
                    any of its subsidiaries or aliates (here onto as{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>, or
                    The Company). I understand that I will not be treated as an
                    employee for Federal or State tax purposes.{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>, is
                    not responsible for withholding, and shall not with hold nor
                    deduct any payroll related taxes from commissions or bonuses
                    earned from my activities; unless such withholding is
                    required and instructed by federal or state authorities.
                  </li>
                  <li>
                    {" "}
                    I will make no statements or promises or engage in legal
                    and/or binding contracts in the name of{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span> I am
                    not authorized and I will not incur in any debt, expenses,
                    obligations, or open any bank account with the name of The
                    Company. I acknowledge I am not authorized to utilize the
                    name of “Zermat” for shipping purposes (I can’t declare
                    “Zermat” as shipper with services such as FedEx, UPS, USPS
                    or any other shipping service provider under any
                    circumstance). Likewise, I will not berepresented as{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span> in any
                    media channel (social media, printed advertising or media
                    articles, television, radio, Internet, etc.)
                  </li>
                  <li>
                    {" "}
                    I agree that I will be solely responsible for paying all
                    expenses related to the sales and promotion of Zermat
                    products and its business model incurred by me, including
                    but not limited to travel, food, lodging, secretarial,
                    office, long distance, telephone and any other expenses.
                  </li>
                  <li>
                    {" "}
                    I agree to represent{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    ’s products, services and their compensation plan in a
                    professional manner. I understand that I should control the
                    form and means by which I operate as Zermat’s Independent
                    Consultant. I will make no claims about potential income
                    earnings beyond what is stated in Zermat’s ocial literature.
                    I maynot use, produce, create, publish, distribute or obtain
                    literature, audios, trade names, service marks, and logos
                    from any source other than{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>
                  </li>
                  <li>
                    {" "}
                    I agree to comply with the purchase and payment polices
                    established by{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span> In the
                    remote event that any financial obligation is created with
                    the company, I hereby authorize{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span> to
                    apply any commissions or bonuses I may have earned through
                    my activities as Independent Consultant as payment of such
                    obligations. I further agree that{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>, will
                    not ship nor process any sales orders until any and all
                    outstanding balances are resolved.
                  </li>
                  <li>
                    {" "}
                    I acknowledge that{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    ’s sales system and model in the United States is limited
                    primarily for multilevel activities, and hereby agree as an
                    Independent Consultant; to sell The Company’s products
                    directly to the end consumer, and will not sell, exhibit, or
                    promote it’s products in commercial establishments of any
                    kind unless previously authorized by{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span> I also
                    accept not to use the Zermat artist brands as my own, and I
                    will not sell its products over the Internetunless sold
                    through my ocial Zermat webpage.
                  </li>
                  <li>
                    {" "}
                    I agree that all sales aids such as printed material or
                    trial packs are exclusively for promotional purposes of the
                    Independent Consultant activities for{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>, and
                    not for resell to the general public.
                  </li>
                  <li>
                    The term of this Agreement is one year from the date signed;
                    it will automatically renew annually on each anniversary
                    date and will remain in eect until:
                    <ol>
                      <li>
                        {" "}
                        I cancel this Agreement for any reason at any time by
                        giving written notice to{" "}
                        <span className="marcazermat">Zermat USA, Inc.</span>,
                        bearing my original signature, printed name, address and
                        consultant ID number.{" "}
                        <span className="marcazermat">Zermat USA, Inc.</span>,
                        reserves the right to accept the process of
                        re-enlistment or any form of re-application, please
                        refer to{" "}
                        <span className="marcazermat">Zermat USA, Inc.</span>{" "}
                        Polices and Procedures Manual.
                      </li>
                      <li>
                        {" "}
                        <span className="marcazermat">Zermat USA, Inc.</span>,
                        cancels this Agreement upon written notice sent to my
                        address set forth herein. I hereby agree that{" "}
                        <span className="marcazermat">Zermat USA, Inc.</span>,
                        may execute this action at any time upon its sole
                        discretion.
                      </li>
                      <li>
                        {" "}
                        In the event of cancellation by any of the parties, I
                        agree to waive any and all rights derived from this
                        agreement, including but not limited to property rights,
                        to my former down line organization and to any bonuses,
                        commission or other remunerations derived through the
                        sales and other activities of my former down line
                        organization.
                      </li>
                    </ol>
                  </li>
                  <li>
                    {" "}
                    I have carefully read and upon signing of this document
                    agree to comply with{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>
                    ’s Policies and Procedures Manual, which in combination with
                    the Compensation Plan, are implicitly incorporated into and
                    made part of these Terms and Conditions. I further
                    understand that this Agreement, the Compensation Plan, and
                    the Polices and Procedures Manual can be amended from time
                    to time, and agree that such amendments will be valid once
                    made public by{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span> and
                    will in turn supersede any previous agreements with{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>
                  </li>
                  <li>
                    {" "}
                    I may not assign any rights or delegate my duties under this
                    Agreement without the prior written consent of{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>, such
                    action entitles The Company to terminate this agreement.
                  </li>
                  <li>
                    {" "}
                    I acknowledge that{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>{" "}
                    respects my privacy and will not sell, share, distribute, or
                    make available any of the information provided by me in this
                    application.
                  </li>
                  <li>
                    {" "}
                    By the execution of this Agreement I hereby authorize{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span> to use
                    and/or publish my name in promotional materials, as it may
                    deem appropriate from time to time.
                  </li>
                  <li>
                    {" "}
                    I understand that if I fail to comply with any terms and
                    conditions of this Agreement,{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span> may,
                    at its discretion, terminate the agreement or impose
                    corrective disciplinary actions, including but not limited
                    to forfeiture of bonuses or commissions, loss of all or part
                    of my down line organization.
                  </li>
                  <li>
                    {" "}
                    I acknowledge and accept imposition of various sanctions
                    that Zermat may apply in case of incompliance of policies,
                    procedures, and standard business conducts wich may be
                    administrative and / or financial. Those penalties are
                    published in the Business Manual. To the extent permitted by
                    law, all claims or disputes of any nature between current or
                    former Independent Consultant, with{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>, its
                    ocers, share-holders or employees, if not resolved by mutual
                    agreement, shall be resolved by binding arbitration using an
                    arbitrator or arbitrators mutually agreeable to the parties.
                    If the parties are unable to agree upon arbitration,
                    disputes may be settled legally under the laws and
                    jurisdiction of Harris County in the State of Texas. To the
                    extent permitted by law, the prevailing party in any
                    proceeding (Whether in arbitration or court, or otherwise)
                    between an Independent Consultant and{" "}
                    <span className="marcazermat">Zermat USA, Inc.</span>, its
                    ocers, shareholders or employees; shall be entitled to an
                    award of attorney’s fees and court costs.
                  </li>
                  <li>
                    {" "}
                    DOT restrictions on Ground and Air Shipping. Certain items
                    considered hazardous materials by DOT including aerosols and
                    alcohol-based products (e.g., Aerosol deodorants, Nail
                    polish, Fragrances, Perfumes, sample of Fragrances or
                    Perfumes samples, etc.) are restricted and required to be
                    shipped by standard, ground-only transport, due to air
                    transport restrictions and regulations. In compliance with
                    DOT regulations restricted items require ground-only
                    delivery to the 48 contiguous States plus Washington DC;
                    with the proper packaging, shipping marks and labeling.
                  </li>
                </ol>
              </Fragment>
            )}
          </div>
        }
        handleClose={() => {
          setOpenTermsConditons(false);
        }}
        isConfirmDisabled={false}
      />
      {/* modal de success */}
      <CenteredModal
        open={openSuccessRegister}
        title={t("__login_Create_Account_Registration_Success_Title")}
        message={t("__login_Create_Account_Registration_Success_Message")}
        handleClose={() => {
          setOpenSuccessRegister(false);
        }}
        handleConfirm={() => {
          setOpenSuccessRegister(false);
          history.replace("/home");
        }}
        alternateConfirmLabel={t("btn_text_accept")}
      />
      {/* modal de error */}
      <CenteredModal
        open={openValidationDataModal}
        title={t("__centered_modal_validation_title")}
        message={
          <ul className="centered__modal__content__list__warning">
            {userDataValidationErrors.length > 0 &&
              userDataValidationErrors.map((error, index) => (
                <li key={index}>{t(error.validationDescription)}</li>
              ))}
          </ul>
        }
        handleClose={() => {
          setOpenValidationDataModal(false);
        }}
      />
    </div>
  );
};

export default LandingEnterprisingContainer;

import React from "react";
import { useTranslation } from "react-i18next";
//MUI
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Skeleton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const CenteredModal = ({
  open,
  title,
  message,
  children,
  handleClose,
  handleCancel,
  handleConfirm,
  alternateConfirmLabel,
  alternateCancelLabel,
  alternateCancelColor,
  alternateConfirmColor,
  isCancelDisabled,
  isConfirmDisabled,
  sizeModal,
}) => {
  //Translation
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      maxWidth={sizeModal ? sizeModal : "xs"}
      fullWidth
      PaperProps={{
        style: {
          overflow: "visible",
          borderRadius: 25,
        },
      }}
    >
      <DialogContent>
        <div className="centered__modal__content">
          <div className="centered__modal__content__close__button__wrapper">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <span className="centered__modal__content__title">{title}</span>
          {message && <p>{message}</p>}

          {children && children}
        </div>
      </DialogContent>
      <DialogActions>
        <div
          className={`centered__modal__actions ${
            !handleCancel ? "centered" : ""
          }`}
        >
          {handleCancel && (
            <>
              {isCancelDisabled ? (
                <Skeleton
                  variant="rect"
                  width={"50%"}
                  height={30}
                  style={{ marginRight: 10 }}
                />
              ) : (
                <Button disabled={isCancelDisabled} onClick={handleCancel}>
                  <label
                    style={{
                      color: alternateCancelColor
                        ? alternateCancelColor
                        : "unset",
                    }}
                  >
                    {alternateCancelLabel
                      ? alternateCancelLabel
                      : t("btn_text_cancel")}
                  </label>
                </Button>
              )}
            </>
          )}
          {handleConfirm && (
            <>
              {isConfirmDisabled ? (
                <Skeleton
                  variant="rect"
                  width={"50%"}
                  height={30}
                  style={{ marginLeft: 10 }}
                />
              ) : (
                <Button
                  disabled={isConfirmDisabled}
                  className="centered__modal__actions__confirm"
                  onClick={handleConfirm}
                >
                  <label
                    style={{
                      color: alternateConfirmColor
                        ? alternateConfirmColor
                        : "unset",
                    }}
                  >
                    {alternateConfirmLabel
                      ? alternateConfirmLabel
                      : t("btn_text_accept")}
                  </label>
                </Button>
              )}
            </>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CenteredModal;

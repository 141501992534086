import React, { useEffect, useState, Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { isMobile, isTablet, isMobileOnly } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
//MUI
import {
  Button,
  Checkbox,
  Collapse,
  IconButton,
  ListItem,
  Skeleton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
//Components
// import ButtonComponent from "../../base/components/ButtonComponent";
// import BreadcrumbsComponet from "../../base/components/BreadcrumbsComponet";
// import ItemProduct from "./ItemProduct";
import NewProductCard from "../../base/components/NewProductCard";
import NotFoundComponent from "./../../base/components/NotFoundComponent";
//import BreadcrumbsNavigationComponet from "../../navigation/components/BreadcrumbsNavigationComponet";
import {
  handleScrollToTop,
  enumCategories,
  capitalizeText,
} from "./../../../../Libraries/Utils/utils";
import Enumerable from "linq";
//Actions
import { requestLoggUserActivity } from "./../../../ReduxSaga/Actions/General";
//Assets
import { ReactComponent as FilterWhiteIcon } from "./../../../../assets/media/images/icons/filterWhiteIcon.svg";
import { ReactComponent as OrderIcon } from "./../../../../assets/media/images/icons/orderFilterWhiteIcon.svg";
import { ReactComponent as CollapseTriangleOrangeIcon } from "./../../../../assets/media/images/icons/collapseTriangleOrangeIcon.svg";
import { ReactComponent as SearchWhiteIcon } from "./../../../../assets/media/images/icons/searchIconAlt.svg";

import { ReactComponent as FiltersCheckboxActive } from "./../../../../assets/media/images/icons/filtersCheckboxActive.svg";
import { ReactComponent as FiltersCheckboxUnactive } from "./../../../../assets/media/images/icons/filtersCheckboxUnactive.svg";
import { ReactComponent as FiltersClearIcon } from "./../../../../assets/media/images/icons/filtersClearWhiteIcon.svg";
import { ReactComponent as TripleArrowLeftIcon } from "./../../../../assets/media/images/icons/tripleArrowLeftIcon.svg";
import { ReactComponent as TripleArrowRightIcon } from "./../../../../assets/media/images/icons/tripleArrowRightIcon.svg";

const ListProductsContainer = (props) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();
  /// Navigation
  let params = useParams();
  /// TRANSLATION
  const { t, i18n } = useTranslation();
  /// REDUX
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.products = useSelector((state) => state.products);
  appReducers.cart = useSelector((state) => state.cart);

  ///HOOKS

  const [collapseFilter, setCollapseFilter] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryTypeId, setcategoryTypeId] = useState(0);
  const [categoryTree, setCategoryTree] = useState("");
  const [subCategoriesL1Data, setSubCategoriesL1Data] = useState([]);
  const [subCategoriesL2Data, setSubCategoriesL2Data] = useState([]);
  const [subCategoriesL3Data, setSubCategoriesL3Data] = useState([]);
  const [subCategoriesL4Data, setSubCategoriesL4Data] = useState([]);
  //New
  const [isOpenFilterMenu, setIsOpenFilterMenu] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [isVisibleOrderMenuOptions, setIsVisibleOrderMenuOptions] =
    useState(false);
  //Pagination
  const [itemsPerPage, setItemsPerPAge] = useState(12);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  //Order
  const [currentOrderOption, setCurrentOrderOption] = useState(undefined);
  ///
  const [isBannerPromo, setIsBannerPromo] = useState(true);
  const [categorySelected, setCategorySelected] = useState({});
  const [filterOptionsGrouped, setFilterOptionsGrouped] = useState([]);
  const [showNoResultFilter, setShowNoResultFilter] = useState(false);

  const handleCollapse = () => {
    setCollapseFilter(!collapseFilter);
  };

  //scrollTop

  useEffect(() => {
    document.title = "Zermat USA :: Product Categories";    

    //Set filters
    // const filtersFromServer = fakeFiltersOptions;
    // let filterOptionsResult = [];
    // filtersFromServer.forEach((a) => {
    //   let itemsClone = [];
    //   a.items.forEach((b) => {
    //     itemsClone.push({ ...b, isActive: false });
    //   });
    //   filterOptionsResult.push({
    //     ...a,
    //     isExpanded: true,
    //     items: itemsClone,
    //   });
    // });
    // setFilterOptions(filterOptionsResult);
  }, []);

  useEffect(() => {
    if (!isOpenFilterMenu) {
      const cloneFilterOptions = [...filterOptions];
      cloneFilterOptions.forEach((item) => {
        item.isExpanded = false;
      });
      //console.log("cloneFilterOptions", cloneFilterOptions);
      setFilterOptions(cloneFilterOptions);
    }
  }, [isOpenFilterMenu]);

  useEffect(() => {
    //document.getElementById("products__list").scrollIntoView();
    //console.log("filter products", filteredProducts);
    if (filteredProducts.length > 0) {
      handleScrollToTop();
      const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
      //console.log("totalPages", totalPages);
      setNumberOfPages(totalPages);
      setCurrentPage(0);
      //setIsOpenFilterMenu(false); PIDE JM NO SE OCULTE EL DIV DE FILTROS CUANDO SE FILTRA POR CATEGORÍA
    }
  }, [filteredProducts, itemsPerPage]);

  useEffect(() => {
    handleScrollToTop();
    const swiperProductsListPagination = document.getElementById(
      "swiperProductsListPagination"
    );
    if (swiperProductsListPagination) {
      //console.log("swiperProductsListPagination", swiperProductsListPagination);
      swiperProductsListPagination.swiper.slideTo(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    //console.log("filterOptions", filterOptions);
  }, [filterOptions]);

  //PRODUCTS LOAD
  useEffect(() => {
    ///props?.hasOwnProperty("match") &&
    if (params && params?.hasOwnProperty("categoryId")) {
      const categoryId = parseInt(params?.categoryId);
      let catTitle = "";
      let categoryTypeId = 0;
      if (
        !appReducers?.products?.errorLoadTrPrExProducts &&
        appReducers?.products?.productsEn &&
        appReducers?.products?.categoriesEn &&
        appReducers?.products?.subCategoriesEnL1
      ) {
        setCategoryTree("");
        if (i18n.language === "en") {
          if (categoryId > 0) {
            const catSelected = enumCategories.find((i) => i.id === categoryId);
            setCategorySelected(catSelected);
            categoryTypeId = appReducers?.products?.categoriesEn?.find(
              (c) => c.idCategories === categoryId
            )?.idCategoriesType;
            catTitle = appReducers?.products?.categoriesEn?.find(
              (c) => c.idCategoriesType === categoryTypeId
            )?.nameCategories;
            //setCategoryTitle(catTitle);
            setCategoryTitle(t(catSelected.name));
            setcategoryTypeId(categoryTypeId);
            setProductsData(appReducers?.products?.productsEn);
            setCategoriesData(appReducers?.products?.categoriesEn);

            const subCatL1 = appReducers?.products?.subCategoriesEnL1?.filter(
              (s1) => s1.idCategoriesType === categoryTypeId
            );
            setSubCategoriesL1Data(subCatL1);
            const subCatL1IdList = appReducers?.products?.subCategoriesEnL1
              .filter((s1) => s1.idCategoriesType === categoryTypeId)
              .map((i) => i.idSubCategories);
            const subCatL2 = appReducers?.products?.subCategoriesEnL2?.filter(
              (s2) => subCatL1IdList.includes(s2.isSubCategoryLevel1)
            );
            setSubCategoriesL2Data(subCatL2);
            const subCatL2IdList = subCatL2.map((i) => i.idSubCategories);
            const subCatL3 = appReducers?.products?.subCategoriesEnL3?.filter(
              (s3) => subCatL2IdList.includes(s3.isSubCategoryLevel2)
            );
            setSubCategoriesL3Data(subCatL3);
            const subCatL4 = appReducers?.products?.subCategoriesEnL4?.filter(
              (l4) => l4.idCategoriesType === categoryTypeId
            );
            setSubCategoriesL4Data(subCatL4);
            handleSetFilters(
              appReducers?.products?.categoriesEn,
              appReducers?.products?.productsEn,
              catSelected,
              subCatL1,
              subCatL2,
              subCatL3,
              subCatL4
            );
            handleSetFiltersGrouped(
              appReducers?.products?.categoriesEn,
              appReducers?.products?.productsEn,
              catSelected,
              subCatL1,
              subCatL2,
              subCatL3,
              subCatL4
            );
          } else {
            setProductsData(appReducers?.products?.productsEn);
            setCategoriesData(appReducers?.products?.categoriesEn);
            setSubCategoriesL1Data(appReducers?.products?.subCategoriesEnL1);
            setSubCategoriesL2Data(appReducers?.products?.subCategoriesEnL2);
            setSubCategoriesL3Data(appReducers?.products?.subCategoriesEnL3);
            setSubCategoriesL4Data(appReducers?.products?.subCategoriesEnL4);
            handleSetFilters(
              appReducers?.products?.categoriesEn,
              appReducers?.products?.productsEn,
              {},
              appReducers?.products?.subCategoriesEnL1,
              appReducers?.products?.subCategoriesEnL2,
              appReducers?.products?.subCategoriesEnL3,
              appReducers?.products?.subCategoriesEnL4,
              true
            );
            handleSetFiltersGrouped(
              appReducers?.products?.categoriesEn,
              appReducers?.products?.productsEn,
              {},
              appReducers?.products?.subCategoriesEnL1,
              appReducers?.products?.subCategoriesEnL2,
              appReducers?.products?.subCategoriesEnL3,
              appReducers?.products?.subCategoriesEnL4,
              true
            );
          }
        }
        if (i18n.language === "es") {
          if (categoryId > 0) {
            const catSelected = enumCategories.find((i) => i.id === categoryId);
            setCategorySelected(catSelected);
            // categoryTypeId = categoryId
            categoryTypeId = appReducers?.products?.categoriesEs?.find(
              (c) => c.idCategories === categoryId
            )?.idCategoriesType;
            catTitle = appReducers?.products?.categoriesEs?.find(
              (c) => c.idCategoriesType === categoryTypeId
            )?.nameCategories;
            setCategoryTitle(t(catSelected.name));
            //setCategoryTitle(catTitle);
            setcategoryTypeId(categoryTypeId);
            setProductsData(appReducers?.products?.productsEs);
            setCategoriesData(appReducers?.products?.categoriesEs);
            const subCatL1 = appReducers?.products?.subCategoriesEsL1?.filter(
              (s1) => s1.idCategoriesType === categoryTypeId
            );
            setSubCategoriesL1Data(subCatL1);
            const subCatL1IdList = appReducers?.products?.subCategoriesEsL1
              .filter((s1) => s1.idCategoriesType === categoryTypeId)
              .map((i) => i.idSubCategories);
            const subCatL2 = appReducers?.products?.subCategoriesEsL2?.filter(
              (s2) => subCatL1IdList.includes(s2.isSubCategoryLevel1)
            );
            setSubCategoriesL2Data(subCatL2);
            const subCatL2IdList = subCatL2.map((i) => i.idSubCategories);
            const subCatL3 = appReducers?.products?.subCategoriesEsL3?.filter(
              (s3) => subCatL2IdList.includes(s3.isSubCategoryLevel2)
            );
            setSubCategoriesL3Data(subCatL3);
            const subCatL4 = appReducers?.products?.subCategoriesEsL4?.filter(
              (l4) => l4.idCategoriesType === categoryTypeId
            );
            setSubCategoriesL4Data(subCatL4);
            handleSetFilters(
              appReducers?.products?.categoriesEs,
              appReducers?.products?.productsEs,
              catSelected,
              subCatL1,
              subCatL2,
              subCatL3,
              subCatL4
            );
            handleSetFiltersGrouped(
              appReducers?.products?.categoriesEs,
              appReducers?.products?.productsEs,
              catSelected,
              subCatL1,
              subCatL2,
              subCatL3,
              subCatL4
            );
          } else {
            setProductsData(appReducers?.products?.productsEs);
            setCategoriesData(appReducers?.products?.categoriesEs);
            setSubCategoriesL1Data(appReducers?.products?.subCategoriesEsL1);
            setSubCategoriesL2Data(appReducers?.products?.subCategoriesEsL2);
            setSubCategoriesL3Data(appReducers?.products?.subCategoriesEsL3);
            setSubCategoriesL4Data(appReducers?.products?.subCategoriesEsL4);
            handleSetFilters(
              appReducers?.products?.categoriesEs,
              appReducers?.products?.productsEs,
              {},
              appReducers?.products?.subCategoriesEsL1,
              appReducers?.products?.subCategoriesEsL2,
              appReducers?.products?.subCategoriesEsL3,
              appReducers?.products?.subCategoriesEsL4,
              true
            );
            handleSetFiltersGrouped(
              appReducers?.products?.categoriesEs,
              appReducers?.products?.productsEs,
              {},
              appReducers?.products?.subCategoriesEsL1,
              appReducers?.products?.subCategoriesEsL2,
              appReducers?.products?.subCategoriesEsL3,
              appReducers?.products?.subCategoriesEsL4,
              true
            );
          }
        }
      } else {
        //error no cargo los productos
      }
    } else {
      //error espera parametro
    }
  }, [
    params?.categoryId,
    appReducers?.products?.errorLoadTrPrExProducts,
    i18n.language,
    appReducers?.products?.productsEs,
    appReducers?.products?.productsEn,
    appReducers?.products?.subCategoriesEnL1,
    appReducers?.products?.subCategoriesEnL2,
    appReducers?.products?.subCategoriesEnL3,
    appReducers?.products?.subCategoriesEsL1,
    appReducers?.products?.subCategoriesEsL2,
    appReducers?.products?.subCategoriesEsL3,
    appReducers?.products?.categoriesEs,
    appReducers?.products?.categoriesEn,
  ]);

  //Log
  useEffect(() => {
    //console.log("productsData", productsData);
    if (productsData.length > 0) {
      //debugger;
      if (categoryTypeId > 0) {
        // console.log("categoryTypeId", categoryTypeId);
        // console.log("props.benefitType", props.benefitType);
        //debugger
        if (props.benefitType === 2 || props.benefitType === 1) {
          const productsResult = productsData.filter(
            (p) =>
              p.idFamily <= 0 &&
              p.idCatProductsCategoriesType === categoryTypeId
          );
          const withFamilyProducts = [];
          const prodEvaWithFamily = productsData.filter(
            (p) =>
              p.idFamily > 0 &&
              p.available &&
              p.idCatProductsCategoriesType === categoryTypeId
          );

          prodEvaWithFamily.forEach((item) => {
            if (
              !withFamilyProducts.find(
                (elemnt) => elemnt.idFamily === item.idFamily
              )
            ) {
              prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

              const articleFinal = prodEvaWithFamily.find(
                (a) => a.itemCode === item.itemCode
              );
              withFamilyProducts.push(articleFinal);
              productsResult.push(articleFinal);
            }
          });

          setFilteredProducts(productsResult);
        } else {
          const productsResult = productsData.filter(
            (p) =>
              p.idFamily <= 0 &&
              p.idCatProductsCategoriesType === categoryTypeId &&
              p.typePlan === props.benefitType
          );
          const withFamilyProducts = [];
          const prodEvaWithFamily = productsData.filter(
            (p) =>
              p.idFamily > 0 &&
              p.available &&
              p.idCatProductsCategoriesType === categoryTypeId &&
              p.typePlan === props.benefitType
          );

          prodEvaWithFamily.forEach((item) => {
            if (
              !withFamilyProducts.find(
                (elemnt) => elemnt.idFamily === item.idFamily
              )
            ) {
              prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

              const articleFinal = prodEvaWithFamily.find(
                (a) => a.itemCode === item.itemCode
              );
              withFamilyProducts.push(articleFinal);
              productsResult.push(articleFinal);
            }
          });
          setFilteredProducts(productsResult);
        }
      } else {
        if (props.benefitType === 2 || props.benefitType === 1) {
          const productsResult = productsData.filter((p) => p.idFamily <= 0);
          const withFamilyProducts = [];
          const prodEvaWithFamily = productsData.filter(
            (p) => p.idFamily > 0 && p.available
          );

          prodEvaWithFamily.forEach((item) => {
            if (
              !withFamilyProducts.find(
                (elemnt) => elemnt.idFamily === item.idFamily
              )
            ) {
              prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

              const articleFinal = prodEvaWithFamily.find(
                (a) => a.itemCode === item.itemCode
              );
              withFamilyProducts.push(articleFinal);
              productsResult.push(articleFinal);
            }
          });
          setFilteredProducts(productsResult);
        } else {
          const productsResult = productsData.filter(
            (p) => p.idFamily <= 0 && p.typePlan === props.benefitType
          );
          const withFamilyProducts = [];
          const prodEvaWithFamily = productsData.filter(
            (p) =>
              p.idFamily > 0 && p.available && p.typePlan === props.benefitType
          );

          prodEvaWithFamily.forEach((item) => {
            if (
              !withFamilyProducts.find(
                (elemnt) => elemnt.idFamily === item.idFamily
              )
            ) {
              prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

              const articleFinal = prodEvaWithFamily.find(
                (a) => a.itemCode === item.itemCode
              );
              withFamilyProducts.push(articleFinal);
              productsResult.push(articleFinal);
            }
          });
          setFilteredProducts(productsResult);
        }
      }
    }
  }, [productsData, categoryTypeId, props.benefitType]);

  useEffect(() => {
    if (parseInt(params?.categoryId) === 7) {
      setIsBannerPromo(true);
    } else {
      setIsBannerPromo(false);
    }
  }, [params]);

  //Handlers
  const handleSetFilters = (
    categories,
    products,
    categoriesEnum,
    subCategoriesL1 = [],
    subCategoriesL2 = [],
    subCategoriesL3 = [],
    subCategoriesL4 = [],
    withCategories = false,
    catIdParam = 0,
    subCatLevel1Param = 0,
    subCatLevel2Param = 0,
    subCatLevel3Param = 0,
    subCatLevel4Param = 0
  ) => {
    const filters = [];
    let level1Name = "SubNivel1";
    let level2Name = "SubNivel2";
    let level3Name = "SubNivel3";
    let level4Name = "SubNivel4";
    if (
      typeof categoriesEnum !== "undefined" &&
      categoriesEnum.hasOwnProperty("id")
    ) {
      categoriesEnum.levels.forEach((item) => {
        switch (item.level) {
          case 1:
            level1Name = t(item.name);
            break;
          case 2:
            level2Name = t(item.name);
            break;
          case 3:
            level3Name = t(item.name);
            break;
          case 4:
            level4Name = t(item.name);
            break;
        }
      });
    }

    const categoriesItems = categories
      .filter(
        (cat) =>
          products.filter(
            (p) => p.idCatProductsCategoriesType === cat.idCategoriesType
          ).length > 0
      )
      .map((cat, index) => ({
        idCatProductsCategories: cat.idCategoriesType,
        isCat: true,
        islevel1: false,
        isLevel2: false,
        isLevel3: false,
        isLevel4: false,
        isActive: cat.idCategoriesType === catIdParam,
        name: cat.nameCategories,
        count: products.filter(
          (p) => p.idCatProductsCategoriesType === cat.idCategoriesType
        ).length,
        index,
      }));
    const subCatL1Items = subCategoriesL1
      .filter(
        (l1) =>
          products.filter(
            (p) =>
              p.idCatProductsCategoriesType === l1.idCategoriesType &&
              p.subCat1 === l1.idSubCategories
          ).length > 0
      )
      .map((l1, index) => ({
        idCatProductsCategories: l1.idSubCategories,
        isCat: false,
        idCategoriesType: l1.idCategoriesType,
        islevel1: true,
        islevel2: false,
        islevel3: false,
        islevel4: false,
        isActive: l1.idSubCategories === subCatLevel1Param,
        name: l1.nameSubCategories,
        count: products.filter(
          (p) =>
            p.idCatProductsCategoriesType === l1.idCategoriesType &&
            p.subCat1 === l1.idSubCategories
        ).length,
        index,
      }));
    const subCatL2Items = subCategoriesL2
      .filter(
        (l2) =>
          products.filter(
            (p) =>
              p.subCat1 === l2.isSubCategoryLevel1 &&
              p.subCat2 === l2.idSubCategories
          ).length > 0
      )
      .map((l2, index) => ({
        idCatProductsCategories: l2.idSubCategories,
        isCat: false,
        islevel1: false,
        idSubCatL1: l2.isSubCategoryLevel1,
        islevel2: true,
        islevel3: false,
        islevel4: false,
        isActive: l2.idSubCategories === subCatLevel2Param,
        name: l2.nameSubCategories,
        count: products.filter(
          (p) =>
            p.subCat1 === l2.isSubCategoryLevel1 &&
            p.subCat2 === l2.idSubCategories
        ).length,
        index,
      }));
    const subCatL3Items = subCategoriesL3
      .filter(
        (l3) =>
          products.filter(
            (p) =>
              p.subCat1 === l3.idSubCategoryLevel1 &&
              p.subCat2 === l3.isSubCategoryLevel2 &&
              p.subCat3 === l3.idSubCategories
          ).length > 0
      )
      .map((l3, index) => ({
        idCatProductsCategories: l3.idSubCategories,
        isCat: false,
        islevel1: false,
        islevel2: false,
        idSubCatL1: l3.idSubCategoryLevel1,
        idSubCatL2: l3.isSubCategoryLevel2,
        islevel3: true,
        islevel4: false,
        isActive: l3.idSubCategories === subCatLevel3Param,
        name: l3.nameSubCategories,
        count: products.filter(
          (p) =>
            p.subCat1 === l3.idSubCategoryLevel1 &&
            p.subCat2 === l3.isSubCategoryLevel2 &&
            p.subCat3 === l3.idSubCategories
        ).length,
        index,
      }));
    const subCatL4Items = subCategoriesL4
      .filter(
        (l4) =>
          products.filter(
            (p) =>
              p.idCatProductsCategoriesType === l4.idCategoriesType &&
              p.subCat4 === l4.idSubCategories
          ).length > 0
      )
      .map((l4, index) => ({
        idCatProductsCategories: l4.idSubCategories,
        isCat: false,
        islevel1: false,
        islevel2: false,
        islevel3: false,
        idCategoriesType: l4.idCategoriesType,
        islevel4: true,
        isActive: l4.idSubCategories === subCatLevel4Param,
        name: l4.nameSubCategories,
        count: products.filter(
          (p) =>
            p.idCatProductsCategoriesType === l4.idCategoriesType &&
            p.subCat4 === l4.idSubCategories
        ).length,
        index,
      }));
    const objCategories = {
      title: i18n.language === "es" ? "Catégorias" : "Categories",
      isExpanded: true,
      items: categoriesItems,
    };
    const objSubCategoriesLevel1 = {
      title: level1Name,
      isExpanded: false,
      items: subCatL1Items,
    };
    const objSubCategoriesLevel2 = {
      title: level2Name,
      isExpanded: false,
      items: subCatL2Items,
    };
    const objSubCategoriesLevel3 = {
      title: level3Name,
      isExpanded: false,
      items: subCatL3Items,
    };
    const objSubCategoriesLevel4 = {
      title: level4Name,
      isExpanded: false,
      items: subCatL4Items,
    };
    if (withCategories) filters.push(objCategories);
    filters.push(objSubCategoriesLevel1);
    filters.push(objSubCategoriesLevel2);
    filters.push(objSubCategoriesLevel3);
    filters.push(objSubCategoriesLevel4);
    setFilterOptions(filters);
  };
  const handleSetFiltersGrouped = (
    categories,
    products,
    categoriesEnum,
    subCategoriesL1 = [],
    subCategoriesL2 = [],
    subCategoriesL3 = [],
    subCategoriesL4 = [],
    withCategories = false,
    catIdParam = 0,
    subCatLevel1Param = 0,
    subCatLevel2Param = 0,
    subCatLevel3Param = 0,
    subCatLevel4Param = 0
  ) => {
    //nombre de subcategorias por nivel
    const filters = [];
    let level1Name = "SubNivel1";
    let level2Name = "SubNivel2";
    let level3Name = "SubNivel3";
    let level4Name = "SubNivel4";
    if (
      typeof categoriesEnum !== "undefined" &&
      categoriesEnum.hasOwnProperty("id")
    ) {
      categoriesEnum.levels.forEach((item) => {
        switch (item.level) {
          case 1:
            level1Name = t(item.name);
            break;
          case 2:
            level2Name = t(item.name);
            break;
          case 3:
            level3Name = t(item.name);
            break;
          case 4:
            level4Name = t(item.name);
            break;
        }
      });
    }
    //nombre de subcategorias por nivel

    const categoriesItems = categories
      .filter(
        (cat) =>
          products.filter(
            (p) => p.idCatProductsCategoriesType === cat.idCategoriesType
          ).length > 0
      )
      .map((cat, index) => ({
        idCatProductsCategories: cat.idCategoriesType,
        isCat: true,
        islevel1: false,
        isLevel2: false,
        isLevel3: false,
        isLevel4: false,
        isActive: cat.idCategoriesType === catIdParam,
        name: cat.nameCategories,
        count: products.filter(
          (p) => p.idCatProductsCategoriesType === cat.idCategoriesType
        ).length,
        index,
      }));

    const groupCategories = Enumerable.from(categoriesItems)
      .groupBy("item => item.name", null, function (key, group) {
        const count = group.sum("$.count");
        const category = { ...group.toArray()[0] };
        delete category.idCatProductsCategories;
        delete category.idSubCatL1;
        category.count = count;
        return {
          category,
          index: category.index,
          isActive: false,
          catUpGrouped: group.toArray(),
        };
      })
      .toArray();

    const subCatL1Items = subCategoriesL1
      .filter(
        (l1) =>
          products.filter(
            (p) =>
              p.idCatProductsCategoriesType === l1.idCategoriesType &&
              p.subCat1 === l1.idSubCategories
          ).length > 0
      )
      .map((l1, index) => ({
        idCatProductsCategories: l1.idSubCategories,
        isCat: false,
        idCategoriesType: l1.idCategoriesType,
        islevel1: true,
        islevel2: false,
        islevel3: false,
        islevel4: false,
        isActive: l1.idSubCategories === subCatLevel1Param,
        name: l1.nameSubCategories,
        count: products.filter(
          (p) =>
            p.idCatProductsCategoriesType === l1.idCategoriesType &&
            p.subCat1 === l1.idSubCategories
        ).length,
        index,
      }));

    const groupCatL1 = Enumerable.from(subCatL1Items)
      .groupBy("item => item.name", null, function (key, group) {
        const count = group.sum("$.count");
        const category = { ...group.toArray()[0] };
        delete category.idCatProductsCategories;
        delete category.idSubCatL1;
        category.count = count;
        return {
          category,
          index: category.index,
          isActive: false,
          catUpGrouped: group.toArray(),
        };
      })
      .toArray();

    const subCatL2Items = subCategoriesL2
      .filter(
        (l2) =>
          products.filter(
            (p) =>
              p.subCat1 === l2.isSubCategoryLevel1 &&
              p.subCat2 === l2.idSubCategories
          ).length > 0
      )
      .map((l2, index) => ({
        idCatProductsCategories: l2.idSubCategories,
        isCat: false,
        islevel1: false,
        idSubCatL1: l2.isSubCategoryLevel1,
        islevel2: true,
        islevel3: false,
        islevel4: false,
        isActive: l2.idSubCategories === subCatLevel2Param,
        name: l2.nameSubCategories,
        count: products.filter(
          (p) =>
            p.subCat1 === l2.isSubCategoryLevel1 &&
            p.subCat2 === l2.idSubCategories
        ).length,
        index,
      }));

    const groupCatL2 = Enumerable.from(subCatL2Items)
      .groupBy("item => item.name", null, function (key, group) {
        const count = group.sum("$.count");
        const category = { ...group.toArray()[0] };
        delete category.idCatProductsCategories;
        delete category.idSubCatL1;
        category.count = count;
        return {
          category,
          index: category.index,
          isActive: false,
          catUpGrouped: group.toArray(),
        };
      })
      .toArray();

    const subCatL3Items = subCategoriesL3
      .filter(
        (l3) =>
          products.filter(
            (p) =>
              p.subCat1 === l3.idSubCategoryLevel1 &&
              p.subCat2 === l3.isSubCategoryLevel2 &&
              p.subCat3 === l3.idSubCategories
          ).length > 0
      )
      .map((l3, index) => ({
        idCatProductsCategories: l3.idSubCategories,
        isCat: false,
        islevel1: false,
        islevel2: false,
        idSubCatL1: l3.idSubCategoryLevel1,
        idSubCatL2: l3.isSubCategoryLevel2,
        islevel3: true,
        islevel4: false,
        isActive: l3.idSubCategories === subCatLevel3Param,
        name: l3.nameSubCategories,
        count: products.filter(
          (p) =>
            p.subCat1 === l3.idSubCategoryLevel1 &&
            p.subCat2 === l3.isSubCategoryLevel2 &&
            p.subCat3 === l3.idSubCategories
        ).length,
        index,
      }));

    const groupCatL3 = Enumerable.from(subCatL3Items)
      .groupBy("item => item.name", null, function (key, group) {
        const count = group.sum("$.count");
        const category = { ...group.toArray()[0] };
        delete category.idCatProductsCategories;
        delete category.idSubCatL1;
        category.count = count;
        return {
          category,
          index: category.index,
          isActive: false,
          catUpGrouped: group.toArray(),
        };
      })
      .toArray();

    const subCatL4Items = subCategoriesL4
      .filter(
        (l4) =>
          products.filter(
            (p) =>
              p.idCatProductsCategoriesType === l4.idCategoriesType &&
              p.subCat4 === l4.idSubCategories
          ).length > 0
      )
      .map((l4, index) => ({
        idCatProductsCategories: l4.idSubCategories,
        isCat: false,
        islevel1: false,
        islevel2: false,
        islevel3: false,
        idCategoriesType: l4.idCategoriesType,
        islevel4: true,
        isActive: l4.idSubCategories === subCatLevel4Param,
        name: l4.nameSubCategories,
        count: products.filter(
          (p) =>
            p.idCatProductsCategoriesType === l4.idCategoriesType &&
            p.subCat4 === l4.idSubCategories
        ).length,
        index,
      }));

    const groupCatL4 = Enumerable.from(subCatL4Items)
      .groupBy("item => item.name", null, function (key, group) {
        const count = group.sum("$.count");
        const category = { ...group.toArray()[0] };
        delete category.idCatProductsCategories;
        delete category.idSubCatL1;
        category.count = count;
        return {
          category,
          index: category.index,
          isActive: false,
          catUpGrouped: group.toArray(),
        };
      })
      .toArray();

    const objCategories = {
      title: i18n.language === "es" ? "Catégorias" : "Categories",
      isExpanded: true,
      items: groupCategories,
    };
    const objSubCategoriesLevel1 = {
      title: level1Name,
      isExpanded: false,
      items: groupCatL1,
    };
    const objSubCategoriesLevel2 = {
      title: level2Name,
      isExpanded: false,
      items: groupCatL2,
    };
    const objSubCategoriesLevel3 = {
      title: level3Name,
      isExpanded: false,
      items: groupCatL3,
    };
    const objSubCategoriesLevel4 = {
      title: level4Name,
      isExpanded: false,
      items: groupCatL4,
    };
    if (withCategories) filters.push(objCategories);
    filters.push(objSubCategoriesLevel1);
    filters.push(objSubCategoriesLevel2);
    filters.push(objSubCategoriesLevel3);
    filters.push(objSubCategoriesLevel4);

    setFilterOptionsGrouped(filters);
  };
  const handleClearAllFilters = () => {
    let filterOptionsResult = [];
    //const clone = [...filterOptions];
    const clone = [...filterOptionsGrouped];
    clone.forEach((a) => {
      let itemsClone = [];
      a.items.forEach((b) => {
        itemsClone.push({ ...b, isActive: false });
      });
      filterOptionsResult.push({
        ...a,
        items: itemsClone,
      });
    });
    //setFilterOptions(filterOptionsResult);
    setFilterOptionsGrouped(filterOptionsResult);
    setCategoryTree("");
    if (showNoResultFilter) setShowNoResultFilter(false);
    // if (categoryTypeId > 0) {
    //   if (props.benefitType === 2 || props.benefitType === 1)
    //     setFilteredProducts(
    //       productsData.filter(
    //         (p) => p.idCatProductsCategoriesType === categoryTypeId
    //       )
    //     );
    //   else
    //     setFilteredProducts(
    //       productsData.filter(
    //         (p) =>
    //           p.idCatProductsCategoriesType === categoryTypeId &&
    //           p.typePlan === props.benefitType
    //       )
    //     );
    // } else {
    //   if (props.benefitType === 2 || props.benefitType === 1)
    //     setFilteredProducts(productsData);
    //   else
    //     setFilteredProducts(
    //       productsData.filter((p) => p.typePlan === props.benefitType)
    //     );
    // }
    if (categoryTypeId > 0) {
      // console.log("categoryTypeId", categoryTypeId);
      // console.log("props.benefitType", props.benefitType);
      //debugger
      if (props.benefitType === 2 || props.benefitType === 1) {
        const productsResult = productsData.filter(
          (p) =>
            p.idFamily <= 0 && p.idCatProductsCategoriesType === categoryTypeId
        );
        const withFamilyProducts = [];
        const prodEvaWithFamily = productsData.filter(
          (p) =>
            p.idFamily > 0 &&
            p.available &&
            p.idCatProductsCategoriesType === categoryTypeId
        );

        prodEvaWithFamily.forEach((item) => {
          if (
            !withFamilyProducts.find(
              (elemnt) => elemnt.idFamily === item.idFamily
            )
          ) {
            prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

            const articleFinal = prodEvaWithFamily.find(
              (a) => a.itemCode === item.itemCode
            );
            withFamilyProducts.push(articleFinal);
            productsResult.push(articleFinal);
          }
        });

        setFilteredProducts(productsResult);
      } else {
        const productsResult = productsData.filter(
          (p) =>
            p.idFamily <= 0 &&
            p.idCatProductsCategoriesType === categoryTypeId &&
            p.typePlan === props.benefitType
        );
        const withFamilyProducts = [];
        const prodEvaWithFamily = productsData.filter(
          (p) =>
            p.idFamily > 0 &&
            p.available &&
            p.idCatProductsCategoriesType === categoryTypeId &&
            p.typePlan === props.benefitType
        );

        prodEvaWithFamily.forEach((item) => {
          if (
            !withFamilyProducts.find(
              (elemnt) => elemnt.idFamily === item.idFamily
            )
          ) {
            prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

            const articleFinal = prodEvaWithFamily.find(
              (a) => a.itemCode === item.itemCode
            );
            withFamilyProducts.push(articleFinal);
            productsResult.push(articleFinal);
          }
        });
        setFilteredProducts(productsResult);
      }
    } else {
      if (props.benefitType === 2 || props.benefitType === 1) {
        const productsResult = productsData.filter((p) => p.idFamily <= 0);
        const withFamilyProducts = [];
        const prodEvaWithFamily = productsData.filter(
          (p) => p.idFamily > 0 && p.available
        );

        prodEvaWithFamily.forEach((item) => {
          if (
            !withFamilyProducts.find(
              (elemnt) => elemnt.idFamily === item.idFamily
            )
          ) {
            prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

            const articleFinal = prodEvaWithFamily.find(
              (a) => a.itemCode === item.itemCode
            );
            withFamilyProducts.push(articleFinal);
            productsResult.push(articleFinal);
          }
        });
        setFilteredProducts(productsResult);
      } else {
        const productsResult = productsData.filter(
          (p) => p.idFamily <= 0 && p.typePlan === props.benefitType
        );
        const withFamilyProducts = [];
        const prodEvaWithFamily = productsData.filter(
          (p) =>
            p.idFamily > 0 && p.available && p.typePlan === props.benefitType
        );

        prodEvaWithFamily.forEach((item) => {
          if (
            !withFamilyProducts.find(
              (elemnt) => elemnt.idFamily === item.idFamily
            )
          ) {
            prodEvaWithFamily.sort((a, b) => a.orderRender - b.orderRender);

            const articleFinal = prodEvaWithFamily.find(
              (a) => a.itemCode === item.itemCode
            );
            withFamilyProducts.push(articleFinal);
            productsResult.push(articleFinal);
          }
        });
        setFilteredProducts(productsResult);
      }
    }
    //handleScrollToTop();
    setCurrentPage(0);
  };

  return (
    <Fragment>
      {isBannerPromo && (
        <div
          className={`products__list__container__banner ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          <Fragment>
            <img
              alt="promotions"
              src={
                i18n.language === "es"
                  ? isMobileOnly
                    ? "https://www.websizusa.net/imgprod/zu/modules/1/1/1/Home_slider6_sm_es.jpg"
                    : "https://www.websizusa.net/imgprod/zu/modules/1/1/3/Home_slider6_lg_es.jpg"
                  : isMobileOnly
                  ? "https://www.websizusa.net/imgprod/zu/modules/1/2/1/Home_slider6_sm_en.jpg"
                  : "https://www.websizusa.net/imgprod/zu/modules/1/2/3/Home_slider6_lg_en.jpg"
              }
            />
          </Fragment>
        </div>
      )}

      <div
        id="products__list"
        className="firstWrapperInBaseContainer products__list"
      >
        <div
          className={`products__list__filters__wrapper ${
            isMobile || isTablet ? "mobile" : ""
          } ${isOpenFilterMenu ? "active" : ""}`}
        >
          <div className="products__list__filters__wrapper__header">
            <div
              className={`products__list__filters__wrapper__header__title ${
                props.isLanguageLoaded ? "" : "skeletonActive"
              }`}
            >
              <FilterWhiteIcon />
              <span
                className={`${props.isLanguageLoaded ? "" : "skeletonActive"}`}
              >
                {props.isLanguageLoaded ? (
                  t("__general__filters")
                ) : (
                  <Skeleton variant="rect" width={"60%"} height={30} />
                )}
              </span>
            </div>
            <div className="products__list__filters__wrapper__header__close__wrapper">
              <IconButton
                onClick={() => {
                  setIsOpenFilterMenu(false);
                }}
              >
                <Close />
              </IconButton>
            </div>
          </div>

          <div className="products__list__filters__wrapper__body">
            {/* filterOptions */}
            {filterOptionsGrouped //filterOptions
              .filter((fo) => fo?.items?.length > 0)
              .map((item, index) => {
                //console.log("item", item);
                return (
                  <div
                    key={index}
                    className="products__list__filters__wrapper__body__filter__section"
                  >
                    <div
                      className="products__list__filters__wrapper__body__filter__section__title"
                      onClick={() => {
                        const foundIndex = filterOptionsGrouped.findIndex(
                          (element) => element.title === item.title
                        );
                        //console.log("foundIndex", foundIndex);
                        const clone = [...filterOptionsGrouped];
                        clone[foundIndex] = {
                          ...clone[foundIndex],
                          isExpanded: !clone[foundIndex].isExpanded,
                        };
                        //console.log("clone", clone);
                        //setFilterOptions(clone)
                        setFilterOptionsGrouped(clone);
                      }}
                    >
                      <span>{item.title}</span>
                      <CollapseTriangleOrangeIcon
                        className={`${!item.isExpanded ? "is__collapsed" : ""}`}
                        // onClick={() => {
                        //   const foundIndex = filterOptions.findIndex(
                        //     (element) => element.title === item.title
                        //   );
                        //   //console.log("foundIndex", foundIndex);
                        //   const clone = [...filterOptions];
                        //   clone[foundIndex] = {
                        //     ...clone[foundIndex],
                        //     isExpanded: !clone[foundIndex].isExpanded,
                        //   };
                        //   //console.log("clone", clone);
                        //   setFilterOptions(clone);
                        // }}
                      />
                    </div>
                    <Collapse in={item.isExpanded}>
                      <div className="products__list__filters__wrapper__body__filter__section__filters">
                        {item.items.map((itemElement, indexElement) => {
                          return (
                            <div
                              key={indexElement}
                              className="products__list__filters__wrapper__body__filter__section__filters__item"
                            >
                              {/* <span>{`${capitalizeText(itemElement.name)} (${itemElement.count} productos)`}</span> */}
                              <span>{`${capitalizeText(
                                itemElement.category.name
                              )} (${
                                itemElement.category.count
                              } productos)`}</span>
                              <Checkbox
                                checkedIcon={<FiltersCheckboxActive />}
                                icon={<FiltersCheckboxUnactive />}
                                checked={itemElement.isActive}
                                onChange={() => {
                                  // const foundIndex = filterOptions.findIndex(
                                  //   (element) => element.title === item.title
                                  // );
                                  const foundIndex =
                                    filterOptionsGrouped.findIndex(
                                      (element) => element.title === item.title
                                    );
                                  //console.log("1er nivel", foundIndex);
                                  //const clone = [...filterOptions];
                                  const clone = [...filterOptionsGrouped];
                                  const items = clone[foundIndex].items;
                                  const foundItemIndex = items.findIndex(
                                    (element) =>
                                      element.index === itemElement.index
                                  );
                                  //console.log("2do nivel", foundItemIndex);

                                  clone[foundIndex].items[foundItemIndex] = {
                                    ...clone[foundIndex].items[foundItemIndex],
                                    isActive:
                                      !clone[foundIndex].items[foundItemIndex]
                                        .isActive,
                                  };
                                  //setFilterOptions(clone);
                                  setFilterOptionsGrouped(clone);
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </Collapse>
                  </div>
                );
              })}
            {/* filterOptions */}
          </div>

          <div className="products__list__filters__wrapper__footer">
            <ListItem
              button
              className="products__list__filters__wrapper__footer__button__with__icon"
              onClick={() => {
                //Clear all checkboxes
                handleClearAllFilters();
              }}
            >
              <div className="products__list__filters__wrapper__footer__button__with__icon__svg__wrapper remove__filters">
                <FiltersClearIcon />
              </div>
              <span>
                {props.isLanguageLoaded ? (
                  t("__general__remove__filters")
                ) : (
                  <Skeleton variant="rect" width={50} height={15} />
                )}
              </span>
            </ListItem>
            <ListItem
              button
              className="products__list__filters__wrapper__footer__button__with__icon"
              onClick={() => {
                let resultsArray = [];
                let arrayCatTree = [];
                // filterOptions.forEach((filterOption) => {
                //   const isActive = filterOption.items.filter(
                //     (filterOptionItem) => filterOptionItem.isActive
                //   );

                //   isActive.forEach((activeItem) => {
                //     if (activeItem?.idCatProductsCategories) {
                //       resultsArray.push(activeItem.idCatProductsCategories);
                //       arrayCatTree.push(activeItem);
                //     }
                //   });
                // });
                filterOptionsGrouped.forEach((filterOptionGrouped) => {
                  const isActive = filterOptionGrouped.items.filter(
                    (filterOptionGroupedItem) =>
                      filterOptionGroupedItem.isActive
                  );

                  isActive.forEach((activeItem) => {
                    if (activeItem?.hasOwnProperty("category")) {
                      resultsArray.push.apply(
                        resultsArray,
                        activeItem.catUpGrouped
                      );
                      arrayCatTree.push(activeItem.category);
                    }
                  });
                });
                if (arrayCatTree.length > 0) {
                  let catTree = "";
                  const lengthIsCat =
                    arrayCatTree.filter((i) => i.isCat).length - 1;
                  const lengthIsLevel1 =
                    arrayCatTree.filter((i) => i.islevel1).length - 1;
                  const lengthIsLevel2 =
                    arrayCatTree.filter((i) => i.islevel2).length - 1;
                  const lengthIsLevel3 =
                    arrayCatTree.filter((i) => i.islevel3).length - 1;
                  const lengthIsLevel4 =
                    arrayCatTree.filter((i) => i.islevel4).length - 1;
                  arrayCatTree
                    .filter((i) => i.isCat)
                    .forEach((i, index) => {
                      catTree +=
                        lengthIsCat > index ? `${i.name} -` : ` ${i.name} >`;
                    });
                  arrayCatTree
                    .filter((i) => i.islevel1)
                    .forEach((i, index) => {
                      catTree +=
                        lengthIsLevel1 > index ? `${i.name} -` : ` ${i.name} >`;
                    });
                  arrayCatTree
                    .filter((i) => i.islevel2)
                    .forEach((i, index) => {
                      catTree +=
                        lengthIsLevel2 > index
                          ? ` ${i.name} -`
                          : ` ${i.name} >`;
                    });
                  arrayCatTree
                    .filter((i) => i.islevel3)
                    .forEach((i, index) => {
                      catTree +=
                        lengthIsLevel3 > index
                          ? ` ${i.name} -`
                          : ` ${i.name} >`;
                    });
                  arrayCatTree
                    .filter((i) => i.islevel4)
                    .forEach((i, index) => {
                      catTree +=
                        lengthIsLevel4 > index
                          ? ` ${i.name} -`
                          : ` ${i.name} >`;
                    });

                  setCategoryTree(catTree.substring(0, catTree.length - 1));
                  //idCategoriesType
                  //idSubCatL1
                  //idSubCatL2
                  //idSubCatL3

                  const productsPrefiltered = productsData.filter(
                    (p) => p.idFamily <= 0
                  );
                  const withFamilyProducts = [];
                  const prodEvaWithFamily = productsData.filter(
                    (p) => p.idFamily > 0 && p.available
                  );

                  prodEvaWithFamily.forEach((item) => {
                    if (
                      !withFamilyProducts.find(
                        (elemnt) => elemnt.idFamily === item.idFamily
                      )
                    ) {
                      prodEvaWithFamily.sort(
                        (a, b) => a.orderRender - b.orderRender
                      );

                      const articleFinal = prodEvaWithFamily.find(
                        (a) => a.itemCode === item.itemCode
                      );
                      withFamilyProducts.push(articleFinal);
                      productsPrefiltered.push(articleFinal);
                    }
                  });

                  // const filteredProductsResult = productsPrefiltered.filter(
                  //   (p) =>
                  //     arrayCatTree.find(
                  //       (a) =>
                  //         a.isCat &&
                  //         a.idCatProductsCategories ===
                  //           p.idCatProductsCategoriesType
                  //     ) ||
                  //     arrayCatTree.find(
                  //       (a) =>
                  //         a.islevel1 &&
                  //         a.idCategoriesType ===
                  //           p.idCatProductsCategoriesType &&
                  //         a.idCatProductsCategories === p.subCat1
                  //     ) ||
                  //     arrayCatTree.find(
                  //       (a) =>
                  //         a.islevel2 &&
                  //         a.idSubCatL1 === p.subCat1 &&
                  //         a.idCatProductsCategories === p.subCat2
                  //     ) ||
                  //     arrayCatTree.find(
                  //       (a) =>
                  //         a.islevel3 &&
                  //         a.idSubCatL1 === p.subCat1 &&
                  //         a.idSubCatL2 === p.subCat2 &&
                  //         a.idCatProductsCategories === p.subCat3
                  //     ) ||
                  //     arrayCatTree.find(
                  //       (a) =>
                  //         a.islevel4 &&
                  //         a.idCategoriesType ===
                  //           p.idCatProductsCategoriesType &&
                  //         a.idCatProductsCategories === p.subCat4
                  //     )
                  // );

                  const filteredProductsResult = productsPrefiltered.filter(
                    (p) => {
                      const lengthCat = resultsArray.filter(
                        (a) => a.isCat
                      ).length;
                      const lengthCatL1 = resultsArray.filter(
                        (a) => a.islevel1
                      ).length;
                      const lengthCatL2 = resultsArray.filter(
                        (a) => a.islevel2
                      ).length;
                      const lengthCatL3 = resultsArray.filter(
                        (a) => a.islevel3
                      ).length;
                      const lengthCatL4 = resultsArray.filter(
                        (a) => a.islevel4
                      ).length;

                      if (lengthCat > 0) {
                        if (lengthCatL1 > 0) {
                          //include L1
                          if (lengthCatL2 > 0) {
                            if (lengthCatL3 > 0) {
                              if (lengthCatL4 > 0) {
                                //cat, l1, l2, l3, l4
                                resultsArray.find(
                                  (a) =>
                                    a.isCat &&
                                    a.idCatProductsCategories ===
                                      p.idCatProductsCategoriesType
                                ) &&
                                  resultsArray.find(
                                    (a) =>
                                      a.islevel1 &&
                                      a.idCategoriesType ===
                                        p.idCatProductsCategoriesType &&
                                      a.idCatProductsCategories === p.subCat1
                                  ) &&
                                  resultsArray.find(
                                    (a) =>
                                      a.islevel2 &&
                                      a.idSubCatL1 === p.subCat1 &&
                                      a.idCatProductsCategories === p.subCat2
                                  ) &&
                                  resultsArray.find(
                                    (a) =>
                                      a.islevel3 &&
                                      a.idSubCatL1 === p.subCat1 &&
                                      a.idSubCatL2 === p.subCat2 &&
                                      a.idCatProductsCategories === p.subCat3
                                  ) &&
                                  resultsArray.find(
                                    (a) =>
                                      a.islevel4 &&
                                      a.idCategoriesType ===
                                        p.idCatProductsCategoriesType &&
                                      a.idCatProductsCategories === p.subCat4
                                  );
                              } else {
                                //cat, l1, l2, l3
                                resultsArray.find(
                                  (a) =>
                                    a.isCat &&
                                    a.idCatProductsCategories ===
                                      p.idCatProductsCategoriesType
                                ) &&
                                  resultsArray.find(
                                    (a) =>
                                      a.islevel1 &&
                                      a.idCategoriesType ===
                                        p.idCatProductsCategoriesType &&
                                      a.idCatProductsCategories === p.subCat1
                                  ) &&
                                  resultsArray.find(
                                    (a) =>
                                      a.islevel2 &&
                                      a.idSubCatL1 === p.subCat1 &&
                                      a.idCatProductsCategories === p.subCat2
                                  ) &&
                                  resultsArray.find(
                                    (a) =>
                                      a.islevel3 &&
                                      a.idSubCatL1 === p.subCat1 &&
                                      a.idSubCatL2 === p.subCat2 &&
                                      a.idCatProductsCategories === p.subCat3
                                  );
                              }
                            } else if (lengthCatL4 > 0) {
                              //cat, l1, l2, l4
                              return (
                                resultsArray.find(
                                  (a) =>
                                    a.isCat &&
                                    a.idCatProductsCategories ===
                                      p.idCatProductsCategoriesType
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel1 &&
                                    a.idCategoriesType ===
                                      p.idCatProductsCategoriesType &&
                                    a.idCatProductsCategories === p.subCat1
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel2 &&
                                    a.idSubCatL1 === p.subCat1 &&
                                    a.idCatProductsCategories === p.subCat2
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel4 &&
                                    a.idCategoriesType ===
                                      p.idCatProductsCategoriesType &&
                                    a.idCatProductsCategories === p.subCat4
                                )
                              );
                            } else {
                              //cat, l1, l2
                              return (
                                resultsArray.find(
                                  (a) =>
                                    a.isCat &&
                                    a.idCatProductsCategories ===
                                      p.idCatProductsCategoriesType
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel1 &&
                                    a.idCategoriesType ===
                                      p.idCatProductsCategoriesType &&
                                    a.idCatProductsCategories === p.subCat1
                                ) &&
                                resultsArray.filter(
                                  (a) =>
                                    a.islevel2 &&
                                    a.idSubCatL1 === p.subCat1 &&
                                    a.idCatProductsCategories === p.subCat2
                                )
                              );
                            }
                          } else if (lengthCatL3 > 0) {
                            if (lengthCatL4 > 0) {
                              //cat, l1, l3, l4
                              return (
                                resultsArray.find(
                                  (a) =>
                                    a.isCat &&
                                    a.idCatProductsCategories ===
                                      p.idCatProductsCategoriesType
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel1 &&
                                    a.idCategoriesType ===
                                      p.idCatProductsCategoriesType &&
                                    a.idCatProductsCategories === p.subCat1
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel3 &&
                                    a.idSubCatL1 === p.subCat1 &&
                                    a.idSubCatL2 === p.subCat2 &&
                                    a.idCatProductsCategories === p.subCat3
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel4 &&
                                    a.idCategoriesType ===
                                      p.idCatProductsCategoriesType &&
                                    a.idCatProductsCategories === p.subCat4
                                )
                              );
                            } else {
                              //cat, l1, l3
                              return (
                                resultsArray.find(
                                  (a) =>
                                    a.isCat &&
                                    a.idCatProductsCategories ===
                                      p.idCatProductsCategoriesType
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel1 &&
                                    a.idCategoriesType ===
                                      p.idCatProductsCategoriesType &&
                                    a.idCatProductsCategories === p.subCat1
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel3 &&
                                    a.idSubCatL1 === p.subCat1 &&
                                    a.idSubCatL2 === p.subCat2 &&
                                    a.idCatProductsCategories === p.subCat3
                                )
                              );
                            }
                          } else if (lengthCatL4 > 0) {
                            //cat, l1, l4
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.isCat &&
                                  a.idCatProductsCategories ===
                                    p.idCatProductsCategoriesType
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel1 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat1
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel4 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat4
                              )
                            );
                          } else {
                            //cat, l1
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.isCat &&
                                  a.idCatProductsCategories ===
                                    p.idCatProductsCategoriesType
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel1 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat1
                              )
                            );
                          }
                        } else if (lengthCatL2 > 0) {
                          if (lengthCatL3 > 0) {
                            if (lengthCatL4 > 0) {
                              //cat, l2, l3, l4
                              return (
                                resultsArray.find(
                                  (a) =>
                                    a.isCat &&
                                    a.idCatProductsCategories ===
                                      p.idCatProductsCategoriesType
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel2 &&
                                    a.idSubCatL1 === p.subCat1 &&
                                    a.idCatProductsCategories === p.subCat2
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel3 &&
                                    a.idSubCatL1 === p.subCat1 &&
                                    a.idSubCatL2 === p.subCat2 &&
                                    a.idCatProductsCategories === p.subCat3
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel4 &&
                                    a.idCategoriesType ===
                                      p.idCatProductsCategoriesType &&
                                    a.idCatProductsCategories === p.subCat4
                                )
                              );
                            } else {
                              //cat, l2, l3
                              return (
                                resultsArray.find(
                                  (a) =>
                                    a.isCat &&
                                    a.idCatProductsCategories ===
                                      p.idCatProductsCategoriesType
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel2 &&
                                    a.idSubCatL1 === p.subCat1 &&
                                    a.idCatProductsCategories === p.subCat2
                                ) &&
                                resultsArray.find(
                                  (a) =>
                                    a.islevel3 &&
                                    a.idSubCatL1 === p.subCat1 &&
                                    a.idSubCatL2 === p.subCat2 &&
                                    a.idCatProductsCategories === p.subCat3
                                )
                              );
                            }
                          } else if (lengthCatL4 > 0) {
                            //cat, l2, l4
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.isCat &&
                                  a.idCatProductsCategories ===
                                    p.idCatProductsCategoriesType
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel2 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idCatProductsCategories === p.subCat2
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel4 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat4
                              )
                            );
                          } else {
                            //cat, l2
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.isCat &&
                                  a.idCatProductsCategories ===
                                    p.idCatProductsCategoriesType
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel2 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idCatProductsCategories === p.subCat2
                              )
                            );
                          }
                        } else if (lengthCatL3 > 0) {
                          if (lengthCatL4 > 0) {
                            //cat, l3, l4
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.isCat &&
                                  a.idCatProductsCategories ===
                                    p.idCatProductsCategoriesType
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel3 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idSubCatL2 === p.subCat2 &&
                                  a.idCatProductsCategories === p.subCat3
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel4 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat4
                              )
                            );
                          } else {
                            //cat, l3
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.isCat &&
                                  a.idCatProductsCategories ===
                                    p.idCatProductsCategoriesType
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel3 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idSubCatL2 === p.subCat2 &&
                                  a.idCatProductsCategories === p.subCat3
                              )
                            );
                          }
                        } else if (lengthCatL4 > 0) {
                          //cat, l4
                          return (
                            resultsArray.find(
                              (a) =>
                                a.isCat &&
                                a.idCatProductsCategories ===
                                  p.idCatProductsCategoriesType
                            ) &&
                            resultsArray.find(
                              (a) =>
                                a.islevel4 &&
                                a.idCategoriesType ===
                                  p.idCatProductsCategoriesType &&
                                a.idCatProductsCategories === p.subCat4
                            )
                          );
                        } else {
                          //cat
                          return resultsArray.find(
                            (a) =>
                              a.isCat &&
                              a.idCatProductsCategories ===
                                p.idCatProductsCategoriesType
                          );
                        }
                      } else if (lengthCatL1 > 0) {
                        if (lengthCatL2 > 0) {
                          if (lengthCatL3 > 0) {
                            //l1, l2, l3
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.islevel1 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat1
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel2 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idCatProductsCategories === p.subCat2
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel3 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idSubCatL2 === p.subCat2 &&
                                  a.idCatProductsCategories === p.subCat3
                              )
                            );
                          } else if (lengthCatL4 > 0) {
                            //l1, l2, l4
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.islevel1 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat1
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel2 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idCatProductsCategories === p.subCat2
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel4 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat4
                              )
                            );
                          } else {
                            //l1, l2
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.islevel1 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat1
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel2 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idCatProductsCategories === p.subCat2
                              )
                            );
                          }
                        } else if (lengthCatL3 > 0) {
                          if (lengthCatL4 > 0) {
                            //l1, l3, l4
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.islevel1 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat1
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel3 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idSubCatL2 === p.subCat2 &&
                                  a.idCatProductsCategories === p.subCat3
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel4 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat4
                              )
                            );
                          } else {
                            //l1, l3
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.islevel1 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat1
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel3 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idSubCatL2 === p.subCat2 &&
                                  a.idCatProductsCategories === p.subCat3
                              )
                            );
                          }
                        } else if (lengthCatL4 > 0) {
                          //L1, L4
                          return (
                            resultsArray.find(
                              (a) =>
                                a.islevel1 &&
                                a.idCategoriesType ===
                                  p.idCatProductsCategoriesType &&
                                a.idCatProductsCategories === p.subCat1
                            ) &&
                            resultsArray.find(
                              (a) =>
                                a.islevel4 &&
                                a.idCategoriesType ===
                                  p.idCatProductsCategoriesType &&
                                a.idCatProductsCategories === p.subCat4
                            )
                          );
                        } else {
                          //L1
                          return resultsArray.find(
                            (a) =>
                              a.islevel1 &&
                              a.idCategoriesType ===
                                p.idCatProductsCategoriesType &&
                              a.idCatProductsCategories === p.subCat1
                          );
                        }
                      } else if (lengthCatL2 > 0) {
                        if (lengthCatL3 > 0) {
                          if (lengthCatL4 > 0) {
                            //l2, l3, l4
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.islevel2 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idCatProductsCategories === p.subCat2
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel3 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idSubCatL2 === p.subCat2 &&
                                  a.idCatProductsCategories === p.subCat3
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel4 &&
                                  a.idCategoriesType ===
                                    p.idCatProductsCategoriesType &&
                                  a.idCatProductsCategories === p.subCat4
                              )
                            );
                          } else {
                            //l2, l3
                            return (
                              resultsArray.find(
                                (a) =>
                                  a.islevel2 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idCatProductsCategories === p.subCat2
                              ) &&
                              resultsArray.find(
                                (a) =>
                                  a.islevel3 &&
                                  a.idSubCatL1 === p.subCat1 &&
                                  a.idSubCatL2 === p.subCat2 &&
                                  a.idCatProductsCategories === p.subCat3
                              )
                            );
                          }
                        } else if (lengthCatL4 > 0) {
                          //l2, l4
                          return (
                            resultsArray.find(
                              (a) =>
                                a.islevel2 &&
                                a.idSubCatL1 === p.subCat1 &&
                                a.idCatProductsCategories === p.subCat2
                            ) &&
                            resultsArray.find(
                              (a) =>
                                a.islevel4 &&
                                a.idCategoriesType ===
                                  p.idCatProductsCategoriesType &&
                                a.idCatProductsCategories === p.subCat4
                            )
                          );
                        } else {
                          //l2
                          return resultsArray.find(
                            (a) =>
                              a.islevel2 &&
                              a.idSubCatL1 === p.subCat1 &&
                              a.idCatProductsCategories === p.subCat2
                          );
                        }
                      } else if (lengthCatL3 > 0) {
                        if (lengthCatL4 > 0) {
                          //l3, l4
                          return (
                            resultsArray.find(
                              (a) =>
                                a.islevel3 &&
                                a.idSubCatL1 === p.subCat1 &&
                                a.idSubCatL2 === p.subCat2 &&
                                a.idCatProductsCategories === p.subCat3
                            ) &&
                            resultsArray.find(
                              (a) =>
                                a.islevel4 &&
                                a.idCategoriesType ===
                                  p.idCatProductsCategoriesType &&
                                a.idCatProductsCategories === p.subCat4
                            )
                          );
                        } else {
                          //l3
                          return resultsArray.find(
                            (a) =>
                              a.islevel3 &&
                              a.idSubCatL1 === p.subCat1 &&
                              a.idSubCatL2 === p.subCat2 &&
                              a.idCatProductsCategories === p.subCat3
                          );
                        }
                      } else if (lengthCatL4 > 0) {
                        //l4
                        return resultsArray.find(
                          (a) =>
                            a.islevel4 &&
                            a.idCategoriesType ===
                              p.idCatProductsCategoriesType &&
                            a.idCatProductsCategories === p.subCat4
                        );
                      }
                    }
                  );

                  if (props.benefitType === 2 || props.benefitType === 1)
                    setFilteredProducts(filteredProductsResult);
                  else
                    setFilteredProducts(
                      filteredProductsResult.filter(
                        (p) => p.typePlan === props.benefitType
                      )
                    );
                  if (filteredProductsResult.length <= 0) {
                    setShowNoResultFilter(true);
                  } else {
                    setShowNoResultFilter(false);
                  }
                  //handleScrollToTop();
                  setCurrentPage(0);
                }
              }}
            >
              <div className="products__list__filters__wrapper__footer__button__with__icon__svg__wrapper apply__filters">
                <SearchWhiteIcon />
              </div>
              <span>
                {" "}
                {props.isLanguageLoaded ? (
                  t("__general__apply__filters")
                ) : (
                  <Skeleton variant="rect" width={50} height={15} />
                )}{" "}
              </span>
            </ListItem>
          </div>
        </div>

        {/* <BreadcrumbsNavigationComponet /> */}

        <div
          id="products__list__container"
          className="products__list__container"
        >
          {/* banner para promociones */}

          {/* {isBannerPromo && (
          <div
            className={`products__list__container__banner ${
              isTablet ? "tablet" : isMobile ? "mobile" : ""
            }`}
          >
            <Fragment>
              <img
                alt="promotions"
                src={
                  i18n.language === "es"
                    ? "https://www.websizusa.net/imgprod/zu/modules/1/1/3/Home_slider6_lg_es.jpg"
                    : "https://www.websizusa.net/imgprod/zu/modules/1/2/3/Home_slider6_lg_en.jpg"
                }
              />
            </Fragment>
          </div>
        )} */}

          <div
            className={`products__list__container__header ${
              isMobile || isTablet ? "mobile" : ""
            }`}
          >
            {(subCategoriesL1Data.length > 0 ||
              subCategoriesL2Data.length > 0 ||
              subCategoriesL3Data.length > 0 ||
              subCategoriesL4Data.length > 0) && (
              <div className="products__list__container__header__filters">
                {!isOpenFilterMenu && (
                  <IconButton
                    className="products__list__container__header__filters__filter__button"
                    onClick={() => {
                      setIsOpenFilterMenu(true);
                    }}
                  >
                    <FilterWhiteIcon />
                  </IconButton>
                )}
              </div>
            )}
            <div className="products__list__container__header__title">
              <div className="products__list__container__header__title__info">
                {filteredProducts.length > 0 ? (
                  <span>
                    {`${filteredProducts.length} ${t("__general__product")}${
                      filteredProducts.length > 1 ? "s" : ""
                    }`}
                  </span>
                ) : !showNoResultFilter ? (
                  <Skeleton
                    width={100}
                    height={30}
                    variant="rect"
                    style={{ marginBottom: 5 }}
                  />
                ) : (
                  <></>
                )}

                {filteredProducts.length > 0 ? (
                  <span>{categoryTitle}</span>
                ) : !showNoResultFilter ? (
                  <Skeleton
                    width={180}
                    height={30}
                    variant="rect"
                    style={{ marginBottom: 5 }}
                  />
                ) : (
                  <></>
                )}
              </div>
              {!showNoResultFilter && (
                <div className="products__list__container__header__title__breadcrumbs">
                  <span>{categoryTree}</span>
                </div>
              )}
            </div>

            <div className="products__list__container__header__order">
              <div
                className={`products__list__container__header__order__order__wrapper ${
                  isVisibleOrderMenuOptions ? "active" : ""
                }`}
                onMouseEnter={() => {
                  if (!isMobile && !isTablet) {
                    //console.log("mouse enter!!!");
                    setIsVisibleOrderMenuOptions(true);
                  }
                }}
                onMouseLeave={() => {
                  if (!isMobile && !isTablet) {
                    //console.log("mouse leave!!");
                    setIsVisibleOrderMenuOptions(false);
                  }
                }}
                onClick={() => {
                  if (isMobile || isTablet) {
                    setIsVisibleOrderMenuOptions(!isVisibleOrderMenuOptions);
                  }
                }}
              >
                {!showNoResultFilter && (
                  <div className="products__list__container__header__order__order__wrapper__inner__wrapper">
                    <div className="products__list__container__header__order__order__wrapper__inner__wrapper__button__wrapper">
                      <IconButton
                        disableRipple
                        className="products__list__container__header__order__order__wrapper__inner__wrapper__button__wrapper__order__button"
                      >
                        <OrderIcon />
                      </IconButton>
                      <span className="products__list__container__header__order__order__wrapper__inner__wrapper__button__wrapper__title">
                        {props.isLanguageLoaded ? (
                          t("__general__organize")
                        ) : (
                          <Skeleton variant="rect" width={50} height={15} />
                        )}
                      </span>
                    </div>
                    <div className="products__list__container__header__order__order__wrapper__inner__wrapper__items__wrapper">
                      <ListItem
                        button
                        className={`products__list__container__header__order__order__wrapper__inner__wrapper__items__wrapper__item ${
                          currentOrderOption === "A-Z" ? "is__active" : ""
                        }`}
                        onClick={() => {
                          setCurrentOrderOption("A-Z");
                          const clone = [...filteredProducts];
                          const result = clone.sort((a, b) => {
                            if (a.nameArticle < b.nameArticle) {
                              return -1;
                            }
                            if (a.nameArticle > b.nameArticle) {
                              return 1;
                            }
                            return 0;
                          });
                          setFilteredProducts(result);
                        }}
                      >
                        <span>A-Z</span>
                      </ListItem>
                      <ListItem
                        button
                        className={`products__list__container__header__order__order__wrapper__inner__wrapper__items__wrapper__item ${
                          currentOrderOption === "Z-A" ? "is__active" : ""
                        }`}
                        onClick={() => {
                          setCurrentOrderOption("Z-A");
                          const clone = [...filteredProducts];
                          const result = clone.sort((a, b) => {
                            if (a.nameArticle > b.nameArticle) {
                              return -1;
                            }
                            if (a.nameArticle < b.nameArticle) {
                              return 1;
                            }
                            return 0;
                          });
                          setFilteredProducts(result);
                        }}
                      >
                        <span>Z-A</span>
                      </ListItem>
                      <ListItem
                        button
                        className={`products__list__container__header__order__order__wrapper__inner__wrapper__items__wrapper__item ${
                          currentOrderOption === "Menor precio"
                            ? "is__active"
                            : ""
                        }`}
                        onClick={() => {
                          setCurrentOrderOption("Menor precio");
                          //regularPrice
                          //promotionPrice
                          const clone = [...filteredProducts];
                          const result = clone.sort((a, b) => {
                            if (a.promotionPrice > 0) {
                              return a.promotionPrice - b.promotionPrice;
                            } else {
                              return a.regularPrice - b.regularPrice;
                            }
                          });
                          setFilteredProducts(result);
                        }}
                      >
                        <span>
                          {" "}
                          {props.isLanguageLoaded ? (
                            t("__general__lower__price")
                          ) : (
                            <Skeleton variant="rect" width={50} height={15} />
                          )}
                        </span>
                      </ListItem>
                      <ListItem
                        button
                        className={`products__list__container__header__order__order__wrapper__inner__wrapper__items__wrapper__item ${
                          currentOrderOption === "Mayor precio"
                            ? "is__active"
                            : ""
                        }`}
                        onClick={() => {
                          setCurrentOrderOption("Mayor precio");
                          //regularPrice
                          //promotionPrice
                          const clone = [...filteredProducts];
                          const result = clone.sort((a, b) => {
                            if (a.promotionPrice > 0) {
                              return b.promotionPrice - a.promotionPrice;
                            } else {
                              return b.regularPrice - a.regularPrice;
                            }
                          });
                          setFilteredProducts(result);
                        }}
                      >
                        <span>
                          {props.isLanguageLoaded ? (
                            t("__general__higher__price")
                          ) : (
                            <Skeleton variant="rect" width={50} height={15} />
                          )}
                        </span>
                      </ListItem>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {(isMobile || isTablet) && (
            <>
              {showNoResultFilter && (
                <div className="products__list__container__breadcrumbs__mobile">
                  <span>{categoryTree}</span>
                </div>
              )}
            </>
          )}

          {/* <div className="products__list__container__title">
          <Typography variant="h6" gutterBottom>
            {t("__products_Module_Products_Title")}
          </Typography>
        </div> */}
          {/* <BreadcrumbsComponet
          withCategoryFiltered={withCategoryFiltered}
          categoryFiltered={categoryFiltered}
          setFilteredProducts={setFilteredProducts}
          productsData={productsData}
          categoriesData={categoriesData}
          subCategoriesL1Data={subCategoriesL1Data}
          subCategoriesL2Data={subCategoriesL2Data}
          subCategoriesL3Data={subCategoriesL3Data}
          requestLoggUserActivity={requestLoggUserActivity}
          dispatch={dispatch}
          handleGAEvent={props.handleGAEvent}
        /> */}

          <div
            id="products__list__container__items"
            className="products__list__container__items"
          >
            {filteredProducts.length > 0 ? (
              filteredProducts
                .slice(
                  currentPage * itemsPerPage,
                  currentPage * itemsPerPage + itemsPerPage
                )
                .map((product, index) => {
                  //console.log("el producct", product);
                  return (
                    <div
                      className={
                        isTablet
                          ? "products__list__container__item_isTablet"
                          : "products__list__container__item"
                      }
                      key={index}
                    >
                      <NewProductCard
                        t={t}
                        product={product}
                        clientFavorites={props.clientFavorites}
                        isLanguageLoaded={props.isLanguageLoaded}
                        enqueueSnackbar={enqueueSnackbar}
                        setIsOpenFloatingCheckout={props.setIsOpenFloatingCheckout}               
                      />
                    </div>
                  );
                })
            ) : showNoResultFilter ? (
              <NotFoundComponent isLanguageLoaded={props.isLanguageLoaded} />
            ) : isTablet ? (
              [0, 1, 2].map((itemX) => {
                return (
                  <Skeleton
                    key={itemX}
                    width={350}
                    height={350}
                    variant="rect"
                    style={{ margin: "15px 8px" }}
                  />
                );
              })
            ) : isMobile ? (
              <Skeleton
                width={350}
                height={350}
                variant="rect"
                style={{ margin: "15px 8px" }}
              />
            ) : (
              [0, 1, 2, 3, 4].map((itemX) => {
                return (
                  <Skeleton
                    key={itemX}
                    width={350}
                    height={350}
                    variant="rect"
                    style={{ margin: "15px 8px" }}
                  />
                );
              })
            )}
          </div>

          {/* PAGINACIÓN */}
          {!showNoResultFilter && (
            <div className="products__list__container__pagination">
              {currentPage !== 0 && (
                <Button
                  className={`products__list__container__pagination__navigation__button ${
                    isMobile || isTablet ? "mobile" : ""
                  }`}
                  onClick={() => {
                    if (currentPage > 0) {
                      //console.log("pagina anterior");
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                >
                  <TripleArrowLeftIcon />
                  <span className="left__side">
                    {props.isLanguageLoaded ? (
                      t("__general__previous__page")
                    ) : (
                      <Skeleton variant="rect" width={50} height={15} />
                    )}
                  </span>
                </Button>
              )}

              <Swiper
                id="swiperProductsListPagination"
                className="products__list__container__pagination__pagination__slider__wrapper"
                slidesPerView={"auto"}
                slidesPerGroup={3}
                centerInsufficientSlides={numberOfPages < 5 ? true : false}
              >
                {[...Array(numberOfPages)].map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className={`products__list__container__pagination__pagination__slider__wrapper__item ${
                        index === currentPage ? "active" : ""
                      }`}
                      onClick={() => {
                        setCurrentPage(index);
                      }}
                    >
                      <span>{index + 1}</span>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              {currentPage < numberOfPages - 1 && (
                <Button
                  className={`products__list__container__pagination__navigation__button ${
                    isMobile || isTablet ? "mobile" : ""
                  }`}
                  onClick={() => {
                    if (currentPage < numberOfPages - 1) {
                      //console.log("siguiente pagina");
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                >
                  <span className="right__side">
                    {props.isLanguageLoaded ? (
                      t("__general__next__page")
                    ) : (
                      <Skeleton variant="rect" width={50} height={15} />
                    )}
                  </span>
                  <TripleArrowRightIcon />
                </Button>
              )}
            </div>
          )}
          {/* FIN PAGINACIÓN */}
        </div>
      </div>
    </Fragment>
  );
};

const withLocation = (Component) => (props) => {
  const location = useLocation();

  return <Component {...props} location={location} />;
};

export default withLocation(ListProductsContainer);
